import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import api from "../../../services/api";
import { currencyFormater } from "../../../services/currencyFormater";

import { useWindowSize } from "../../../hooks/useWindowSize";

import { OrderStatus } from "../../../models/enums/OrderStatus";
import { CategoryModel } from "../../../models/CategoryModel";
import { ProductStore } from "../../../models/ProductStore";

import GetStore from "../../../components/GetStore";
import Spinner from "../../../components/Spinner";

import Centralizer from "../../../containers/Centralizer";

import { Provider } from "../../../models/Provider";
import { Order as OrderModel } from "../../../models/Order/Order";

import notFound from "../../../assets/svg/data-not-found.svg";

import { Modal, Tooltip, notification } from "antd";

import {
  Container,
  Center,
  ContentCategories,
  Select,
  TextSelect,
  Form,
  StepIcon,
  StepperContainer,
  Steps,
  ContainerItens,
  Table,
  ListItens,
  ItemCard,
  ContainerCards,
  ContainerItensInfo,
  QuantityInput,
  ItemText,
  ItemImage,
  ListText,
  DeleteItem,
  Footer,
  TotalFooter,
  Total,
  ButtonsContainer,
  Button,
  ButtonSave,
  Content,
  Row,
  Col,
  TextAreaComment,
  Divider,
  LineDivider,
  Status,
  IconInfo,
  StatusContainer,
} from "./styles";
import { Product } from "../../../models/Product";

const { Option } = Select;

interface IItem {
  category_id: number;
  category_name: string;
  product_id: number;
  price_sell: number;
  price_sell_py?: number;
  name: string;
  upload_url: string;
  quantity: number;
  weight: number;
  id?: number;
  certified_provider_id?: number;
}
interface ITotalPriceQuantity {
  totalPrice: number;
  totalQuantity: number;
}

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  store?: number | null;
  categories: CategoryModel[];
  order?: OrderModel;
  user: any;
  setOrderToEdit: Dispatch<SetStateAction<OrderModel | undefined>>;
  typeOfLoad: number | undefined;
  setTypeOfLoad: Dispatch<SetStateAction<number | undefined>>;
  countryPY?: boolean;
  providers: Provider[];
}

const ModalHomologOrder: React.FC<IProps> = ({
  setVisible,
  visible,
  setShouldSearch,
  store,
  categories,
  order,
  user,
  setOrderToEdit,
  countryPY,
  providers,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [providerSelect, setProviderSelect] = useState<number>(0);
  const [providerProductList, setProviderProductList] = useState<
    Product[] | undefined
  >([]);
  const [productsStock, setProductsStock] = useState<ProductStore[]>([]);
  const [handleProducts, setHandleProducts] = useState<IItem[]>([]);

  const [categoryPriceQuantity, setCategoryPriceQuantity] =
    useState<ITotalPriceQuantity>({
      totalPrice: 0,
      totalQuantity: 0,
    });

  const [totalPriceQuantity, setTotalPriceQuantity] =
    useState<ITotalPriceQuantity>({
      totalPrice: 0,
      totalQuantity: 0,
    });

  const [comment, setComment] = useState<string>("");

  const windowSize = useWindowSize();
  const [form] = Form.useForm();
  const [formQuantity] = Form.useForm();

  useEffect(() => {
    if (visible) {
      const _providersProducts =
        categories.flatMap((_category) => _category.products) || [];

      const _productList = _providersProducts
        .filter(
          (_product) => _product !== undefined && _product.certified_provider_id
        )
        .map((product) => ({
          ...product,
        }));

      setProviderProductList(_productList);

      if (order) {
        const _product = _productList?.find(
          (_item) => _item?.id === order?.orderItems[0]?.product_id
        );
        setProviderSelect(
          () =>
            providers?.find(
              (_provider) => _product?.certified_provider_id === _provider?.id
            )?.id || 0
        );
      } else {
        setProviderSelect(providers[0].id || 0);
      }
    }
  }, [visible]);

  // Chamadas de apis.

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const urlFactory = countryPY
          ? `/products_store/store/paraguay/${store}?stockProducts=false&load_factory_info=true`
          : `/products_store/store/${store}?stockProducts=false&load_factory_info=true`;
        const {
          data: { content: stockContent },
        } = await api.get(urlFactory);

        setProductsStock(stockContent);

        if (order) {
          order.orderItems.forEach(
            (_orderItem, index) =>
              (handleProducts[index] = {
                category_id: _orderItem?.products?.category_id || 0,
                category_name: _orderItem?.products?.category?.name || "",
                product_id: _orderItem.product_id,
                price_sell: +_orderItem.price_unit,
                name: _orderItem.products.name || "",
                upload_url: _orderItem.products.upload_url || "",
                quantity: +_orderItem.quantity,
                weight: +(_orderItem?.products?.weight || 0),
                id: _orderItem.id,
              })
          );
          setComment("");
          form.setFieldsValue({ comment: order.comment });
        }
      } catch (error) {
        //@ts-ignore
        const _description = error.message;

        notification.error({
          message: "Oops! Erro ao listar produtos do estoque.",
          description: error
            ? _description
            : "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde!",
          duration: 5,
        });
      } finally {
        setLoading(false);
      }
    };

    if (store && visible) {
      fetchData();
    }
  }, [store, visible]);

  //Cálculo de totais e alterações do productlist.

  useEffect(() => {
    const calcPriceQuantity = () => {
      currentStep !== 3 &&
        (handleProducts.length ? setCurrentStep(2) : setCurrentStep(1));

      const _totalPrice = handleProducts.reduce(
        (totalPrice, reducePrice) =>
          totalPrice + +(reducePrice.price_sell * reducePrice.quantity),
        0
      );
      setCategoryPriceQuantity({
        totalPrice: _totalPrice,

        totalQuantity: handleProducts.reduce(
          (totalQuantity, reduceQuantity) =>
            totalQuantity + reduceQuantity.quantity,
          0
        ),
      });

      let _totalOrderValue = handleProducts.reduce(
        (totalPrice, reducePrice) =>
          totalPrice + +(reducePrice.price_sell * reducePrice.quantity),
        0
      );

      setTotalPriceQuantity({
        totalPrice: _totalOrderValue,

        totalQuantity: handleProducts.reduce(
          (totalQuantity, reduceQuantity) =>
            totalQuantity + reduceQuantity.quantity,
          0
        ),
      });
    };
    if (providerSelect && visible && store) {
      calcPriceQuantity();
    }
  }, [providerSelect, handleProducts, visible, store, loading]);

  const onChangeQuantity = ({ target: { value } }, record) => {
    if (value < 0) {
      return notification.warning({
        message: `A quantidade de itens não deve ser negativa`,
        duration: 5,
      });
    }
    setHasError(false);
    let _newListProduct = handleProducts.filter(
      (_item) => _item.product_id !== record.id
    );

    setHandleProducts(_newListProduct);

    if (!value || +value === 0) {
      return;
    }
    setHandleProducts((oldArray) => [
      ...oldArray,
      {
        category_id: +record.category_id,
        category_name:
          categories.find((category) => category.id === record.category_id)
            ?.name || "Categoria não encontrada",
        product_id: +record.id,
        price_sell: order
          ? +record.price_unit
          : countryPY
          ? +record.price_sell_py
          : +record.price_sell,
        name: record.name,
        upload_url: record.upload_url,
        quantity: +value,
        weight: +record.weight,
        id: order?.orderItems.find(
          (orderItem) => orderItem.product_id === record.id
        )?.id,
        min_quantity: record.min_quantity,
        min_value: record.min_value,
      },
    ]);
  };

  const deleteItem = (ItemId: number) => {
    let _newListProduct = handleProducts.filter(
      (_item) => _item.product_id !== ItemId
    );

    formQuantity.resetFields();
    setHandleProducts(_newListProduct);
  };

  //Finalização de novo pedido e edição de pedidos.

  const payloadContruct = async () => {
    await form.validateFields();

    let orderItems: {
      product_id: number;
      quantity: number;
      products?: {
        name?: string;
      };
      price_unit?: string;
      id?: number;
    }[] = [];

    order
      ? handleProducts.forEach(
          (product, index) =>
            (orderItems[index] = {
              product_id: product.product_id,
              products: {
                name: product.name,
              },
              quantity: product.quantity,
              price_unit: product.price_sell.toString(),
              id: product.id,
            })
        )
      : handleProducts.forEach(
          (product, index) =>
            (orderItems[index] = {
              product_id: product.product_id,
              quantity: product.quantity,
              price_unit: product.price_sell.toString(),
            })
        );

    return {
      store_id: store,
      user_id: user?.id,
      comment: comment,
      to_delivery: false,
      total: +totalPriceQuantity.totalPrice.toFixed(2),
      total_nf: +totalPriceQuantity.totalPrice.toFixed(2),
      additional_value: 0,
      value_icms_st: 0,
      value_ipi: 0,
      value_additional_taxes: 0,
      value_interest: 0,
      discount: 0,
      franchise_tax: 0,
      type_of_load: 2,
      value_freight: 0,
      orderItems,
    };
  };

  const validateQuantity = async () => {
    try {
      await formQuantity.validateFields();

      const finalProductList = order
        ? providerProductList
            ?.map((entity) => ({
              ...entity,
              min_quantity: entity.min_quantity ?? 0,
              min_value: entity.min_value ?? 0,
            }))
            ?.filter((_product) =>
              order?.orderItems?.some((item) => item.product_id === _product.id)
            ) ?? []
        : providerProductList
            ?.filter(
              (_product) => _product.certified_provider_id === providerSelect
            )
            ?.map((entity) => ({
              ...entity,
              min_quantity: entity.min_quantity ?? 0,
              min_value: entity.min_value ?? 0,
            })) ?? [];

      let totalOrderValue = 0;

      for (const product of finalProductList) {
        const fieldName = `${product.id}Quantity`;
        const enteredQty = formQuantity.getFieldValue(fieldName) || 0;

        if (enteredQty === 0) {
          continue;
        }

        if (enteredQty < (product.min_quantity || 0)) {
          notification.warning({
            message: (
              <>
                A quantidade de <strong>"{product.name}"</strong> é menor que a
                quantidade mínima (<strong>{product.min_quantity}</strong>).
              </>
            ),
            duration: 5,
          });
          return;
        }

        const unitPrice = countryPY
          ? product.price_sell_py
          : product.price_sell;
        const totalItemValue = (unitPrice || 0) * enteredQty;

        if (totalItemValue < (product.min_value || 0)) {
          notification.warning({
            message: (
              <>
                O valor total de <strong>"{product.name}"</strong> (
                <strong>
                  R$ {currencyFormater(Number(totalItemValue.toFixed(2)))}
                </strong>
                ) está abaixo do mínimo R$ <strong>{product.min_value}</strong>.
              </>
            ),
            duration: 6,
          });
          return;
        }

        totalOrderValue += totalItemValue;
      }

      const currentProvider = providers.find((p) => p.id === providerSelect);
      if (
        currentProvider?.min_order_amount &&
        totalOrderValue < currentProvider.min_order_amount
      ) {
        notification.warning({
          message: (
            <>
              O total do pedido{" "}
              <strong>
                R$ {currencyFormater(Number(totalOrderValue.toFixed(2)))}
              </strong>{" "}
              está abaixo do pedido mínimo{" "}
              <strong>
                R${currencyFormater(Number(currentProvider.min_order_amount))}
              </strong>
              .
            </>
          ),
          duration: 6,
        });
        return;
      }

      setCurrentStep(3);
    } catch (error) {
      notification.warning({
        message:
          "Existem campos inválidos ou vazios. Verifique as quantidades.",
        duration: 5,
      });
    }
  };

  const onFinish = async () => {
    let payload;
    payload = await payloadContruct();

    Modal.confirm({
      title: "Enviar Pedido",
      content: "Gostaria de continuar o envio do pedido?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      className: "modalConfirm",
      width: 500,
      async onOk() {
        try {
          const method = order ? "put" : "post";
          const url = countryPY
            ? order
              ? `/paraguay/orders/${order.id}`
              : "/paraguay/orders"
            : order
            ? `/orders/${order.id}`
            : "/orders";

          await api[method](url, {
            ...payload,
            status: order ? order.status : 0,
          });

          notification.success({
            message: `Pedido enviado com sucesso`,
            duration: 5,
          });

          clearOrder();
          setOrderToEdit(undefined);
          setVisible(false);
          setShouldSearch(true);
        } catch (error) {
          //@ts-ignore
          const _description = error.message;

          notification.error({
            message: `Erro ao registrar pedido`,
            description: _description,
            duration: 5,
          });
        }
      },
    });
  };

  const cancelOrder = () => {
    Modal.confirm({
      title: "Gostaria de continuar e cancelar as alterações do pedido?",
      content: "Todas as modificações realizadas serão descartadas.",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      className: "modalConfirm",
      width: 500,
      async onOk() {
        clearOrder();
        setOrderToEdit(undefined);
        setVisible(false);
      },
    });
  };

  const clearOrder = async () => {
    if (order) {
      setProviderSelect(0);
    } else {
      setProviderSelect(0);
    }

    setCurrentStep(1);

    setHandleProducts([]);
    setCategoryPriceQuantity({
      totalPrice: 0,
      totalQuantity: 0,
    });

    setTotalPriceQuantity({
      totalPrice: 0,
      totalQuantity: 0,
    });

    setComment("");
    form.resetFields();
    formQuantity.resetFields();
  };

  //Cabeçalhos das tabelas

  const columnsItemsProducts = [
    {
      title: "Item",
      dataIndex: "name",
      key: "name",
      width: 30,
      defaultSortOrder: "ascend" as any,
      sorter: (productName1, productName2) =>
        productName1?.name?.localeCompare(productName2?.name),
      render: (_, record) => (
        <Tooltip
          title={
            countryPY
              ? record.name_py || "Produto sem nome."
              : record.name || "Produto sem nome."
          }
        >
          <span>
            {countryPY
              ? record.name_py || "Produto sem nome."
              : record.name || "Produto sem nome."}
          </span>
        </Tooltip>
      ),
    },
    {
      title: <Center>Situação</Center>,
      dataIndex: "situacao",
      key: "situacao",
      width: 13,
      render: (text) => (
        <Center>
          <StatusContainer>
            <Tooltip
              title={
                text === "A"
                  ? "Ativo"
                  : text === "I"
                  ? "Inativo"
                  : "Sem informações da fábrica"
              }
            >
              <Status active={text}>
                {text === "A" ? "Ativo" : text === "I" ? "Inativo" : "-"}
              </Status>
            </Tooltip>
            <Tooltip title={"O produto foi desativado pela fábrica!"}>
              {text === "I" ? <IconInfo /> : <></>}
            </Tooltip>
          </StatusContainer>
        </Center>
      ),
    },
    {
      title: <Center>Sugestão</Center>,
      dataIndex: "recommended_product",
      key: "recommended_product",
      responsive: ["md"] as any,
      width: 13,
      render: (text) => (
        <Tooltip title={text}>
          <Center>
            <span>{text}</span>
          </Center>
        </Tooltip>
      ),
    },

    {
      title: <Center>Peso</Center>,
      dataIndex: "weight",
      key: "weight",
      responsive: ["md"] as any,
      width: 13,
      render: (text) => (
        <Tooltip title={Number(text).toFixed(2)}>
          <Center>
            <span>{Number(text).toFixed(2)} KG</span>
          </Center>
        </Tooltip>
      ),
    },

    {
      title: <Center>Valor Total</Center>,
      dataIndex: order
        ? "price_unit"
        : countryPY
        ? "price_sell_py"
        : "price_sell",
      key: order ? "price_unit" : countryPY ? "price_sell_py" : "price_sell",
      width: 15,
      render: (text) => (
        <Tooltip
          title={
            text
              ? `Valor total: ${countryPY ? `US$` : `R$`}${currencyFormater(
                  +text
                )}`
              : "Valores não informados"
          }
        >
          <Center>
            <span>
              {countryPY ? `US$` : `R$`} {currencyFormater(+text)}
            </span>
          </Center>
        </Tooltip>
      ),
    },
    {
      title: <Center>Estoque</Center>,
      dataIndex: "quantity",
      key: "quantity",
      width: 12,
      render: (text) => (
        <Tooltip title={text}>
          <Center>
            <span>{text}</span>
          </Center>
        </Tooltip>
      ),
    },
    {
      title: <Center>Disponível para pedido</Center>,
      dataIndex: "saldoFisicoTotal",
      key: "saldoFisicoTotal",
      width: 16,
      render: (text, record) => (
        <Tooltip
          title={
            record.situacao === "A"
              ? text
              : record.situacao === "I"
              ? "Inativo"
              : "Sem informações da fábrica"
          }
        >
          <Center>
            <span>
              {record.situacao === "A"
                ? text
                : record.situacao === "I"
                ? "Inativo"
                : "-"}
            </span>
          </Center>
        </Tooltip>
      ),
    },
    {
      title: <Center>Qtd Mín. p/ Pedido</Center>,
      dataIndex: "min_quantity",
      key: "min_quantity",
      width: 13,
      render: (text) => (
        <Center>
          <span>{text || 0}</span>
        </Center>
      ),
    },
    {
      title: <Center>Val. Mín. p/ Pedido</Center>,
      dataIndex: "min_value",
      key: "min_value",
      width: 16,
      render: (text) => (
        <Center>
          <span>
            {countryPY ? `US$` : `R$`} {text ? Number(text).toFixed(2) : "0,00"}
          </span>
        </Center>
      ),
    },
    {
      title: <Center>Qtd</Center>,
      dataIndex: "Ação",
      key: "Ação",
      width: 15,
      render: (_, record) => (
        <Center>
          <Form form={formQuantity}>
            <Form.Item
              name={`${record.id}Quantity`}
              rules={[
                {
                  pattern: /^\d+$/,
                  message: "Qtd Inválida",
                },
                {
                  validator: (_, value) => {
                    return !value || value < 1001
                      ? Promise.resolve()
                      : Promise.reject("Qtd Inválida");
                  },
                },
              ]}
            >
              <QuantityInput
                type="number"
                defaultValue={
                  handleProducts?.find(
                    (product) =>
                      product.product_id === record.id &&
                      product.category_id === record.category_id
                  )?.quantity
                }
                onChange={(value) => onChangeQuantity(value, record)}
                disabled={
                  order?.status === OrderStatus.EM_ESTOQUE ||
                  record.situacao === "I" ||
                  (record?.saldoFisicoTotal && record?.saldoFisicoTotal === 0)
                }
              ></QuantityInput>
            </Form.Item>
          </Form>
        </Center>
      ),
    },
  ];

  const selectedProducts = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Peso",
      dataIndex: "weight",
      key: "weight",
      render: (text) => <span>{Number(text).toFixed(2)} KG</span>,
    },
    {
      title: "Quantidade Mínima do item",
      dataIndex: "min_quantity",
      key: "min_quantity",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Quantidade",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => <span>{text} Items</span>,
    },
    {
      title: "Valor Mínimo do item",
      dataIndex: "min_value",
      key: "min_value",
      render: (text) => <span>R$ {currencyFormater(Number(text))}</span>,
    },
    {
      title: "Valor Unitário",
      dataIndex: "price_sell",
      key: "price_sell",
      render: (text) => (
        <span>
          {countryPY ? `US$` : `R$`} {currencyFormater(text)}
        </span>
      ),
    },
    {
      title: "Valor Total",
      dataIndex: "total",
      key: "total",
      render: (_, record) => (
        <span>
          {countryPY ? `US$` : `R$`}{" "}
          {currencyFormater(record?.price_sell * record?.quantity)}
        </span>
      ),
    },
  ];

  const responsiveTable = (height) => {
    if (height <= 620) {
      return 270;
    }
    if (height <= 768) {
      return 325;
    }
    if (height <= 850) {
      return 400;
    }
    return 500;
  };

  return (
    <Container
      title="Novo Pedido"
      visible={visible}
      centered
      destroyOnClose={true}
      width={"90%"}
      closable={false}
      onCancel={() => setVisible(false)}
      maskClosable={false}
      footer={(() => {
        const currentProvider = providers.find(
          (_provider) => _provider.id === providerSelect
        );

        return (
          <Footer>
            <TotalFooter>
              <Total>
                <span>Total </span>
                <span className="totalCalc">
                  {countryPY ? "US$" : "R$"}{" "}
                  {currencyFormater(totalPriceQuantity.totalPrice)}
                </span>
              </Total>

              <Total>
                <span>Total de </span>
                <span className="totalCalc">
                  {totalPriceQuantity.totalQuantity} Itens
                </span>
              </Total>
              {currentProvider?.min_order_amount && (
                <Total>
                  <span>Valor Mínimo do Fornecedor</span>
                  <span className="totalCalc">
                    {countryPY ? "US$" : "R$"}{" "}
                    {currencyFormater(
                      Number(currentProvider?.min_order_amount)
                    )}
                  </span>
                </Total>
              )}
            </TotalFooter>

            <ButtonsContainer>
              <Button
                onClick={() => {
                  currentStep !== 3
                    ? cancelOrder()
                    : setCurrentStep(currentStep - 1);
                }}
              >
                {currentStep !== 3 ? "Cancelar" : "Revisar Pedido"}
              </Button>

              <ButtonSave
                disabled={!!!handleProducts.length || hasError}
                onClick={() => {
                  currentStep === 3 ? onFinish() : validateQuantity();
                }}
              >
                {currentStep === 1 || currentStep === 2
                  ? "Continuar Pedido"
                  : "Finalizar Pedido"}
              </ButtonSave>
            </ButtonsContainer>
          </Footer>
        );
      })()}
    >
      <ContentCategories>
        <span className="spanText">Progresso do Pedido</span>
        {currentStep !== 3 && (
          <div>
            <span className="loadType">Fornecedores homologados</span>

            {loading ? (
              <></>
            ) : (
              <Select
                placeholder="Fornecedor"
                defaultValue={providerSelect}
                value={providerSelect}
                onChange={(id) => {
                  setProviderSelect(+id);
                  setCategoryPriceQuantity({
                    totalPrice: 0,
                    totalQuantity: 0,
                  });
                }}
                disabled={handleProducts?.length !== 0}
              >
                {providers?.map((_provider) => (
                  <Option
                    key={_provider.id}
                    value={_provider.id}
                    style={{ textTransform: "uppercase" }}
                  >
                    <TextSelect>
                      <span>{_provider.company_name}</span>
                      <span className="itemCount">
                        {handleProducts?.length || 0}
                      </span>
                    </TextSelect>
                  </Option>
                ))}
              </Select>
            )}
          </div>
        )}
      </ContentCategories>

      <Form layout="vertical" initialValues={{ remember: false }}>
        <StepperContainer>
          <Steps currentPage={currentStep}>
            <StepIcon className="step-icon1" />
            <div className="step-line1" />
            <StepIcon className="step-icon2" />
            <div className="step-line2" />
            <StepIcon className="step-icon3" />
          </Steps>
        </StepperContainer>
      </Form>
      {currentStep !== 3 ? (
        <>
          {loading ? (
            <Centralizer>
              <Spinner />
            </Centralizer>
          ) : (
            <ContainerItens>
              <Table
                columns={columnsItemsProducts}
                dataSource={
                  order
                    ? providerProductList
                        ?.map((entity) => ({
                          ...entity,
                          key: entity.id,
                          quantity:
                            productsStock?.find(
                              (_productStockID) =>
                                _productStockID?.product_id === entity.id
                            )?.quantity || 0,
                          price_unit: order.orderItems.find(
                            (_productStockID) =>
                              _productStockID?.product_id === entity.id
                          )?.price_unit,
                          situacao:
                            productsStock.find(
                              (_productStockID) =>
                                _productStockID?.product_id === entity.id
                            )?.product?.factory_info?.situacao || "-",
                          min_quantity: entity.min_quantity ?? 0,
                          min_value: entity.min_value ?? 0,
                          saldoFisicoTotal: productsStock.find(
                            (_productStockID) =>
                              _productStockID?.product_id === entity.id
                          )?.product?.factory_info?.estoque?.saldoFisicoTotal,
                        }))
                        .filter((_productID) =>
                          order?.orderItems.some(
                            (productItemEdit) =>
                              productItemEdit.product_id === _productID.id
                          )
                        )
                    : providerProductList
                        ?.filter(
                          (_product) =>
                            _product.certified_provider_id === providerSelect
                        )
                        ?.map((entity) => ({
                          ...entity,
                          key: entity.id,
                          quantity:
                            productsStock?.find(
                              (_productStockID) =>
                                _productStockID?.product_id === entity.id
                            )?.quantity || 0,
                          situacao:
                            productsStock.find(
                              (_productStockID) =>
                                _productStockID?.product_id === entity.id
                            )?.product?.factory_info?.situacao || "-",
                          saldoFisicoTotal: productsStock.find(
                            (_productStockID) =>
                              _productStockID?.product_id === entity.id
                          )?.product?.factory_info?.estoque?.saldoFisicoTotal,
                        }))
                }
                scroll={{
                  y: responsiveTable(windowSize.height),
                }}
                pagination={false}
              />
              <ListItens>
                <ListText>
                  <span className="textItens">Lista de Pedidos</span>
                </ListText>
                <ContainerCards>
                  {handleProducts.map((itens) => (
                    <React.Fragment key={itens.product_id}>
                      <ItemCard>
                        <ItemText>
                          <span className={"itemName"}>{itens.name}</span>
                          <span className={"itemQuantity"}>
                            Quantidade: {itens.quantity}
                          </span>
                        </ItemText>

                        <ItemImage
                          src={itens.upload_url || notFound}
                        ></ItemImage>
                      </ItemCard>
                      {!(order?.status === OrderStatus.EM_ESTOQUE) && (
                        <DeleteItem>
                          <span onClick={() => deleteItem(itens.product_id)}>
                            Remover
                          </span>
                        </DeleteItem>
                      )}
                    </React.Fragment>
                  ))}
                </ContainerCards>
                <ContainerItensInfo>
                  <div className="totalInfo">
                    <span>Total</span>
                    <span className="totalCalc">
                      {currencyFormater(categoryPriceQuantity?.totalPrice)}
                    </span>
                  </div>

                  <div className="totalInfo">
                    <span>Quantidade</span>
                    <span className="totalCalc">
                      {categoryPriceQuantity?.totalQuantity}
                    </span>
                  </div>
                </ContainerItensInfo>
              </ListItens>
            </ContainerItens>
          )}
        </>
      ) : (
        <Content>
          <Form
            layout="vertical"
            initialValues={{ remember: false }}
            form={form}
          >
            <Row gutter={8}>
              <Col sm={6} xs={24}>
                <Form.Item label="Loja Selecionada:">
                  <GetStore
                    disabled={true}
                    defaultValue={store}
                    handleChange={() => {}}
                  />
                </Form.Item>
              </Col>
              <Col
                sm={order?.status === OrderStatus.CONFIRMADO ? 6 : 12}
                xs={24}
              >
                <Form.Item label="Comentário:" name="comment">
                  <TextAreaComment
                    style={{ height: 100 }}
                    placeholder="Deixe algum comentário relacionado ao seu pedido"
                    defaultValue={comment}
                    rows={5}
                    onChange={({ target: { value } }) =>
                      setComment(() => value)
                    }
                    disabled={order?.status === OrderStatus.EM_ESTOQUE}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Divider>
            <span>Lista de pedidos</span>
            <LineDivider />
          </Divider>

          <Table
            columns={selectedProducts}
            dataSource={handleProducts}
            rowKey={(entity: any) => entity.id}
            scroll={{ y: 200 }}
            pagination={false}
            className="orderList"
          />
        </Content>
      )}
    </Container>
  );
};

export default ModalHomologOrder;
