import React, { useEffect, useState } from "react";
import { Tabs, notification, Table, Button, message, Popover } from "antd";
import api from "../../../services/api";
import { Order as OrderModel } from "../../../models/Order/Order";
import Spinner from "../../../components/Spinner";
import { currencyFormater } from "../../../services/currencyFormater";
import moment from "moment";
import { Container, Modal } from "./styles";
import { verifyPermission } from "../../../services/auth";

interface ModalOrderDocumentsProps {
  visible: boolean;
  onClose: () => void;
  order?: OrderModel;
}

type Invoice = {
  F2_DOC?: string;
  F2_SERIE?: string;
  F2_CHVNFE?: string;
  F2_VALFAT?: number;
  F2_MENNOTA?: string;
  F2_EMISSAO?: string;
};

type Bankslip = {
  E1_NUM?: string;
  E1_SERIE?: string;
  E1_STATUS?: string;
  E1_EMISSAO?: string;
  E1_VENCTO?: string;
  E1_CODDIG?: string;
  E1_VALOR?: number;
  E1_DESCONT?: number;
  E1_JUROS?: number;
  E1_MULTA?: number;
  E1_CORREC?: number;
  E1_VALLIQ?: number;
  E1_SALDO?: number;
};

const statusLabel = {
  A: "Em Aberto",
  B: "Pago",
};

const ModalOrderDocuments: React.FC<ModalOrderDocumentsProps> = ({
  visible,
  onClose,
  order,
}) => {
  const [loadingInvoices, setLoadingInvoices] = useState(false);
  const [loadingBankslips, setLoadingBankslips] = useState(false);

  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [bankslips, setBankslips] = useState<Bankslip[]>([]);

  const [downloading, setDownloading] = useState<{
    [key: string]: { pdf?: boolean; xml?: boolean };
  }>({});

  const orderId = order?.id;

  useEffect(() => {
    if (visible && orderId) {
      fetchInvoices([orderId]);
      fetchBankslips([orderId]);
    }
  }, [visible, orderId]);

  const fetchInvoices = async (orderIds: number[]) => {
    setLoadingInvoices(true);
    try {
      const { data } = await api.post("/orders/find-invoices", {
        order_ids: orderIds,
      });
      if (data.content && data.content.length > 0) {
        const result = data.content[0];
        setInvoices(result.invoices || []);
      } else {
        setInvoices([]);
      }
    } catch (error) {
      notification.error({
        message: "Falha ao buscar notas fiscais",
        //@ts-ignore
        description: error?.response?.data?.error?.message || error.message,
        duration: 5,
      });
    } finally {
      setLoadingInvoices(false);
    }
  };

  const fetchBankslips = async (orderIds: number[]) => {
    setLoadingBankslips(true);
    try {
      const { data } = await api.post("/orders/find-bankslips", {
        order_ids: orderIds,
      });
      if (data.content && data.content.length > 0) {
        const result = data.content[0];
        setBankslips(result.bank_slips || []);
      } else {
        setBankslips([]);
      }
    } catch (error) {
      notification.error({
        message: "Falha ao buscar boletos",
        //@ts-ignore
        description: error?.response?.data?.error?.message || error.message,
        duration: 5,
      });
    } finally {
      setLoadingBankslips(false);
    }
  };

  const setFileDownloading = (
    id: string,
    fileType: "pdf" | "xml",
    value: boolean
  ) => {
    setDownloading((prev) => ({
      ...prev,
      [id]: {
        ...(prev[id] || {}),
        [fileType]: value,
      },
    }));
  };

  const isDownloading = (id: string, fileType: "pdf" | "xml") => {
    return downloading[id]?.[fileType] === true;
  };

  const downloadFile = async (
    invoiceNumber: string,
    fileType: "pdf" | "xml"
  ) => {
    setFileDownloading(invoiceNumber, fileType, true);
    try {
      const response = await api.get(
        `/order/attachment/${invoiceNumber}/${fileType}`,
        {
          responseType: "blob",
        }
      );

      const mimeType =
        fileType === "pdf" ? "application/pdf" : "application/xml";
      const fileExtension = fileType === "pdf" ? "pdf" : "xml";
      const file = new Blob([response.data], { type: mimeType });
      const url = URL.createObjectURL(file);

      const link = document.createElement("a");
      link.href = url;
      link.download = `${invoiceNumber}.${fileExtension}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      //@ts-ignore
      if (error.response && error.response.data) {
        //@ts-ignore
        const errorData = await error.response.data.text();
        const parsedError = JSON.parse(errorData);
        notification.error({
          message: `Falha ao baixar o ${fileType.toUpperCase()}`,
          description: parsedError.message || "Erro desconhecido.",
          duration: 5,
        });
      } else {
        notification.error({
          message: `Falha ao baixar o ${fileType.toUpperCase()}`,
          description: "Erro desconhecido ao processar o erro da API.",
          duration: 5,
        });
      }
    } finally {
      setFileDownloading(invoiceNumber, fileType, false);
    }
  };

  const copyCode = (code: string) => {
    if (!code) return;
    navigator.clipboard.writeText(code).then(() => {
      message.success("Código digitável copiado!");
    });
  };

  const formatDate = (date: string | undefined): string => {
    if (!date) return "-";
    return moment(date, "YYYYMMDD").format("DD/MM/YYYY");
  };

  const invoiceColumns = [
    {
      title: "Número da Nota",
      dataIndex: "F2_DOC",
      key: "F2_DOC",
      render: (text: string) => text || "-",
    },
    {
      title: "Série",
      dataIndex: "F2_SERIE",
      key: "F2_SERIE",
      render: (text: string) => text || "-",
    },
    {
      title: "Chave",
      dataIndex: "F2_CHVNFE",
      key: "F2_CHVNFE",
      render: (text: string) => text || "-",
    },
    {
      title: "Valor Faturado",
      dataIndex: "F2_VALFAT",
      key: "F2_VALFAT",
      render: (value: number) =>
        value ? `R$ ${currencyFormater(value)}` : "-",
    },
    {
      title: "Mensagem Informações Adicionais",
      dataIndex: "F2_MENNOTA",
      key: "F2_MENNOTA",
      render: (text: string) => text || "-",
    },
    {
      title: "Data de Emissão",
      dataIndex: "F2_EMISSAO",
      key: "F2_EMISSAO",
      render: (text: string) => formatDate(text),
    },
    {
      title: "Ações",
      key: "acoes",
      render: (_: any, record: Invoice) => {
        const invNumber = record.F2_CHVNFE || "";
        return invNumber ? (
          <div style={{ display: "flex", gap: "8px" }}>
            <Button
              type="primary"
              loading={isDownloading(invNumber, "pdf")}
              onClick={() => downloadFile(invNumber, "pdf")}
            >
              PDF
            </Button>
            <Button
              loading={isDownloading(invNumber, "xml")}
              onClick={() => downloadFile(invNumber, "xml")}
            >
              XML
            </Button>
          </div>
        ) : null;
      },
    },
  ];

  const bankslipColumns = [
    {
      title: "Número do Boleto",
      dataIndex: "E1_NUM",
      key: "E1_NUM",
      render: (text: string) => text || "-",
    },
    {
      title: "Série",
      dataIndex: "E1_SERIE",
      key: "E1_SERIE",
      render: (text: string) => text || "-",
    },
    {
      title: "Status",
      dataIndex: "E1_STATUS",
      key: "E1_STATUS",
      render: (status: string, record: Bankslip) => {
        const label = statusLabel[status as "A" | "B"] || "-";
        const color = status === "B" ? "green" : "inherit";
        return <span style={{ color, fontWeight: "bold" }}>{label}</span>;
      },
    },
    {
      title: "Data de Emissão",
      dataIndex: "E1_EMISSAO",
      key: "E1_EMISSAO",
      render: (text: string) => formatDate(text),
    },
    {
      title: "Data de Vencimento",
      dataIndex: "E1_VENCTO",
      key: "E1_VENCTO",
      render: (text: string, record: Bankslip) => {
        const today = moment();
        const dueDate = moment(text, "YYYYMMDD");

        if (!dueDate.isValid()) return "-";

        const daysDifference = dueDate.diff(today, "days");

        let color = "inherit";
        if (record.E1_STATUS !== "B") {
          if (daysDifference < 0) {
            color = "red";
          } else if (daysDifference <= 3) {
            color = "orange";
          }
        }

        return <span style={{ color }}>{dueDate.format("DD/MM/YYYY")}</span>;
      },
    },
    {
      title: "Código Digitável",
      dataIndex: "E1_CODDIG",
      key: "E1_CODDIG",
      render: (text: string) =>
        text ? (
          <Popover content={text}>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <span>{`${text.substring(0, 10)}...`}</span>
              <Button size="small" onClick={() => copyCode(text)}>
                Copiar
              </Button>
            </div>
          </Popover>
        ) : (
          "-"
        ),
    },
    {
      title: "Valor",
      dataIndex: "E1_VALOR",
      key: "E1_VALOR",
      render: (value: number) =>
        value ? `R$ ${currencyFormater(value)}` : "-",
    },
    {
      title: "Desconto",
      dataIndex: "E1_DESCONT",
      key: "E1_DESCONT",
      render: (value: number) =>
        value ? `R$ ${currencyFormater(value)}` : "-",
    },
    {
      title: "Juros",
      dataIndex: "E1_JUROS",
      key: "E1_JUROS",
      render: (value: number) =>
        value ? `R$ ${currencyFormater(value)}` : "-",
    },
    {
      title: "Multa",
      dataIndex: "E1_MULTA",
      key: "E1_MULTA",
      render: (value: number) =>
        value ? `R$ ${currencyFormater(value)}` : "-",
    },
    {
      title: "Correção",
      dataIndex: "E1_CORREC",
      key: "E1_CORREC",
      render: (value: number) =>
        value ? `R$ ${currencyFormater(value)}` : "-",
    },
    {
      title: "Valor Pago",
      dataIndex: "E1_VALLIQ",
      key: "E1_VALLIQ",
      render: (value: number) =>
        value ? `R$ ${currencyFormater(value)}` : "-",
    },
    {
      title: "Saldo",
      dataIndex: "E1_SALDO",
      key: "E1_SALDO",
      render: (value: number) =>
        value ? `R$ ${currencyFormater(value)}` : "-",
    },
  ];

  const getModalWidth = () => {
    if (window.outerWidth < 768) {
      return "100%";
    } else if (window.outerWidth < 1400) {
      return "70%";
    }
    return "90%";
  };

  return (
    <Modal
      visible={visible}
      footer={
        <>
          <Button
            onClick={() => {
              if (!loadingInvoices && !loadingBankslips) {
                onClose();
              } else {
                notification.warning({
                  message: "Aguarde",
                  description:
                    "As informações ainda estão sendo carregadas. Por favor, aguarde a conclusão.",
                  duration: 3,
                });
              }
            }}
          >
            Fechar
          </Button>
        </>
      }
      closable={false}
      maskClosable={false}
      title={`Documentos do Pedido ${order?.vhsys ?? ""}`}
      width={getModalWidth()}
    >
      <Tabs defaultActiveKey="invoices">
        <Tabs.TabPane tab="Notas Fiscais" key="invoices">
          {loadingInvoices ? (
            <Spinner />
          ) : invoices.length === 0 ? (
            <Container>
              <p>
                Nenhuma nota fiscal disponível no momento, tente novamente mais
                tarde.
              </p>
            </Container>
          ) : (
            <Table
              columns={invoiceColumns}
              dataSource={invoices.map((inv) => ({ ...inv }))}
              pagination={false}
              scroll={{ x: true }}
            />
          )}
        </Tabs.TabPane>
        {verifyPermission("orders.document_orders") && (
          <Tabs.TabPane tab="Boletos" key="bankslips">
            {loadingBankslips ? (
              <Spinner />
            ) : bankslips.length === 0 ? (
              <Container>
                <p>
                  Nenhum boleto disponível no momento, tente novamente mais
                  tarde.
                </p>
              </Container>
            ) : (
              <Table
                columns={bankslipColumns}
                dataSource={bankslips.map((b) => ({ ...b }))}
                pagination={false}
                scroll={{ x: true }}
              />
            )}
          </Tabs.TabPane>
        )}
      </Tabs>
    </Modal>
  );
};

export default ModalOrderDocuments;
