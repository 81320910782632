import React, { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import locale from "antd/es/date-picker/locale/pt_BR";

import api from "../../../services/api";
import { currencyFormater } from "../../../services/currencyFormater";

import { useWindowSize } from "../../../hooks/useWindowSize";
import { useResponsiveSizeTable } from "../../../hooks/useResponsiveSizeTable";

import Spinner from "../../../components/Spinner";
import Pagination from "../../../components/Pagination";
import MonetaryInput from "../../../components/MonetaryInput2";

import Centralizer from "../../../containers/Centralizer";
import PageContainer from "../../../containers/PageContainer";

import { Page } from "../../../models/Page";
import { OrderStatus } from "../../../models/enums/OrderStatus";
import { Order as OrderModel } from "../../../models/Order/Order";

import ModalTaxesPayments from "../ModalTaxesPayments";

import { notification, Tooltip } from "antd";

import {
  Button,
  Container,
  Header,
  Content,
  BoxChangePayment,
  Select,
  RangePicker,
  StatusDiv,
  StatusInfo,
  Table,
} from "./styles";

const OrderPagePaymentMassive: React.FC = () => {
  const windowSize = useWindowSize();
  const responsiveWindow = useResponsiveSizeTable(windowSize);

  const [loading, setLoading] = useState<boolean>(false);
  const [shouldSearch, setShouldSearch] = useState<boolean>(false);
  const [loadingStore, setLoadingStore] = useState<boolean>(false);
  const [visibleTaxesModal, setVisibleTaxesModal] = useState<boolean>(false);

  const [orderSelect, setOrderSelect] = useState<OrderModel | undefined | null>(
    null
  );

  const [paginate, setPaginate] = useState<Page>({
    page: 1,
    size: 100,
    totalElements: 0,
  });

  const [ordersPaymentsValues, setOrdersPaymentsValues] = useState<
    { key: number; value: number }[]
  >([]);
  const [selectedDate, setSelectedDate] = useState<Moment[]>([
    moment(new Date()).subtract(7, "days"),
    moment(new Date()),
  ]);

  const [ordersToUpdate, setOrdersToUpdate] = useState<OrderModel[]>([]);
  const [status, setStatus] = useState(3);

  const handleDateRangeChange = (dates: any) => {
    setSelectedDate(dates);
  };
  const fetchAllStoresOrders = async () => {
    setLoadingStore(true);

    try {
      let URL = `/orders?page=${paginate.page}&size=${paginate.size}&status=${status}&stores_country=brasil&partially_paid=true&providerNotHomologation=true`;

      if (selectedDate && selectedDate[0] && selectedDate[1]) {
        let initialDate = selectedDate[0].format("DD/MM/YYYY");
        let finalDate = selectedDate[1].format("DD/MM/YYYY");

        URL += `&data_inicial=${initialDate}&data_final=${finalDate}`;
      }
      const { data: pagination } = await api.get(URL);
      const { totalElements, content } = pagination;

      setPaginate((oldValues) => ({ ...oldValues, totalElements }));

      setOrdersToUpdate(content);
    } catch (error) {
      const _description =
        //@ts-ignore
        error.response?.data || "Erro ao buscar pedidos";
      notification.error({
        message: "Erro ao buscar pedidos",
        description: _description,
        duration: 5,
      });
    } finally {
      setLoadingStore(false);
    }
  };

  useEffect(() => {
    fetchAllStoresOrders();
  }, [paginate.page, paginate.size, selectedDate, status, shouldSearch]);

  const payloadConstructor = async () => {
    const _payload = ordersToUpdate.map((_order) => {
      const findOrder = ordersPaymentsValues.find(
        (_orderValue) => _orderValue.key === _order.id
      );
      const totalToPay =
        +(_order.total_nf ? _order.total_nf : _order.total) -
        +_order.order_payments.reduce(
          (total, item) =>
            item?.deleted_at ? total : total + +item?.payment_value,
          0
        );
      return findOrder
        ? {
            id: _order.id,
            total_nf: totalToPay,
            payment_type: 5,
            payment_value: findOrder.value,
          }
        : {
            id: _order.id,
            total_nf: totalToPay,
            payment_type: 5,
            payment_value: 0,
          };
    });

    return _payload.filter((_order) => +_order.payment_value !== 0);
  };

  const verifyTotalPayment = async (payload) => {
    const paymentsTotalVerify = payload.map((item) => {
      if (item.payment_value <= 0) {
        return false;
      } else if (item.total_nf < item.payment_value) {
        return false;
      } else {
        return true;
      }
    });
    return paymentsTotalVerify.every((_item) => _item);
  };

  const onFinish = async () => {
    setLoading(true);
    const payload = await payloadConstructor();
    const verifyPayload = await verifyTotalPayment(payload);
    if (!verifyPayload) {
      setLoading(false);
      return notification.error({
        message: "Valor de pagamento inválido",
        description:
          "Os valores de pagamento devem ser maiores que zero e menores que o Valor da Nota",
        duration: 5,
      });
    }

    const _payload = await payload.map((item) => ({
      order_id: item.id,
      payment_value: item.payment_value,
      payment_type: item.payment_type,
    }));

    try {
      setShouldSearch(true);
      await api.post("/orders/update-orders-payments", _payload);
      notification.success({
        message: "Pagamentos realizados com sucesso",
        duration: 5,
      });
    } catch (error) {
      const description =
        //@ts-ignore
        error?.response?.data?.message || "Um erro interno ocorreu";
      notification.error({
        message: "Erro ao atualizar pagamentos",
        description: description,
        duration: 5,
      });
    } finally {
      setShouldSearch(false);
      setLoading(false);
    }
  };

  const getStatusOrder = (order: OrderModel) => {
    if (order.to_delivery && order.status < 4) {
      if (order.status === 0) {
        return `Aguardando confirmação`;
      } else {
        return `Retirar às ${order.time_to_get}`;
      }
    } else {
      return OrderStatus[order.status];
    }
  };

  const getRowClassName = (record) => {
    return record.total_nf !== null && record.total_nf !== undefined
      ? getRowClassNameNFValue(record)
      : getRowClassNameTotal(record);
  };

  const getRowClassNameTotal = (record) => {
    if (+record?.total === +record?.payment_value?.toFixed(2)) {
      return "totalPay";
    } else if (
      record.status === 3 &&
      +record.payment_value < +record.total &&
      record.order_payments?.length === 0
    ) {
      return "emptyPay";
    } else if (
      +record.payment_value < +record.total &&
      +record.payment_value !== 0 &&
      record.order_payments?.length > 0
    ) {
      return "partialPay";
    } else {
      return "default";
    }
  };

  const getRowClassNameNFValue = (record) => {
    if (+record?.total_nf === +record?.payment_value?.toFixed(2)) {
      return "totalPay";
    } else if (
      record.status === 3 &&
      +record.payment_value < +record.total_nf &&
      record.order_payments?.length === 0
    ) {
      return "emptyPay";
    } else if (
      +record.payment_value < +record.total_nf &&
      +record.payment_value !== 0 &&
      record.order_payments?.length > 0
    ) {
      return "partialPay";
    } else {
      return "default";
    }
  };

  const getPaymentValue = async (_key: number, _value: number) => {
    const _paymentsValue = [...ordersPaymentsValues];
    const index = _paymentsValue.findIndex((_payment) => _payment.key === _key);
    if (index !== -1) {
      _paymentsValue[index] = { ..._paymentsValue[index], value: _value };
    } else {
      _paymentsValue.push({ key: _key, value: _value });
    }

    setOrdersPaymentsValues(_paymentsValue);
  };

  const openTaxChange = (_order: OrderModel) => {
    setOrderSelect(_order);
    setVisibleTaxesModal(true);
  };

  const orderStatus = [
    { id: 3, description: "Finalizado" },
    { id: 5, description: "Em estoque" },
    { id: 6, description: "Estornado" },
  ];

  const columns = [
    {
      title: "Nº",
      dataIndex: "vhsys",
      key: "vhsys",
      width: windowSize.width > 575 ? 90 : 40,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: windowSize.width > 575 ? 130 : 100,
      render: (text, record) => (
        <StatusDiv>
          <Tooltip title={getStatusOrder(record)}>
            <StatusInfo order_status={text} to_delivery={record.to_delivery}>
              {getStatusOrder(record)}
            </StatusInfo>
          </Tooltip>
        </StatusDiv>
      ),
    },
    {
      title: "Data/Hora",
      dataIndex: "created_at",
      key: "created_at",
      responsive: ["xl"] as any,
      width: 100,
      render: (text) => (
        <Tooltip
          title={moment(text, "DD-MM-YYYY HH:mm:ss").format(
            "DD/MM/YYYY HH:mm:ss"
          )}
        >
          <span>
            {moment(text, "DD-MM-YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Valor NF",
      dataIndex: "total_nf",
      key: "total_nf",
      responsive: ["md"] as any,
      width: 110,
      render: (text, record) => (
        <Tooltip
          title={
            text !== undefined && text !== null
              ? `R$ ${currencyFormater(+text)}`
              : `R$ ${currencyFormater(+record?.total)}`
          }
        >
          <span>
            {text !== undefined && text !== null ? (
              <Centralizer>R$ {currencyFormater(+text)}</Centralizer>
            ) : (
              <Centralizer>R$ ${currencyFormater(+record?.total)}</Centralizer>
            )}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Valor Pago NF",
      dataIndex: "payment_value",
      key: "payment_value",
      responsive: ["lg"] as any,
      width: 150,
      render: (text) => (
        <Tooltip title={`R$ ${currencyFormater(+text)}`}>
          <span>
            {`R$`} {currencyFormater(+text)}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Tipo de Carga",
      dataIndex: "type_of_load",
      key: "type_of_load",
      responsive: ["lg"] as any,
      width: 120,
      render: (text) => (
        <Tooltip title={text === 0 ? "Gelada" : text === 1 ? "Seca" : "-"}>
          <span>{text === 0 ? "Gelada" : text === 1 ? "Seca" : "-"}</span>
        </Tooltip>
      ),
    },
    {
      title: "Loja",
      dataIndex: "Loja",
      key: "Loja",
      responsive: ["md"] as any,
      width: 150,
      render: (_, record) => (
        <Tooltip
          title={
            record?.store
              ? record?.store?.company_name
              : "Loja não identificada"
          }
        >
          <span style={{ textTransform: "capitalize" }}>
            {record?.store
              ? record?.store?.company_name
              : "Loja não identificada"}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Valor pendente",
      dataIndex: "total_payable",
      key: "total_payable",
      width: 150,
      render: (_, record) => (
        <Tooltip
          title={
            record.total_nf !== undefined && record.total_nf !== null
              ? `R$ ${currencyFormater(
                  +(record.total_nf - record.payment_value)
                )}`
              : `R$ ${currencyFormater(+(record.total - record.payment_value))}`
          }
        >
          <span>
            {record.total_nf !== undefined && record.total_nf !== null ? (
              <Centralizer>
                R$ {currencyFormater(+(record.total_nf - record.payment_value))}
              </Centralizer>
            ) : (
              <Centralizer>
                R$ ${currencyFormater(+(record.total - record.payment_value))}
              </Centralizer>
            )}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Insira o valor",
      width: 150,
      render: (_, record) => (
        <div style={{ padding: "0 1rem" }}>
          <MonetaryInput
            getValue={(event) => getPaymentValue(record.key, event)}
            disabled={
              +(record.total_nf ? record.total_nf : record.total) <=
              record.payment_value
            }
          />
        </div>
      ),
    },
    // {
    //   title: <Center>Ação</Center>,
    //   dataIndex: "Ação",
    //   key: "Ação",
    //   width: windowSize.width > 575 ? 70 : 25,
    //   render: (_, record) => (
    //     <Center>
    //       <Tooltip title={"Editar Valores"}>
    //         <ButtonTax onClick={() => openTaxChange(record)}>
    //           <PaymentIcon />
    //         </ButtonTax>
    //       </Tooltip>
    //     </Center>
    //   ),
    // },
  ];

  return (
    <PageContainer route="Gerenciamento de Pagamentos">
      <Container>
        {loadingStore ? (
          <Centralizer>
            <Spinner />
          </Centralizer>
        ) : (
          <>
            <Header>
              <BoxChangePayment>
                <Select defaultValue={status} onChange={(id) => setStatus(+id)}>
                  {orderStatus.map((status) => (
                    <Select.Option
                      key={status.id}
                      value={status.id}
                      style={{ textTransform: "uppercase" }}
                    >
                      {status.description}
                    </Select.Option>
                  ))}
                </Select>
                <RangePicker
                  placeholder={["Data inicial", "Data final"]}
                  locale={locale}
                  format="DD/MM/YYYY"
                  value={[selectedDate[0], selectedDate[1]]}
                  onChange={handleDateRangeChange}
                />
                <>
                  <Button
                    disabled={loadingStore || loading || !selectedDate}
                    onClick={() => onFinish()}
                    loading={loadingStore || loading}
                  >
                    Atualizar Pagamentos
                  </Button>
                </>
              </BoxChangePayment>
            </Header>
            <Content>
              {loadingStore ? (
                <Centralizer>
                  <Spinner />
                </Centralizer>
              ) : (
                <>
                  <Table
                    columns={columns}
                    dataSource={ordersToUpdate.map((order) => ({
                      ...order,
                      key: order?.id,
                      orderItems: order?.orderItems?.sort((current, next) =>
                        current.products?.name &&
                        next.products?.name &&
                        current.products?.name > next.products?.name
                          ? 1
                          : current.products?.name &&
                            next.products?.name &&
                            next.products?.name > current.products?.name
                          ? -1
                          : 0
                      ),
                      payment_value: order?.order_payments?.reduce(
                        (total, item) =>
                          item?.deleted_at
                            ? total
                            : total + +item?.payment_value,
                        0
                      ),
                    }))}
                    scroll={{
                      y: responsiveWindow - (windowSize.width > 700 ? 50 : 150),
                    }}
                    rowClassName={getRowClassName}
                    pagination={false}
                  />
                  <Pagination
                    disabled={loadingStore}
                    setStateSearch={() => {}}
                    setPaginate={setPaginate}
                    defaultPageSize={paginate.size}
                    showSizeChanger={true}
                    current={paginate.page}
                    totalElements={paginate.totalElements}
                  />
                </>
              )}
            </Content>
          </>
        )}
      </Container>
      <ModalTaxesPayments
        visible={visibleTaxesModal}
        setVisible={setVisibleTaxesModal}
        orderSelect={orderSelect}
        setOrderSelect={setOrderSelect}
        ordersToUpdate={ordersToUpdate}
        setOrdersToUpdate={setOrdersToUpdate}
      />
    </PageContainer>
  );
};

export default OrderPagePaymentMassive;
