import styled, { css } from "styled-components";

import {
  Button as ButtonAnt,
  Row as RowAnt,
  Col as ColAnt,
  Modal as ModalAnt,
  Select as SelectAnt,
  Form as FormAnt,
  Input as InputAnt,
} from "antd";

import { Add, CancelPresentation, Filter, Reload } from "../../styles/Icons";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  .rowEnd,
  .colEnd {
    display: flex;
    justify-content: flex-end;
  }
  .ant-input-affix-wrapper {
    padding: 0 0.5rem;
    border-radius: 0.5rem;
  }
`;

const buttonCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  background: var(--orange-350);
  border: none;
  box-shadow: none;
  border-radius: 0.5rem;
  height: 3.25rem;
  width: 100%;

  :hover,
  :active,
  :focus {
    color: var(--white);
    background: var(--orange-350);
    border: none;
    box-shadow: none;
    opacity: 70%;
  }
`;

export const Button = styled(ButtonAnt)`
  ${buttonCss}
`;

export const ButtonFilter = styled(ButtonAnt)`
  ${buttonCss}
`;

export const ButtonClear = styled(ButtonAnt)`
  ${buttonCss}
  color: var(--gray-45);
  background: var(--white);
  border: 1px solid var(--gray-45);

  :hover,
  :active,
  :focus {
    color: var(--gray-45);
    background: var(--white);
    border: 1px solid var(--gray-45);
  }
`;

const iconCSS = css`
  width: 1.5rem;
  height: 1.5rem;
`;

export const IconFilter = styled(Filter)`
  ${iconCSS}
`;
export const IconAdd = styled(Add)`
  ${iconCSS}
`;

export const CancelPresentationIcon = styled(CancelPresentation)`
  ${iconCSS}
`;

export const ReloadIcon = styled(Reload)`
  ${iconCSS}
`;

export const TopSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 10%;
  width: 100%;
  padding: 0;
  min-height: 3.5rem;

  @media only screen and (max-width: 575px) {
    height: 30%;
  }
`;

export const Content = styled.div`
  height: 90%;
  width: 100%;
  overflow: hidden;
  text-align: center;
`;

export const Row = styled(RowAnt)`
  display: flex;
  width: 100%;

  .span-number{
    font-size: 1rem;
    color: var(--blue-900);
  }
`;

export const Col = styled(ColAnt)``;

export const Modal = styled(ModalAnt)`
  .ant-modal-header {
    padding: 1 1rem;
  }
  .ant-modal-body {
    padding: 0 1rem;
  }
  .ant-modal-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1 1rem;
  }
  .ant-row .ant-form-item {
    margin: 0;
  }
`;

export const Select = styled(SelectAnt)``;

export const Form = styled(FormAnt)``;

export const Input = styled(InputAnt)``;
