import { Modal, Button } from 'antd';
import styled from 'styled-components';

export const Container = styled(Modal)`
  .ant-modal-header {
    padding: 1.3rem;
  }

  .ant-modal-body {
    padding: 0 2rem 1rem;
    overflow-y: auto;
  }
`;

export const ButtonClose = styled(Button)`
    background-color: var(--orange-350);
    width: 100%;
    color: var(--white);
    border: none;
    border-radius: 8px;

    :hover, :focus {
        background-color: var(--orange-350);
        color: var(--white);
        opacity: 70%;
    }
`;

export const TextCapitalize = styled.span`
  text-transform: capitalize;
`;

export const Title = styled.span``;