import styled from "styled-components";

import { EditAlt } from "../../styles/Icons";

import { Select as SelectAnt } from "antd";

const { Option: OptionAnt } = SelectAnt;

export const Select = styled(SelectAnt)<{ height?: string }>`
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select-selector {
    height: ${({ height }) => (height ? "2.5rem" : "auto")} !important;
  }

  .pencilIcon {
    display: inline-block;
  }
`;

export const Option = styled(OptionAnt)``;

export const OptionInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  width: 100%;
  gap: 1rem;
`;

export const OptionLabel = styled.label<{ isCnpj?: boolean }>`
  color: var(--black);
  font-weight: normal;
  font-size: ${({ isCnpj }) => (isCnpj ? "0.8rem" : "1rem")};
  ${({ isCnpj }) =>
    isCnpj &&
    `
      color: var(--gray-200);
      text-align: right;
      width: 150px;
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

export const OptionLabelContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 0.5rem; 
`;

export const PencilIcon = styled(EditAlt)`
  cursor: pointer;
  fill: black;
  width: 24px;
  height: 24px;

  &:hover {
    fill: orange;
  }
`;
