import React, { Dispatch, SetStateAction, useState } from "react";

import api from "../../services/api";
import apiMercury from "../../services/apiMercury";
import { verifyPermission } from "../../services/auth";
import { currencyFormater } from "../../services/currencyFormater";

import { useWindowSize } from "../../hooks/useWindowSize";
import { useResponsiveSizeTable } from "../../hooks/useResponsiveSizeTable";

import { CategoryModel } from "../../models/CategoryModel";
import { RoyaltyType } from "../../models/enums/royaltyType";
import { Royalty as RoyaltyModel } from "../../models/Royalty";

import ModalAudit from "../../pages/Royalty/ModalAudit";
import ModalImageRoyalty from "../../pages/Royalty/ModalImageRoyalty";
import ModalMovimentation from "../../pages/Royalty/ModalMovimentation";
import ModalParcialPayment from "../../pages/Royalty/ModalParcialPayment";

import moment from "moment";

import { Tooltip, Modal, notification } from "antd";

import {
  Container,
  Action,
  MoreIcon,
  Table,
  TableCenter,
  Dropdown,
  Menu,
  TitleResponsive,
  CodBankCel,
  ExpirationDate,
  CodNFVHSYS,
} from "./styles";

const { confirm } = Modal;

interface IProps {
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  setStateSearch: Dispatch<SetStateAction<boolean>>;
  setVisible: Dispatch<SetStateAction<boolean>>;
  royalty: RoyaltyModel[];
  loading: boolean;
  categoryProduct?: CategoryModel;
  setEditRoyalty: Dispatch<SetStateAction<RoyaltyModel | undefined>>;
  handleDelete: (royalty: RoyaltyModel) => void;
  handleDeleteUpload: (royalty) => void;
  handleDownload: (royalty) => void;
  isStoreActive: boolean | undefined;
}

const RoyaltiesList: React.FC<IProps> = ({
  setShouldSearch,
  loading,
  royalty,
  setVisible,
  setEditRoyalty,
  handleDelete,
  handleDeleteUpload,
  setStateSearch,
  handleDownload,
  categoryProduct,
  isStoreActive,
}) => {
  const [selectedRoyalty, setSelectedRoyalty] = useState<
    RoyaltyModel | undefined
  >(undefined);
  const [visibleModalArchive, setVisibleModalArchive] =
    useState<boolean>(false);
  const [visibleModalPayment, setVisibleModalPayment] = useState(false);
  const [visibleModalAudit, setVisibleModalAudit] = useState(false);
  const [visibleModalMovements, setVisibleModalMovements] = useState(false);

  const responsiveWindow = useWindowSize();
  const today = new Date();

  const calculateTotalPayment = (royalty) => {
    return royalty.royalty_payments
      .filter((payment) => payment.deleted_at === null)
      .reduce((total, payment) => total + parseFloat(payment.payment_value), 0);
  };

  const getType = (type) => {
    if (type === RoyaltyType.Royalty) return "Royalty";
    else if (type === RoyaltyType.Marketing) return "Marketing";
    else if (type === RoyaltyType.Sistema) return "Sistema";
    else if (type === RoyaltyType.Serviço) return "Serviço";
    else return "";
  };

  const getTypePurchase = (type) => {
    if (type === RoyaltyType.Royalty) return "royalties";
    else if (type === RoyaltyType.Marketing) return "marketing";
    else if (type === RoyaltyType.Sistema) return "sistema";
    else if (type === RoyaltyType.Serviço) return "serviço";
    else return "";
  };

  const getTotalPayment = (item) => {
    const validPayments = item.royalty_payments.filter(
      (payment) => payment.deleted_at === null
    );

    const totalPayment = validPayments.reduce((total, payment) => {
      return total + parseFloat(payment.payment_value);
    }, 0);

    return totalPayment;
  };

  const getStatus = (item: RoyaltyModel) => {
    const today = moment();
    const expirationStatus = moment(
      item.expiration_date,
      "DD-MM-YYYY HH:mm:ss"
    ).isBefore(moment(today, "DD-MM-YYYY HH:mm:ss"));
    const totalPayment = getTotalPayment(item);

    if (!item?.cod_bankslip) return "Aguardando Boleto";
    if (totalPayment && +item?.value_bankslip === totalPayment) return "Pago";
    else if (
      item?.cod_bankslip &&
      !expirationStatus &&
      +item?.value_bankslip !== +item?.payment_value
    )
      return "Em Aberto";
    else if (expirationStatus) return "Em Atraso";
    else return "";
  };

  const columns = [
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
      width: "10%",
      render: (text) => (
        <Tooltip placement="top" title={getType(text)}>
          <div>{getType(text)}</div>
        </Tooltip>
      ),
    },
    {
      title: (
        <TitleResponsive>
          <span className="titleResponsive">Código do </span>Boleto
        </TitleResponsive>
      ),
      dataIndex: "cod_bankslip",
      key: "cod_bankslip",
      width: "10%",
      responsive: ["sm"] as any,
      render: (text) => (
        <Tooltip placement="top" title={text}>
          <CodBankCel>{text}</CodBankCel>
        </Tooltip>
      ),
    },
    {
      title: (
        <TitleResponsive>
          Valor
          <span className="titleResponsive"> do Boleto</span>
        </TitleResponsive>
      ),
      dataIndex: "value_bankslip",
      key: "value_bankslip",
      width: "10%",
      render: (text) => (
        <Tooltip placement="top" title={`R$ ${currencyFormater(+text || 0)}`}>
          <span>R$ {currencyFormater(+text || 0)}</span>
        </Tooltip>
      ),
    },
    {
      title: "Total Pago",
      dataIndex: "payment_value",
      key: "payment_value",
      width: "9%",
      responsive: ["md"] as any,
      render: (_, record) => {
        const totalPayment = getTotalPayment(record);

        return <span> R$ {currencyFormater(totalPayment)}</span>;
      },
    },
    {
      title: "Mês Referente",
      dataIndex: "month",
      key: "month",
      width: "8%",
      responsive: ["lg"] as any,
      render: (text) => (
        <Tooltip placement="top" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "Nota Fiscal",
      dataIndex: "cod_nf",
      key: "cod_nf",
      width: "10%",
      responsive: ["sm"] as any,
      render: (text, record) => (
        <Tooltip
          placement="top"
          title={record.type === RoyaltyType.Serviço ? "-" : text}
        >
          <CodNFVHSYS cod={record.type === RoyaltyType.Serviço}>
            {record.type === RoyaltyType.Serviço ? "-" : text}
          </CodNFVHSYS>
        </Tooltip>
      ),
    },
    {
      title: (
        <TitleResponsive>
          <span className="titleResponsive">Código VHSYS do </span>Pedido
        </TitleResponsive>
      ),
      dataIndex: "cod_vhsys",
      key: "cod_vhsys",
      width: "10%",
      responsive: ["sm"] as any,
      render: (text, record) => (
        <Tooltip
          placement="top"
          title={record.type !== RoyaltyType.Serviço ? "-" : text}
        >
          <CodNFVHSYS cod={record.type !== RoyaltyType.Serviço}>
            {record.type !== RoyaltyType.Serviço ? "-" : text}
          </CodNFVHSYS>
        </Tooltip>
      ),
    },
    {
      title: "Valido até",
      dataIndex: "expiration_date",
      key: "expiration_date",
      width: "10%",
      render: (text) =>
        text ? (
          <Tooltip
            placement="top"
            title={`${moment(text, "DD-MM-YYYY hh:mm:ss").format(
              "DD/MM/YYYY"
            )}`}
          >
            <ExpirationDate
              date={
                moment(today, "DD-MM-YYYY hh:mm:ss").toISOString() >
                moment(text, "DD-MM-YYYY hh:mm:ss").toISOString()
              }
            >
              {moment(text, "DD-MM-YYYY hh:mm:ss").format("DD/MM/YYYY")}
            </ExpirationDate>
          </Tooltip>
        ) : (
          <TableCenter>-</TableCenter>
        ),
    },
    {
      title: "Criado em",
      dataIndex: "created_at",
      key: "created_at",
      width: "9%",
      responsive: ["lg"] as any,
      render: (text) => (
        <Tooltip
          placement="top"
          title={`${moment(text, "DD-MM-YYYY hh:mm:ss").format("DD/MM/YYYY")}`}
        >
          <>{moment(text, "DD-MM-YYYY hh:mm:ss").format("DD/MM/YYYY")}</>
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "8%",
      responsive: ["lg"] as any,
      render: (_, record) => (
        <Tooltip title={getStatus(record)}>
          <>{getStatus(record)}</>
        </Tooltip>
      ),
    },
    {
      title: <TableCenter>Ações</TableCenter>,
      width: "5%",
      render: (_, record) => (
        <Action>
          {!isStoreActive && (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      setSelectedRoyalty(record);
                      setVisibleModalArchive(true);
                    }}
                  >
                    Visualizar arquivos
                  </Menu.Item>
                  {record?.cod_bankslip && (
                    <Menu.Item
                      onClick={() => {
                        setSelectedRoyalty(record);
                        setVisibleModalPayment(true);
                      }}
                    >
                      <span>Confirmar pagamento</span>
                    </Menu.Item>
                  )}
                  
                  {record?.cod_bankslip && (
                    <Menu.Item
                      onClick={() => {
                        setSelectedRoyalty(record);
                        setVisibleModalMovements(true);
                      }}
                    >
                      Movimentações dos pagamentos
                    </Menu.Item>
                  )}
                  {record?.cod_bankslip && (
                    <Menu.Item
                      onClick={() => {
                        setVisibleModalAudit(true);
                        setSelectedRoyalty(record);
                      }}
                    >
                      <span>Auditoria de pagamento</span>
                    </Menu.Item>
                  )}
                  {verifyPermission("royalty.edit") && (
                    <Menu.Item
                      onClick={() => {
                        setVisible(true);
                        setEditRoyalty(record);
                      }}
                    >
                      <span>Editar</span>
                    </Menu.Item>
                  )}
                  {verifyPermission("royalty.delete") && (
                    <Menu.Item onClick={() => handleDelete(record)}>
                      <span style={{ color: "var(--orange-600)" }}>
                        Excluir
                      </span>
                    </Menu.Item>
                  )}
                </Menu>
              }
              trigger={["click"]}
              placement="bottomCenter"
              arrow
            >
              <MoreIcon />
            </Dropdown>
          )}
        </Action>
      ),
    },
  ];

  const getRowClassName = (record) => {
    const totalPayment = calculateTotalPayment(record);
    const expirationDate = moment(
      record.expiration_date,
      "DD-MM-YYYY HH:mm:ss"
    );
    const today = moment();

    if (parseFloat(record.value_bankslip) === totalPayment) {
      return "totalPay";
    } else if (expirationDate.isBefore(today)) {
      return "expiratedDate";
    } else if (totalPayment < parseFloat(record.value_bankslip)) {
      return "partialPay";
    } else {
      return "default";
    }
  };

  return (
    <>
      <Container>
        <Table
          loading={loading}
          columns={columns}
          pagination={false}
          scroll={{ y: useResponsiveSizeTable(responsiveWindow) }}
          dataSource={royalty.map((entity) => ({
            ...entity,
            key: entity.id,
          }))}
          rowClassName={getRowClassName}
        />
      </Container>

      <ModalImageRoyalty
        loading={loading}
        handleDeleteUpload={handleDeleteUpload}
        setVisible={setVisibleModalArchive}
        visible={visibleModalArchive}
        royalty={selectedRoyalty as RoyaltyModel}
        setShouldSearch={setStateSearch}
        handleDownload={handleDownload}
      />

      <ModalParcialPayment
        visible={visibleModalPayment}
        setVisible={setVisibleModalPayment}
        royalty={selectedRoyalty}
        setShouldSearch={setShouldSearch}
      />

      <ModalAudit
        visible={visibleModalAudit}
        setVisible={setVisibleModalAudit}
        royalty={selectedRoyalty}
      />

      <ModalMovimentation
        visible={visibleModalMovements}
        setVisible={setVisibleModalMovements}
        royalty={selectedRoyalty}
        setShouldSearch={setShouldSearch}
      />
    </>
  );
};

export default RoyaltiesList;
