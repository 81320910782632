import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";

import api from "../../services/api";

import InputMask from "../InputMask";
import { ProductStore as ProductStoreModel } from "../../models/ProductStore";
import FormCard from "../../components/FormCard";
import CFOPs from "./DadosTributarios/CFOPs.json";
import taxRegimeICMS from "./DadosTributarios/ICMS/taxRegimeICMS.json";
import taxSituationNormalICMS from "./DadosTributarios/ICMS/taxSituationNormalICMS.json";
import taxSituationNationalICMS from "./DadosTributarios/ICMS/taxSituationNationalICMS.json";
import originICMS from "./DadosTributarios/ICMS/originICMS.json";
import modalityBCICMS from "./DadosTributarios/ICMS/modalityBCICMS.json";

import Brasil from "../../assets/images/brasil.webp";
import Paraguai from "../../assets/images/paraguai.webp";

import taxSituation from "./DadosTributarios/taxSituation.json";
import typeCalculation from "./DadosTributarios/typeCalculation.json";

import { enumTaxSituation } from "./DadosTributarios/enums/enumTaxSituation";
import { RegimeTributario } from "./DadosTributarios/enums/ICMS/RegimeTributario";
import { TaxSituation } from "./DadosTributarios/enums/ICMS/TaxSituation";
import { enumTypeCalc } from "./DadosTributarios/enums/enumTypeCalc";

import { Input, Select, Tooltip, notification } from "antd";
import {
  Modal,
  Tabs,
  Col,
  Button,
  Form,
  CancelButton,
  ContentUnity,
  Country,
  ContainerCountry,
  CountryImg,
} from "./styles";
import MonetaryInput2 from "../MonetaryInput2";

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  currentProduct: ProductStoreModel | null;
  setShouldUpdated: Dispatch<SetStateAction<boolean>>;
  storeInfo?: any;
}

type ConditionFields = {
  tax_regime?: number;
  icms_tax_situation?: string;
  cofins_tax_situation?: string;
  pis_tax_situation?: string;
  bc_icms?: number;
  bc_icms_st?: number;
};

const options = [
  { name: "Quilograma (kg)", value: "kg" },
  { name: "Litro (l)", value: "l" },
  { name: "Metro cúbico (m³)", value: "m³" },
  { name: "Unidade (un)", value: "un" },
  { name: "Metro (m)", value: "m" },
];

const ModalProductFranchisee: React.FC<IProps> = ({
  visible,
  setVisible,
  currentProduct,
  setShouldUpdated,
  storeInfo,
}) => {
  const [typeCalcPIS, setTypeCalcPIS] = useState<number | null>(null);
  const [typeCalcCOFINS, setTypeCalcCOFINS] = useState<number | null>(null);
  const [conditionFields, setConditionFields] = useState<ConditionFields>();
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);
  const [formDados] = Form.useForm();
  const [formTributos] = Form.useForm();
  const [formAdditionalInfo] = Form.useForm();

  const [valueOfSale, setValueOfSale] = useState<number | undefined>();

  const formattedValue = valueOfSale?.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const formattedValuePY = valueOfSale?.toLocaleString("es-PY", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  useEffect(() => {
    const modality = modalityBCICMS?.find((modality) =>
      currentProduct?.bc_icms
        ? +modality.value === +currentProduct?.bc_icms
        : null
    );

    setConditionFields({
      tax_regime: currentProduct?.tax_regime,
      icms_tax_situation: currentProduct?.icms_tax_situation,
      cofins_tax_situation: currentProduct?.cofins_tax_situation,
      pis_tax_situation: currentProduct?.pis_tax_situation,
      bc_icms: +(currentProduct?.bc_icms || ""),
      bc_icms_st: +(currentProduct?.bc_icms_st || ""),
    });
    formDados.setFieldsValue({
      name: currentProduct?.product?.name,
      cod_product: currentProduct?.product?.cod_product,
      cod_ncm: currentProduct?.cod_ncm,
      category_name: currentProduct?.product?.category?.name,
      price_unit: currentProduct?.price_unit,
      unity_taxable: currentProduct?.unity_taxable,
      cfop: currentProduct?.cfop,
    });
    formTributos.setFieldsValue({
      tax_regime: currentProduct?.tax_regime,
      icms_tax_situation: currentProduct?.icms_tax_situation,
      icms_origin: currentProduct?.icms_origin,
      icms_aliquot_percentage: currentProduct?.icms_aliquot_percentage,
      bc_icms: modality?.label,
      bc_icms_st: currentProduct?.bc_icms_st,
      redution_icms: currentProduct?.redution_icms,
      aliquot_final_consumer: currentProduct?.aliquot_final_consumer,
      pis_tax_situation: currentProduct?.pis_tax_situation,
      pis_aliquot_percentage: currentProduct?.pis_aliquot_percentage,
      pis_aliquot_value: currentProduct?.pis_aliquot_value,
      pis_calculation_type: currentProduct?.pis_calculation_type,
      cofins_calculation_type: currentProduct?.cofins_calculation_type,
      cofins_tax_situation: currentProduct?.cofins_tax_situation,
      cofins_aliquot_percentage: currentProduct?.cofins_aliquot_percentage,
      cofins_aliquot_value: currentProduct?.cofins_aliquot_value,
      codigo_beneficio_fiscal: currentProduct?.codigo_beneficio_fiscal,
      cest: currentProduct?.cest,
      tax_code_pyg: currentProduct?.tax_code_pyg,
      iva_taxable_proportion_number:
        currentProduct?.iva_taxable_proportion_number,
      iva_rate_number: currentProduct?.iva_rate_number,
    });
    formAdditionalInfo.setFieldsValue({
      additional_information: currentProduct?.additional_information,
    });
    setValueOfSale(currentProduct?.price_unit);
    setTypeCalcPIS(formTributos.getFieldValue("pis_calculation_type"));
    setTypeCalcCOFINS(formTributos.getFieldValue("cofins_calculation_type"));
  }, [currentProduct, formDados, formTributos, formAdditionalInfo]);

  const handleState = ({ target: { name, value } }) => {
    setConditionFields((oldValues) => ({ ...oldValues, [name]: value }));
  };

  const handleStateCest = (event) => {
    if (event.key === "Backspace") {
      return;
    }
    if (event.target.name === "cest" && event.target.value.lenght < 7) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
    }
  };

  const hasErrorFormDados = async () => {
    try {
      await formDados.validateFields();
    } catch {
      return true;
    }
    return false;
  };
  const hasErrorFormTributos = async () => {
    try {
      await formTributos.validateFields();
    } catch {
      return true;
    }
    return false;
  };

  const handleUpdate = async () => {
    const fieldsOnFormDados = formDados.getFieldsValue();
    fieldsOnFormDados.price_taxable = parseFloat(
      formattedValue?.replace(/,/g, "") || "0"
    );
    fieldsOnFormDados.price_unit = parseFloat(
      formattedValue?.replace(/,/g, "") || "0"
    );
    const fieldsOnFormTributos = formTributos.getFieldsValue();
    fieldsOnFormTributos.bc_icms &&
      (fieldsOnFormTributos.bc_icms = +(conditionFields?.bc_icms || ""));
    fieldsOnFormTributos.bc_icms_st &&
      (fieldsOnFormTributos.bc_icms_st = +(conditionFields?.bc_icms_st || ""));
    const fieldsOnFormAddInfo = formAdditionalInfo.getFieldsValue();
    const cest = formTributos
      .getFieldValue("cest")
      ?.replace(/[^0-9]/g, "")
      ?.toString();

    const hasErrorDadosForm = await hasErrorFormDados();
    const hasErrorTributosForm = await hasErrorFormTributos();
    if (hasErrorDadosForm) {
      return notification.warning({
        message: "Verifique os campos da aba Dados",
        description: "Preencha todas os campos da aba Dados correatmente.",
        duration: 3,
      });
    }

    if (hasErrorTributosForm) {
      return notification.warning({
        message: "Verifique os campos da aba Tributos",
        description: "Preencha todas os campos da aba Tributos correatmente.",
        duration: 3,
      });
    }
    Modal.confirm({
      title: "Atualização movimentação",
      content: "Deseja continuar e atualizar esta movimentação?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      async onOk() {
        setLoading(true);

        try {
          if (!cest || cest.length === 0 || cest.length === 7) {
            await api.put(`/products_store/${currentProduct?.id}`, {
              ...fieldsOnFormDados,
              ...fieldsOnFormTributos,
              ...fieldsOnFormAddInfo,
              cest: cest,
            });
            notification.success({
              message: "Produto alterado com sucesso",
              duration: 5,
            });
            setShouldUpdated(true);
            setVisible(false);
          } else {
            notification.warning({
              message: "Ops, Algo deu errado",
              description: "O CEST deve ter pelo menos 7 dígitos",
              duration: 5,
            });
            return;
          }
        } catch (e) {
          const _message =
            //@ts-ignore
            e?.response?.data?.message ||
            "Houve um erro ao atualizar o produto";
          notification.error({
            message: _message,
            duration: 3,
          });
        } finally {
          setLoading(false);
          setShouldUpdated(false);
        }
      },
    });
  };

  return (
    <Modal
      title={
        <ContainerCountry>
          <span> Cadastro do Produto</span>
          {storeInfo?.company?.country === "brasil" ? (
            <CountryImg>
              <Country src={Brasil} />
              <span>🇧🇷</span>
            </CountryImg>
          ) : (
            <CountryImg>
              <Country src={Paraguai} />
              <span>🇵🇾</span>
            </CountryImg>
          )}
        </ContainerCountry>
      }
      centered
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      width={1000}
      footer={[
        <CancelButton key="back" type="link" onClick={() => setVisible(false)}>
          Cancelar
        </CancelButton>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => handleUpdate()}
        >
          Salvar
        </Button>,
      ]}
      destroyOnClose
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Dados" key="1">
          <Form layout="vertical" form={formDados}>
            <FormCard title={"Dados do produto"}>
              <Col sm={10} xs={24}>
                <Form.Item label="Nome do Produto" name="name">
                  <Input
                    type="text"
                    name="name"
                    placeholder="Nome do Produto"
                    disabled
                  />
                </Form.Item>
              </Col>

              <Col sm={7} xs={12}>
                <Form.Item label="Código do produto" name="cod_product">
                  <Input
                    type="text"
                    name="cod_product"
                    placeholder="Código do produto"
                    disabled
                  />
                </Form.Item>
              </Col>

              <>
                <Col sm={7} xs={12}>
                  <Form.Item
                    label="NCM"
                    name="cod_ncm"
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <Input type="number" name="cod_ncm" placeholder="NCM" />
                  </Form.Item>
                </Col>
              </>
              <Col sm={7} xs={12}>
                <Form.Item label="Categoria" name="category_name">
                  <Input
                    type="text"
                    name="category_name"
                    placeholder="Categoria"
                    disabled
                  />
                </Form.Item>
              </Col>
              {storeInfo?.company?.country === "brasil" && (
                <Col sm={5} xs={12}>
                  <Form.Item
                    label="Valor de Venda"
                    name="price_unit"
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <MonetaryInput2
                      defaultValue={valueOfSale}
                      getValue={(value) => setValueOfSale(+value)}
                    />
                  </Form.Item>
                </Col>
              )}

              {storeInfo?.company?.country === "paraguai" && (
                <Col sm={5} xs={12}>
                  <Form.Item
                    label="Valor de Venda"
                    name="price_unit"
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <MonetaryInput2
                      currency="PYG"
                      defaultValue={valueOfSale}
                      getValue={(value) => setValueOfSale(+value)}
                    />
                  </Form.Item>
                </Col>
              )}

              <Col sm={6} xs={12}>
                <Form.Item
                  label="Unidade Tributável"
                  name="unity_taxable"
                  rules={[{ required: true, message: "Campo obrigatório" }]}
                >
                  <Select
                    loading={loading}
                    onChange={(e: string) => setSelectedOption(e)}
                    value={selectedOption}
                  >
                    {options.map((option) => (
                      <Select.Option key={option.name} value={option.value}>
                        {option.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {storeInfo?.company?.country === "paraguai" ? (
                <Col sm={6} xs={12}>
                  <Form.Item
                    label="Valor Unitário Tributável"
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <ContentUnity>
                      <p>
                        Gs.
                        {formattedValuePY}
                      </p>
                    </ContentUnity>
                  </Form.Item>
                </Col>
              ) : (
                <Col sm={6} xs={12}>
                  <Form.Item
                    label="Valor Unitário Tributável"
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <ContentUnity>
                      <p>R$ {formattedValue?.replace(".", ",")}</p>
                    </ContentUnity>
                  </Form.Item>
                </Col>
              )}

              {storeInfo?.company?.country === "brasil" && (
                <Col sm={24} xs={24}>
                  <Form.Item
                    label="CFOP"
                    name="cfop"
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <Select placeholder="Selecione o CFOP">
                      {CFOPs.map((cfop) => (
                        <Option
                          label={cfop.label}
                          value={cfop.value}
                          key={cfop.value}
                        >
                          {cfop.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </FormCard>
          </Form>
        </TabPane>
        <TabPane forceRender={true} tab="Tributos" key="2">
          {storeInfo?.company?.country === "brasil" ? (
            //---------------------------------------------------------------- Brasil

            <Form layout="vertical" form={formTributos}>
              <FormCard title="ICMS - Imposto sobre Circulação de Mercadorias e Serviços">
                <Col sm={12} xs={24}>
                  <Form.Item
                    label="Regime Tributário"
                    name="tax_regime"
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <Select
                      placeholder="Selecione o tipo de tributação"
                      onChange={(value) => {
                        handleState({ target: { name: "tax_regime", value } });
                        formTributos.setFieldsValue({
                          icms_tax_situation: null,
                        });
                      }}
                    >
                      {taxRegimeICMS.map((taxRegime) => (
                        <Option
                          label={taxRegime.label}
                          value={taxRegime.value}
                          key={taxRegime.value}
                        >
                          {taxRegime.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col sm={12} xs={24}>
                  <Form.Item
                    label="Situação Tributária"
                    name="icms_tax_situation"
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <Select
                      placeholder="Escolha a opção"
                      disabled={!conditionFields?.tax_regime}
                      onChange={(value) =>
                        handleState({
                          target: { name: "icms_tax_situation", value },
                        })
                      }
                    >
                      {conditionFields?.tax_regime ===
                      RegimeTributario.TAX_NORMAL
                        ? taxSituationNormalICMS.map((taxNormal) => (
                            <Option
                              label={taxNormal.label}
                              value={taxNormal.value}
                              key={taxNormal.value}
                            >
                              {taxNormal.label}
                            </Option>
                          ))
                        : taxSituationNationalICMS.map((simpleNational) => (
                            <Option
                              label={simpleNational.label}
                              value={simpleNational.value}
                              key={simpleNational.value}
                            >
                              {simpleNational.label}
                            </Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm={12} xs={24}>
                  <Form.Item
                    label="Origem"
                    name="icms_origin"
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <Select
                      placeholder="Escolha a opção"
                      disabled={!conditionFields?.tax_regime}
                    >
                      {originICMS.map((origin) => (
                        <Option
                          label={origin.label}
                          value={origin.value}
                          key={origin.value}
                        >
                          {origin.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm={12} xs={24}>
                  <Form.Item label="CEST" name="cest">
                    <InputMask
                      placeholder="CEST"
                      mask="99.999.99"
                      name="cest"
                      value={"99.999.99"}
                      onKeyDown={handleStateCest}
                    />
                  </Form.Item>
                </Col>
                {conditionFields?.icms_tax_situation ===
                  TaxSituation.FULL_TAXED && (
                  <>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        label="Alíquota (%)"
                        name="icms_aliquot_percentage"
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="Alíquota do Imposto(%)"
                          name="icms_aliquot_percentage"
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={12} xs={24}>
                      <Form.Item
                        label="Modalidade de Determinação da BC ICMS"
                        name="bc_icms"
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <Select
                          placeholder="Escolha a Opção"
                          onChange={(value) =>
                            handleState({
                              target: { name: "bc_icms", value },
                            })
                          }
                        >
                          {modalityBCICMS.map((modalityBC) => (
                            <Option
                              label={modalityBC.label}
                              value={modalityBC.value}
                              key={modalityBC.value}
                            >
                              {modalityBC.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </>
                )}

                {conditionFields?.icms_tax_situation ===
                  TaxSituation.BASE_REDUCTION && (
                  <>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        label="Alíquota (%)"
                        name="icms_aliquot_percentage"
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="Alíquota do Imposto(%)"
                          name="icms_aliquot_percentage"
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={12} xs={24}>
                      <Form.Item
                        label="Modalidade de Determinação da BC ICMS ST"
                        name="bc_icms_st"
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <Select
                          placeholder="Escolha a Opção"
                          onChange={(value) =>
                            handleState({
                              target: { name: "bc_icms_st", value },
                            })
                          }
                        >
                          {modalityBCICMS.map((modalityBC) => (
                            <Option
                              label={modalityBC.label}
                              value={modalityBC.value}
                              key={modalityBC.value}
                            >
                              {modalityBC.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        label="Redução do BC ICMS (%)"
                        name="redution_icms"
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="Redução do BC ICMS (%)"
                          name="redution_icms"
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}

                {conditionFields?.icms_tax_situation ===
                  TaxSituation.CHARGED_ST && (
                  <Col sm={12} xs={24}>
                    <Form.Item
                      label="Alíquota suportada pelo Consumidor Final"
                      name="aliquot_final_consumer"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <Input
                        type="number"
                        name="aliquot_final_consumer"
                        placeholder="Alíquota suportada pelo Consumidor Final (%)"
                      />
                    </Form.Item>
                  </Col>
                )}
              </FormCard>

              <FormCard title="PIS - Programa de Integração Social">
                <Col sm={24} xs={24}>
                  <Form.Item
                    label="Situação Tributária"
                    name="pis_tax_situation"
                  >
                    <Select
                      placeholder="Escolha a Opção"
                      onChange={(value) =>
                        handleState({
                          target: { name: "pis_tax_situation", value },
                        })
                      }
                    >
                      {taxSituation.map((taxSituationPIS) => (
                        <Option
                          value={taxSituationPIS.value}
                          label={taxSituationPIS.label}
                          key={taxSituationPIS.value}
                        >
                          {taxSituationPIS.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {(conditionFields?.pis_tax_situation ===
                  enumTaxSituation.TAX_OPERATION_NORMAL ||
                  conditionFields?.pis_tax_situation ===
                    enumTaxSituation.TAX_OPERATION_DIFFERENTIATED) && (
                  <Col sm={12} xs={12}>
                    <Form.Item
                      label="Alíquota(%)"
                      name="pis_aliquot_percentage"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <Input
                        type="number"
                        name="pis_aliquot_percentage"
                        placeholder="Alíquota(%)"
                      />
                    </Form.Item>
                  </Col>
                )}

                {conditionFields?.pis_tax_situation ===
                  enumTaxSituation.TAX_OPERATION_QTDE && (
                  <Col sm={12} xs={12}>
                    <Form.Item
                      label="Alíquota(R$)"
                      name="pis_aliquot_value"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <Input
                        type="number"
                        name="pis_aliquot_value"
                        placeholder="Alíquota(R$)"
                      />
                    </Form.Item>
                  </Col>
                )}

                {(conditionFields?.pis_tax_situation ===
                  enumTaxSituation.OUTPUT_OPERATION ||
                  conditionFields?.pis_tax_situation ===
                    enumTaxSituation.ANOTHER_OPERATIONS) && (
                  <Col sm={12} xs={12}>
                    <Form.Item
                      label="Tipo de Cálculo"
                      name="pis_calculation_type"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <Select
                        placeholder="Escolha a Opção"
                        onChange={(value) => setTypeCalcPIS(+value)}
                      >
                        {typeCalculation.map((typeCalc) => (
                          <Option
                            value={typeCalc.value}
                            label={typeCalc.label}
                            key={typeCalc.value}
                          >
                            {typeCalc.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}

                {((typeCalcPIS === enumTypeCalc.PERCENT &&
                  conditionFields?.pis_tax_situation ===
                    enumTaxSituation.OUTPUT_OPERATION) ||
                  (typeCalcPIS === enumTypeCalc.PERCENT &&
                    conditionFields?.pis_tax_situation ===
                      enumTaxSituation.ANOTHER_OPERATIONS)) && (
                  <Col sm={12} xs={12}>
                    <Form.Item
                      label="Alíquota(%)"
                      name="pis_aliquot_percentage"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <Input
                        type="number"
                        name="pis_aliquot_percentage"
                        placeholder="Alíquota(%)"
                      />
                    </Form.Item>
                  </Col>
                )}

                {((typeCalcPIS === enumTypeCalc.VALUE &&
                  conditionFields?.pis_tax_situation ===
                    enumTaxSituation.OUTPUT_OPERATION) ||
                  (typeCalcPIS === enumTypeCalc.VALUE &&
                    conditionFields?.pis_tax_situation ===
                      enumTaxSituation.ANOTHER_OPERATIONS)) && (
                  <Col sm={12} xs={12}>
                    <Form.Item
                      label="Alíquota(R$)"
                      name="pis_aliquot_value"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <Input
                        type="number"
                        name="pis_aliquot_value"
                        placeholder="Alíquota(R$)"
                      />
                    </Form.Item>
                  </Col>
                )}
              </FormCard>

              <FormCard title="COFINS - Contribuição para o Financeiro da Seguridade Social">
                <Col sm={24} xs={24}>
                  <Form.Item
                    label="Situação Tributária"
                    name="cofins_tax_situation"
                  >
                    <Select
                      placeholder="Escolha a Opção"
                      onChange={(value) =>
                        handleState({
                          target: { name: "cofins_tax_situation", value },
                        })
                      }
                    >
                      {taxSituation.map((taxSituationCOFINS) => (
                        <Option
                          value={taxSituationCOFINS.value}
                          label={taxSituationCOFINS.label}
                          key={taxSituationCOFINS.value}
                        >
                          {taxSituationCOFINS.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {(conditionFields?.cofins_tax_situation ===
                  enumTaxSituation.TAX_OPERATION_NORMAL ||
                  conditionFields?.cofins_tax_situation ===
                    enumTaxSituation.TAX_OPERATION_DIFFERENTIATED) && (
                  <Col sm={12} xs={12}>
                    <Form.Item
                      label="Alíquota(%)"
                      name="cofins_aliquot_percentage"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <Input
                        type="number"
                        name="cofins_aliquot_percentage"
                        placeholder="Alíquota(%)"
                      />
                    </Form.Item>
                  </Col>
                )}

                {conditionFields?.cofins_tax_situation ===
                  enumTaxSituation.TAX_OPERATION_QTDE && (
                  <Col sm={12} xs={12}>
                    <Form.Item
                      label="Alíquota(R$)"
                      name="cofins_aliquot_value"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <Input
                        type="number"
                        name="cofins_aliquot_value"
                        placeholder="Alíquota(R$)"
                      />
                    </Form.Item>
                  </Col>
                )}

                {(conditionFields?.cofins_tax_situation ===
                  enumTaxSituation.OUTPUT_OPERATION ||
                  conditionFields?.cofins_tax_situation ===
                    enumTaxSituation.ANOTHER_OPERATIONS) && (
                  <Col sm={12} xs={12}>
                    <Form.Item
                      label="Tipo de Cálculo"
                      name="cofins_calculation_type"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <Select
                        placeholder="Escolha a Opção"
                        onChange={(value) => setTypeCalcCOFINS(+value)}
                      >
                        {typeCalculation.map((typeCalc) => (
                          <Option
                            value={typeCalc.value}
                            label={typeCalc.label}
                            key={typeCalc.value}
                          >
                            {typeCalc.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}

                {((typeCalcCOFINS === enumTypeCalc.PERCENT &&
                  conditionFields?.cofins_tax_situation ===
                    enumTaxSituation.OUTPUT_OPERATION) ||
                  (typeCalcCOFINS === enumTypeCalc.PERCENT &&
                    conditionFields?.cofins_tax_situation ===
                      enumTaxSituation.ANOTHER_OPERATIONS)) && (
                  <Col sm={12} xs={12}>
                    <Form.Item
                      label="Alíquota(%)"
                      name="cofins_aliquot_percentage"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <Input
                        type="number"
                        name="cofins_aliquot_percentage"
                        placeholder="Alíquota(%)"
                      />
                    </Form.Item>
                  </Col>
                )}

                {((typeCalcCOFINS === enumTypeCalc.VALUE &&
                  conditionFields?.cofins_tax_situation ===
                    enumTaxSituation.OUTPUT_OPERATION) ||
                  (typeCalcCOFINS === enumTypeCalc.VALUE &&
                    conditionFields?.cofins_tax_situation ===
                      enumTaxSituation.ANOTHER_OPERATIONS)) && (
                  <Col sm={12} xs={12}>
                    <Form.Item
                      label="Alíquota(R$)"
                      name="cofins_aliquot_value"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <Input
                        type="number"
                        name="cofins_aliquot_value"
                        placeholder="Alíquota(R$)"
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col sm={24} xs={24}>
                  <Form.Item
                    label="Codigo beneficio fiscal"
                    name="codigo_beneficio_fiscal"
                    rules={[{ required: false, message: "Campo obrigatório" }]}
                  >
                    <Input
                      type="text"
                      name="codigo_beneficio_fiscal"
                      placeholder="codigo beneficio fiscal"
                    />
                  </Form.Item>
                </Col>
              </FormCard>
            </Form>
          ) : (
            //---------------------------------------------------------------- Paraguai
            <Form layout="vertical" form={formTributos}>
              <FormCard title="Tributações">
                <Col sm={8} xs={24}>
                  <Tooltip title="Somente para visualização, não é possível editar">
                    <Form.Item
                      label="Código de Tributação IVA"
                      name="tax_code_pyg"
                    >
                      <Input name="tax_code_pyg" disabled />
                    </Form.Item>
                  </Tooltip>
                </Col>

                <Col sm={8} xs={24}>
                  <Tooltip title="Somente para visualização, não é possível editar">
                    <Form.Item
                      label="Proporção Tributável do IVA"
                      name="iva_taxable_proportion_number"
                    >
                      <Input
                        name="iva_taxable_proportion_number"
                        disabled
                        addonBefore="%"
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col sm={8} xs={24}>
                  <Tooltip title="Somente para visualização, não é possível editar">
                    <Form.Item label="Taxa do IVA" name="iva_rate_number">
                      <Input name="iva_rate_number" disabled addonBefore="%" />
                    </Form.Item>
                  </Tooltip>
                </Col>
              </FormCard>
            </Form>
          )}
        </TabPane>

        <TabPane tab="Informações Adicionais" key="3">
          <Form layout="vertical" form={formAdditionalInfo}>
            <FormCard>
              <Col sm={24} xs={24}>
                <Form.Item
                  label="Informações Adicionais"
                  name="additional_information"
                >
                  <TextArea rows={5} name="additional_information" />
                </Form.Item>
              </Col>
            </FormCard>
          </Form>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default ModalProductFranchisee;
