import React, { useEffect, useState } from "react";

import moment, { Moment } from "moment";

import api from "../../services/api";
import apiMsNf from "../../services/apiMsNf";
import { verifyPermission } from "../../services/auth";

import NfeList from "./NfeList";
import NFEModalDevolution from "./NFEModalDevolution";

import Centralizer from "../../containers/Centralizer";
import PageContainer from "../../containers/PageContainer";

import GetStore from "../../components/GetStore";
import MonetaryInput from "../../components/MonetaryInput2";
import StoreSelectionPage from "../../components/StoreSelectionPage";

import { StoreSelectNfe } from "../../models/StoreSelectNfe";

import { DatePicker, Empty, notification, Spin } from "antd";

import nfeImage from "../../assets/svg/nfeImage.svg";

import {
  Button,
  Col,
  Container,
  Content,
  Row,
  TopSide,
  Modal,
  Select,
  Form,
  Input,
  ButtonFilter,
  ButtonClear,
  IconFilter,
  IconAdd,
  CancelPresentationIcon,
  ReloadIcon,
} from "./styles";

const { Option } = Select;

const { TextArea } = Input;

interface IPaginateProps {
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

const Nfe: React.FC = () => {
  const [form] = Form.useForm();
  const [formFilter] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [shouldSearch, setShouldSearch] = useState<boolean>(true);
  const [visibleFilter, setVisibleFilter] = useState<boolean>(false);
  const [visibleRenderUseless, setVisibleRenderUseless] =
    useState<boolean>(false);
  const [store, setStore] = useState<StoreSelectNfe | undefined | null>(null);
  const [nfeList, setNfeList] = useState<any[]>([]);
  const [productsStore, setProductsStore] = useState<any[]>([]);
  const [filter, setFilter] = useState<{
    name: string;
    numero: string;
    situation: number;
    min_value: number;
    max_value: number;
  }>({ name: "", numero: "", situation: 0, min_value: 0, max_value: 0 });
  const [date, setDate] = useState<Moment>(moment());
  const [proxNum, setProxNum] = useState<number>(0);
  const [pagination, setPagination] = useState<IPaginateProps>({
    page: 1,
    size: 10,
    totalElements: 0,
    totalPages: 0,
  });

  useEffect(() => {
    const fetchStores = async () => {
      setLoading(true);
      try {
        let URL = `/nfe?store_id=${store?.key}`;

        if (filter.numero !== "") {
          URL += `&numero=${filter.numero}`;
        } else {
          URL += `&data_inicial=${date.format(
            "01/MM/YYYY"
          )}&data_final=${moment(date).add(1, "month").format("01/MM/YYYY")}`;
          URL += `&page=${pagination.page}&size=${pagination.size}`;
        }

        const { data } = await apiMsNf.get(URL);

        filterSearch(data);
        setProxNum(data.proximo_numero);
      } catch (e) {
        const _message = "Houve um erro ao realizar busca";
        //@ts-ignore
        const _description = e?.response?.data?.message;
        notification.error({
          message: _message,
          description: _description,
          duration: 5,
        });
      } finally {
        setLoading(false);
        setShouldSearch(false);
      }
    };
    if (store && shouldSearch) {
      fetchStores();
    }
  }, [store, shouldSearch]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const {
          data: { content },
        } = await api.get(
          `/products_store/store/${store?.key}?withDeleted=true`
        );
        setProductsStore(content);
      } catch (e) {
        console.error(e);
      }
    };

    if (store) {
      fetchProducts();
    }
  }, [store]);

  const filterSearch = async (data) => {
    let _nfeListFilter = data.content;

    if (filter.name) {
      _nfeListFilter = _nfeListFilter.filter((item) =>
        item?.nome_fantasia_emitente
          ?.toLowerCase()
          .includes(filter?.name?.toLowerCase())
      );
    }
    if (filter.situation) {
      _nfeListFilter = _nfeListFilter.filter((item) =>
        filter.situation === 1
          ? item?.status?.toLowerCase().includes("autorizado")
          : filter.situation === 2
          ? item?.status?.toLowerCase().includes("cancelado")
          : !(
              item?.status?.toLowerCase().includes("autorizado") ||
              item?.status?.toLowerCase().includes("cancelado")
            )
      );
    }

    if (filter.min_value) {
      _nfeListFilter = _nfeListFilter.filter(
        (item) => +item?.valor_total >= +filter?.min_value
      );
    }
    if (filter.max_value) {
      _nfeListFilter = _nfeListFilter.filter(
        (item) => +item?.valor_total <= +filter?.max_value
      );
    }
    setNfeList(_nfeListFilter);

    setPagination({
      ...pagination,
      totalElements: data.totalElements,
      totalPages: data.totalPages,
    });

    setTimeout(() => {
      setVisibleFilter(false);
    }, 100);
  };

  const changeInputValue = async (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const _value = event?.target?.value;
    setFilter((oldValues) => ({
      ...oldValues,
      [key]: _value,
    }));
  };

  const resetFilter = async () => {
    await formFilter.resetFields();
    setFilter({
      name: "",
      numero: "",
      situation: 0,
      min_value: 0,
      max_value: 0,
    });
    setDate(moment());
    setShouldSearch(true);
    setPagination({
      ...pagination,
      page: 1,
    });
  };

  const renderUseless = async () => {
    await form.validateFields();
    setLoading(true);
    try {
      let payload = form.getFieldsValue();

      payload = { ...payload, cnpj: store?.company?.cnpj };

      await apiMsNf.post(`/nfe/disable-nfe/${store?.key}`, payload);

      setShouldSearch(true);
      setVisibleRenderUseless(false);
      form.resetFields();
    } catch (e) {
      const _message = "Erro ao enviar inutilização";
      //@ts-ignore
      const _description = e?.response?.data?.message || "";
      notification.error({
        message: _message,
        description: _description,
        duration: 5,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer route="Nfe Devolução">
      <>
        {!store ? (
          <StoreSelectionPage
            title="Notas fiscais eletrônicas de saída"
            Img={nfeImage}
            store={store}
            setStoreInfo={setStore}
            setShouldSearch={setShouldSearch}
          ></StoreSelectionPage>
        ) : (
          <Container>
            <TopSide>
              <Row gutter={[4, 2]} className="rowEnd">
                <Col xs={24} sm={9} lg={11} xl={15}>
                  <Row gutter={4} className="rowEnd">
                    <Col xs={20} sm={18} md={16} lg={12} xl={8}>
                      <GetStore
                        defaultValue={store?.key}
                        handleChangeStore={(value) => {
                          setStore(value);
                          setShouldSearch(true);
                        }}
                      />
                    </Col>

                    <Col xs={4} sm={6} md={4} lg={3} xl={2} className="colEnd">
                      <ButtonFilter
                        onClick={() => setVisibleFilter(true)}
                        disabled={shouldSearch}
                      >
                        <IconFilter />
                      </ButtonFilter>
                    </Col>
                  </Row>
                </Col>
                {verifyPermission("nfe.disable_number") && (
                  <Col xs={10} sm={7} lg={5} xl={4} className="colEnd">
                    <Button onClick={() => setVisibleRenderUseless(true)}>
                      Inutilizar numeração <CancelPresentationIcon />
                    </Button>
                  </Col>
                )}
                {verifyPermission("nfe.create") && (
                  <Col xs={10} sm={6} lg={5} xl={4} className="colEnd">
                    <Button
                      onClick={() =>
                        store?.company?.habilita_nfe
                          ? setVisible(true)
                          : notification.warning({
                              message:
                                "Essa loja ainda nao foi habilitada para emitir nota fiscal eletrônica",
                              description:
                                "Por favor entre em contato com o suporte ou habilite a loja através do autenticador",
                              duration: 3,
                            })
                      }
                    >
                      Incluir nota fiscal <IconAdd />
                    </Button>
                  </Col>
                )}
                <Col xs={4} sm={2} xl={1}>
                  <Button onClick={() => setShouldSearch(true)}>
                    <ReloadIcon />
                  </Button>
                </Col>
              </Row>
            </TopSide>
            <Content>
              {loading ? (
                <Centralizer>
                  <Spin />
                </Centralizer>
              ) : (
                <>
                  {nfeList?.length > 0 ? (
                    <>
                      <Row>
                        <span className="span-number">
                          {`Próximo número: ${proxNum}`}
                        </span>
                      </Row>
                      <NfeList
                        setShouldSearch={setShouldSearch}
                        nfeList={nfeList}
                        store={store}
                        pagination={pagination}
                        setPagination={setPagination}
                      />
                    </>
                  ) : (
                    <Centralizer>
                      <Empty description="Nenhuma NFE encontrada no período" />
                    </Centralizer>
                  )}
                </>
              )}
            </Content>
          </Container>
        )}
        <NFEModalDevolution
          visible={visible}
          setVisible={setVisible}
          setShouldSearch={setShouldSearch}
          store={store}
          productsStore={productsStore}
        />

        <Modal
          title="Filtros"
          centered
          onCancel={() => setVisibleFilter(false)}
          visible={visibleFilter}
          width={450}
          footer={
            <>
              <Row style={{ justifyContent: "space-around" }} gutter={[4, 4]}>
                <Col sm={8} xs={24}>
                  <ButtonClear
                    onClick={() => resetFilter()}
                    loading={shouldSearch}
                  >
                    Limpar Filtro
                  </ButtonClear>
                </Col>
                <Col sm={12} xs={24}>
                  <Button
                    onClick={() => {
                      setShouldSearch(true);
                      setPagination({
                        ...pagination,
                        page: 1,
                      });
                    }}
                    loading={shouldSearch}
                  >
                    Buscar itens
                  </Button>
                </Col>
              </Row>
            </>
          }
          destroyOnClose={true}
        >
          <Form layout="vertical" form={formFilter}>
            <Row gutter={[4, 4]}>
              <Col sm={24} xs={24}>
                <Form.Item label="Período">
                  <DatePicker
                    picker="month"
                    value={date}
                    onChange={(value) => {
                      setDate(value || moment());
                    }}
                  />
                </Form.Item>
              </Col>

              <Col sm={24} xs={24}>
                <Form.Item label="Número" name="numero">
                  <Input
                    type="numero"
                    value={filter.numero}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      changeInputValue(event, "numero");
                    }}
                  />
                </Form.Item>
              </Col>

              <Col sm={24} xs={24}>
                <Form.Item label="Nome" name="name">
                  <Input
                    value={filter.name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      changeInputValue(event, "name");
                    }}
                  />
                </Form.Item>
              </Col>

              <Col sm={24} xs={24}>
                <Form.Item label="Situação" name="situation">
                  <Select
                    placeholder="Escolha a opção"
                    value={filter.situation}
                    onChange={(value) => {
                      setFilter((oldValues) => ({
                        ...oldValues,
                        situation: +value,
                      }));
                    }}
                  >
                    <Option value={0} key={0}>
                      Escolha a opção
                    </Option>
                    <Option value={1} key={1}>
                      Autorizada
                    </Option>
                    <Option value={2} key={2}>
                      Canceladas
                    </Option>
                    <Option value={3} key={3}>
                      Denegadas
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={12} xs={24}>
                <Form.Item label="Valor mínimo" name="min_value">
                  <MonetaryInput
                    defaultValue={filter.min_value}
                    getValue={(value) =>
                      setFilter((oldValues) => ({
                        ...oldValues,
                        min_value: +value,
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col sm={12} xs={24}>
                <Form.Item label="Valor máximo" name="max_value">
                  <MonetaryInput
                    defaultValue={filter.max_value}
                    getValue={(value) =>
                      setFilter((oldValues) => ({
                        ...oldValues,
                        max_value: +value,
                      }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
          centered
          closable={true}
          visible={visibleRenderUseless}
          title={"Inutilizar NFE"}
          footer={
            <>
              <ButtonClear
                onClick={() => {
                  setVisibleRenderUseless(false);
                  form.resetFields();
                }}
                loading={loading}
              >
                Cancelar
              </ButtonClear>

              <Button onClick={() => renderUseless()} loading={loading}>
                Enviar
              </Button>
            </>
          }
          width={600}
          onCancel={() => setVisibleRenderUseless(false)}
        >
          <Form layout="vertical" form={form} validateTrigger="onSubmit">
            <Row gutter={[4, 4]}>
              <Col sm={8} xs={8}>
                <Form.Item
                  label="Série"
                  name="serie"
                  rules={[
                    { required: true, message: "Campo obrigatório" },
                    { max: 3, message: "Deve possuir no máximo 3 números" },
                    {
                      pattern: /^\d+$/,
                      message: "Deve ser apenas números",
                    },
                  ]}
                >
                  <Input name="serie" />
                </Form.Item>
              </Col>
              <Col sm={8} xs={8}>
                <Form.Item
                  label="Nº Inicial"
                  name="numero_inicial"
                  rules={[{ required: true, message: "Campo obrigatório" }]}
                >
                  <Input name="numero_inicial" />
                </Form.Item>
              </Col>
              <Col sm={8} xs={8}>
                <Form.Item
                  label="Nº Final"
                  name="numero_final"
                  rules={[{ required: true, message: "Campo obrigatório" }]}
                >
                  <Input name="numero_final" />
                </Form.Item>
              </Col>
              <Col sm={24} xs={24}>
                <Form.Item
                  label="Digite a justificativa"
                  name="justificativa"
                  rules={[
                    { required: true, message: "Campo obrigatório" },
                    {
                      min: 15,
                      message: "Deve possuir no mínimo 15 caracteres",
                    },
                    {
                      max: 255,
                      message: "Deve possuir no máximo 255 caracteres",
                    },
                  ]}
                >
                  <TextArea
                    rows={5}
                    minLength={15}
                    maxLength={255}
                    name="justificativa"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    </PageContainer>
  );
};

export default Nfe;
