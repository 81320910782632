import styled, { css } from "styled-components";
import {
  Col as ColAnt,
  Row as RowAnt,
  Input as InputAnt,
  Form,
  Table as TableAnt,
  DatePicker as DatePickerAnt,
  Button,
} from "antd";
import {
  Barcode,
  CreditCard,
  FilterAlt,
  InfoCircle,
  Minus,
  Money,
  MoreHoriz,
  Transfer,
  Pix,
} from "../../styles/Icons";

const { RangePicker } = DatePickerAnt;

export const Input = styled(InputAnt)`
  @media screen and (max-width: 768px) {
    margin-bottom: 0.5rem;
  }
`;

export const StyledFormItem = styled(Form.Item)`
  display: flex;
  flex-direction: column;
  width: 100%;

  .ant-input,
  .ant-picker {
    width: 100%; 
  }
`;

export const DatePickerElement = styled(DatePickerAnt)`
  width: 100%;
  height: 2.5rem !important;
`;

export const ContentEmptyId = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 8%;
`;

export const MinusIcon = styled(Minus)`
  width: 1rem;
  height: 1rem;
`;

export const InfoCapitalized = styled.span`
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  max-width: 120px;
`;

export const ContentBorderColor = styled.div<{ borderLeft: string }>`
  border-left: 5px solid ${({ borderLeft }) => borderLeft};
  border-radius: 0px 5px 5px 0px;
  padding: 1rem;
  box-sizing: border-box;
`;

export const DatePicker = styled(DatePickerAnt)`
  width: 100%;

  .ant-picker-input > input {
    font-weight: 400 !important;
    font-size: 0.9rem !important;
  }
`;

export const DisabledSpan = styled.span`
  color: var(--gray-25);
  cursor: not-allowed;
`;

export const RangePickerAnt = styled(RangePicker)`
  .ant-picker-input > input {
      font-weight: 400 !important;
      font-size: .9rem !important;
      color: var(--gray-170) !important;
  }
`;

export const Table = styled(TableAnt)`
    .highlight-row {
      background-color: var(--orange-5) !important; 
      transition: background-color 0.5s ease;
    }
  .ant-table table {
    width: 100%;
    text-align: left;
    border-radius: 0;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid var(--gray-25);
    border-radius: 8px;
    margin-top: 1rem;
  }

  .ant-table-container {
    overflow-y: scroll;
    max-height: calc(100vh - 340px);
    border-bottom: 1px solid var(--gray-50);
    border-radius: 10px;

    @media screen and (max-width: 1600px) {
      max-height: calc(100vh - 300px);
    }

    @media screen and (max-width: 768px) {
      max-height: calc(100vh - 500px);
    }
  }

  .ant-table-column-sorters {
    @media screen and (max-width: 1600px) {
      padding: 0;
    }
  }

  .ant-table-thead > tr > th {
    background: var(--white);

    @media screen and (max-width: 1600px) {
      padding: 2px 16px !important;
    }
  }

  .ant-table-thead > tr > th:first-child,
  .ant-table-tbody > tr > td:first-child {
    padding: 0;
  }

  @media screen and (max-width: 1400px) {
    max-height: calc(100vh - 140px);
  }

  @media screen and (max-width: 768px) {
    max-height: 60vh;
    margin-top: 28%;
  }

  @media screen and (max-width: 500px) {
    max-height: 50vh;
    margin-top: 0;
  }
`;

export const ButtonFilter = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 3.3rem;
  border-radius: 5px;
  color: var(--white);
  border: none;
  width: 100%;

  :hover {
    opacity: 80%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: .5rem;
  }
  background: var(--orange-350);

  :hover,
  :focus {
    background: var(--orange-350);
    color: var(--white);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0.8;
  width: 100%;
  height: 100vh;

  padding: 1rem 0;
`;

export const EmptyContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerHeader = styled.div`
  width: 100%;
`;

export const Row = styled(RowAnt)`
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: end;
`;

export const Col = styled(ColAnt)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const iconCSS = css`
  width: 23px;
  height: 23px;

  @media only screen and (max-width: 578px) {
    width: 18px;
    height: 18px;
  }
`;

export const MoreIcon = styled(MoreHoriz)`
  ${iconCSS}
  cursor: pointer;

  :hover {
    background: #8e8b8b6e;
    padding: 2px;
    border-radius: 4px;
    transition: 0.3s background;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  color: var(--orange-350);
  margin-left: 0.5rem;

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: red !important;
  }
`;

export const TableInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0.4rem;
`;

export const HeaderRow = styled.div`
  display: flex;
  width: 100%;
`;

export const Th = styled.div<{ isSelected?: boolean; borderColor?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: bold;
  text-align: center;
  font-size: 12px;
  padding: 8px;
  background-color: var(--white);
  cursor: pointer;
  border: 1px solid var(--gray-25);

  border-top: ${({ isSelected, borderColor }) =>
    isSelected
      ? `4px solid ${borderColor || "var(--gray-25)"}`
      : "2px solid var(--gray-25)"};

  flex: 1;

  .ant-radio-inner {
    border-color: ${({ borderColor }) => borderColor || "var(--gray-25)"};
    display: flex;
    align-items: center;
  }

  .ant-radio-checked .ant-radio-inner {
    display: flex;
    align-items: center;
  }

  .ant-radio-inner::after {
    background-color: ${({ borderColor }) => borderColor || "var(--gray-25)"};
  }
`;

export const BodyRow = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledTd = styled.div<{ color?: string }>`
  color: ${(props) => props.color || "inherit"};
  font-weight: bold;
  font-size: 24px;
  padding: 8px;
  border: 1px solid var(--gray-25);
  text-align: center;
  flex: 1;

  @media screen and (max-width: 1600px) {
    font-size: 19px;
  }
`;

export const FilterIcon = styled(FilterAlt)`
  width: 1.3rem;
  height: 1.3rem;
`;

export const ContentTile = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentTileID = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InfoCircleIcon = styled(InfoCircle)`
  width: 15px;
  height: 15px;
  cursor: pointer;
  color: var(--c64-blue);
  margin-left: 10px;
`;

const iconTableCSS = css`
  width: 20px;
  height: 20px;

  margin-left: 10px;
`;

export const MoneyIcon = styled(Money)`
  ${iconTableCSS}
  color: var(--midori-green);
`;

export const CreditCardIcon = styled(CreditCard)`
  ${iconTableCSS}
  color: var(--c64-blue);
`;

export const DebitCardIcon = styled(CreditCard)`
  ${iconTableCSS}
  color: var(--c80-blue);
`;

export const TransferIcon = styled(Transfer)`
  ${iconTableCSS}
  color: var(--roof-terracotta);
`;

export const PixIcon = styled(Pix)`
  ${iconTableCSS}
  color: var(--pix);
`;

export const BarcodeIcon = styled(Barcode)`
  ${iconTableCSS}
  color: var(--black);
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonCSS = css`
  font-weight: 500;
  font-size: 1rem;
  width: 7rem;
  height: 3rem;
  border-radius: 5px;
`;

export const ButtonCancel = styled.button`
  ${ButtonCSS}
  color: var(--gray-650);

  :hover {
    background-color: var(--white-100);
  }
`;

export const ButtonSave = styled(Button)`
  ${ButtonCSS}
  color: white;
  background-color: var(--orange-350);
  transition: 0.2s;

  :hover,
  :active,
  :focus {
    background-color: var(--orange-600);
    border: 1px solid var(--orange-600);
    color: white;
  }
`;
