import styled, { css } from 'styled-components';

import {
  Table as TableAnt,
  Row as RowAnt,
  Button as ButtonAnt,
  Col as ColAnt,
  Select as SelectAnt,
  DatePicker as DatePickerAnt
} from 'antd';
import { Add, Download, InfoCircle, MoreHoriz, SelectMultiple } from '../../styles/Icons';
import { Link } from 'react-router-dom';

export const DatePicker = styled(DatePickerAnt)`
  .ant-picker-input input {
    font-weight: 400 !important;
  }

  @media only screen and (max-width: 768px) {
    margin: .4rem 0 !important;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0.8;
  width: 100%;
  height: 100vh;

  overflow: hidden;
  overflow-x: hidden;
  
  padding: 1rem 0;

  @media only screen and (max-width: 768px) {
   height: 100%;
  }
`;

export const TopRow = styled(RowAnt)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end; 
    }

      .first-col {
        @media screen and (min-width: 1600px) {
          justify-content: flex-end;
        }
    }

    .ant-col {
      display: flex;
      justify-content: flex-start; 
    }
`;

export const Col = styled(ColAnt)`
    width: 100%;
`;

export const EmptyContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Table = styled(TableAnt)`  
    .ant-table-container {
      overflow-y: scroll;
      max-height: calc(100vh - 240px);
      border-bottom: 1px solid var(--gray-50);
      border-radius: 10px;

      @media screen and (max-width: 1600px) {
        max-height: calc(100vh - 200px);
      }

      @media screen and (max-width: 768px) {
        max-height: calc(100vh - 400px);
      }
    }

 .ant-table table {
        width: 100%;
        height: 100%; 
        text-align: left;
        border-radius: 0;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid var(--gray-25);
        border-radius: 8px;
        margin-top: 1rem;
    }

    .ant-table-thead > tr > th {
        background: var(--white);
    }

    .ant-table-body {
        overflow-y: auto; 
    }
`;

export const TopScreenMenu = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: start;
    }
`;

export const ContentTile = styled.div`
  display: flex;
  align-items: center;
`;

const iconCSS = css`
  width: 23px;
  height: 23px;

  @media only screen and (max-width: 578px) {
    width: 18px;
    height: 18px;
  }
`;

export const MoreIcon = styled(MoreHoriz)`
  ${iconCSS}
  cursor: pointer;

  :hover {
    background: #8e8b8b6e;
    padding: 2px;
    border-radius: 4px;
    transition: 0.3s background;
  }
`;

export const InfoCircleIcon = styled(InfoCircle)`
  width: 15px;
  height: 15px;
  cursor: pointer;
  color: var(--c64-blue);
  margin-left: 10px;
`;

export const InfoCapitalized = styled.span`
  text-transform: capitalize;
`;


export const Actions = styled.div`
  display: flex;
  align-items: center;
  color: var(--orange-350);
  margin-left: 0.5rem;

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: red !important;
  }
`;

export const TotalText = styled.div`
    display: flex;
    font-size: 1.2rem;
    align-items: baseline;

    span {
        font-weight: bold;
        margin-right: 5px;
    }

    .total {
        font-weight: normal;
        font-size: 1rem;
    }
`;

export const SelectMultipleIcon = styled(SelectMultiple)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: var(--black);
  margin-left: 10px;
`;

export const ButtonCommon = styled(ButtonAnt)`
  padding: 0;
  border-radius: 5px;
  width: 100%;
  height: 3.3rem;
  background: var(--orange-gradient);
  box-shadow: 0px 1px 10px 0px rgba(253, 103, 174, 0.25);

  :hover {
    background: var(--orange-gradient);
    box-shadow: 0px 1px 10px 0px rgba(253, 103, 174, 0.25);
    opacity: 70%;
  }
`;

export const Select = styled(SelectAnt)`
  width: 100%;
  text-transform: uppercase;
  text-align: initial;
    @media only screen and (max-width: 768px) {
      margin-bottom: .4rem !important;
    }

  .ant-select-selection-search-input {
    height: 100% !important;
    color: var(--gray-45) !important;
    font-weight: 500 !important;
  }

  .ant-select-single:not(.ant-select-customize-input),
  .ant-select-selector {
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      color: var(--gray-45) !important;
      justify-content: flex-start !important;
      font-weight: 400 !important;
      text-transform: capitalize !important;

      :last-child{
        margin-top: 20px;
      }
    }

    height: 3.25rem !important;
    border-radius: 5px !important;
    outline: none !important;
    border: 1px solid var(--gray-50) !important;
    box-shadow: 0 0 0 0 !important;
    background: transparent !important;
    color: var(--gray-45) !important;
    font-weight: 500 !important;

    :focus,
    :active,
    :hover {
      outline: none;
      box-shadow: 0 0 0 0;
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      padding: 10px; 
    }
  }

  .ant-select-arrow {
    color: var(--orange-350) !important;
  }

  @media only screen and (max-width: 578px) {
    width: 100%;

    .ant-select-selector {
      height: 3.25rem !important;
    }
  }
`;

const iconsButton = css`
    color: var(--white);
    width: 1.5rem;
    height: 1.5rem;
`;

export const DownloadIcon = styled(Download)`
 ${iconsButton}  
`;



export const AddIcon = styled(Add)`
 ${iconsButton}  
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--orange-gradient);
  color: var(--white);
  height: 3.3rem;
  width: 100%;
  border-radius: 5px;
  text-decoration: none; 
  padding: 0;

  :hover {
    color: var(--white);
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;