import styled, { css } from "styled-components";

import {
  Filter,
  PlusCircle,
  SearchAlt2,
  Download,
  Block,
  Unlock,
  Whatsapp,
  Status,
  Payments,
  PersonCheckFill,
} from "../../../styles/Icons";

import {
  Dropdown as DropdownAnt,
  Menu as MenuAnt,
  Col as ColAnt,
  Row as RowAnt,
  Checkbox as CheckboxAnt,
  Input as InputAnt,
  Button as ButtonAnt,
  Modal as ModalAnt,
} from "antd";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  .ant-pagination {
    margin-top: 1rem;
  }
`;

export const Header = styled(RowAnt)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 6%;
  min-height: 3rem;

  h2 {
    font-size: 1.5rem;
    color: var(--gray-45);
    font-weight: 500;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 800px) {
    min-height: 6rem;
    h2 {
      display: none;
    }
  }
  @media only screen and (max-width: 575px) {
    min-height: 12rem;
  }
`;

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ActionFilterContainer = styled(RowAnt)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80%;

  .store-select-content {
    width: 50%;
  }
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.25rem;
  width: 3.25rem;
  margin: 0 0.1rem;
  border-radius: 5px;
  background: var(--orange-gradient);

  svg {
    color: var(--white-50);
    width: 1.3rem;
    height: 1.3rem;
  }

  :hover {
    filter: contrast(0.8);
  }
  @media only screen and (max-width: 500px) {
    margin-top: 0.2rem;
  }
`;

export const Row = styled(RowAnt)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem 0 0;
  min-height: 3.5rem;
`;

export const Col = styled(ColAnt)``;

export const List = styled.div`
  width: 100%;
  height: 94%;
  margin-top: 1rem;
`;

export const AddIcon = styled(PlusCircle)``;

export const BlockIcon = styled(Block)``;

export const UnlockIcon = styled(Unlock)``;

export const SearchIcon = styled(SearchAlt2)``;

export const FilterIcon = styled(Filter)``;

export const WhatsappIcon = styled(Whatsapp)`
  margin-right: 0.5rem;
  width: 1.2rem;
  height: 1.2rem;
  color: var(--green-800);
`;

export const HomologProviderIcon = styled(PersonCheckFill)``;

export const DownloadIcon = styled(Download)``;

export const StatusIcon = styled(Status)``;

export const PaymentIcon = styled(Payments)``;

export const Dropdown = styled(DropdownAnt)`
  li {
    padding: 8px;
  }
`;

export const Menu = styled(MenuAnt)`
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--gray-25);
  }
  .statusCheck {
    display: flex;
    flex-direction: row-reverse;
    margin-left: auto;
  }
  user-select: none;
`;

export const Checkbox = styled(CheckboxAnt)`
  font-size: 0.6rem;
  zoom: 1;
  font-weight: 600;
`;

export const InputOrders = styled(InputAnt)``;

export const FilterText = styled.span`
  margin-right: 0.5rem;
`;

export const ContentModal = styled.div`
  display: flex;
  align-items: center;
`;

export const WhatsappText = styled.a`
  font-size: 1.1rem;
  color: var(--gray-45);
`;

const ImgCSS = css`
  width: 2rem;
  height: 2rem;
`;

export const Icons = styled.img`
  ${ImgCSS}
`;

export const ModalTypeLoad = styled(ModalAnt)`
  .ant-modal-header {
    padding: 1rem;
  }
  .ant-modal-title {
    color: var(--blue-900);
    font-weight: 500;
    font-size: 1.8rem;
    padding: 0rem;
  }
  .ant-modal-body {
    color: var(--gray-45);
    border-top: 1px solid var(--gray-50);
    padding: 1rem;
    height: 25rem;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
`;

export const ModalDivButtons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 20rem;

  @media only screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: scroll;
  }
`;

interface ITypeButton {
  typeStyle: number;
}

export const ButtonCard = styled(ButtonAnt)<ITypeButton>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 33%;
  height: 100%;
  background: white;
  border: 1px solid var(--gray-45);
  border-radius: 0.31rem;
  color: var(--gray-45);
  padding: 1rem 2rem;

  .icons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3rem;
  }

  .title {
    width: 100%;
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--gray-45);
  }

  .subTitle {
    width: 100%;
    font-size: 0.9rem;
    color: var(--gray-45);
  }

  .divText {
    padding: 0 1rem 1rem;
    width: 100%;
    font-size: 0.9rem;
    color: var(--gray-45);
    fill: var(--gray-45);
    text-align: start;
    hyphens: auto;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }

  h3 {
    color: var(--blue-900);
    font-weight: 500;
    font-size: 1rem;
  }

  ${({ typeStyle }) => {
    if (typeStyle === 1) {
      return css`
        margin-right: 0.5rem;
        :hover,
        :focus {
          border: 1px solid var(--clear-chill);
          color: var(--clear-chill);
          opacity: 80%;
          background: var(--white-50);

          .title,
          .subTitle,
          .divText {
            color: var(--clear-chill);
            fill: var(--clear-chill);
          }

          img {
            filter: brightness(0) saturate(100%) invert(43%) sepia(58%)
              saturate(2327%) hue-rotate(191deg) brightness(101%) contrast(101%);
          }
        }
      `;
    } else if (typeStyle === 2) {
      return css`
        margin: 0 0.5rem;
        :hover,
        :focus {
          border: 1px solid var(--coffe);
          color: var(--coffe);
          opacity: 80%;
          background: var(--white-50);

          .title,
          .subTitle,
          .divText {
            color: var(--coffe);
            fill: var(--coffe);
          }

          img {
            filter: brightness(0) saturate(100%) invert(51%) sepia(21%)
              saturate(1468%) hue-rotate(327deg) brightness(83%) contrast(85%);
          }
        }
      `;
    } else if (typeStyle === 3) {
      return css`
        margin-left: 0.5rem;
        :hover,
        :focus {
          border: 1px solid var(--billiard-ball);
          color: var(--billiard-ball);
          opacity: 80%;
          background: var(--white-50);

          .title,
          .subTitle,
          .divText {
            color: var(--billiard-ball);
            fill: var(--billiard-ball);
          }

          img {
            filter: brightness(0) saturate(100%) invert(35%) sepia(11%)
              saturate(2475%) hue-rotate(86deg) brightness(97%) contrast(86%);
          }
        }
      `;
    }
  }}

  @media only screen and (max-width: 800px) {
    align-items: center;
    width: 100%;
    height: 30%;
    padding: 1rem;
    text-align: center;
    hyphens: auto;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    overflow-y: auto;

    .icons {
      width: 50%;
      height: 1.5rem;
    }

    .title {
      justify-content: center;
      font-size: 1rem;
    }

    .subTitle {
      font-size: 0.7rem;
    }

    .divText {
      font-size: 0.7rem;
    }

    h3 {
      font-size: 0.8rem;
    }

    ${({ typeStyle }) => {
      if (typeStyle === 1) {
        return css`
          margin: 0.25rem 0rem;
        `;
      } else if (typeStyle === 2) {
        return css`
          margin: 0.25rem 0rem;
        `;
      } else if (typeStyle === 3) {
        return css`
          margin: 0.25rem 0rem;
        `;
      }
    }}
  }
`;

export const ButtonCancel = styled(ButtonAnt)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.37rem;
  height: 3.25rem;
  background: white;
  color: var(--gray-45);
  border: 1px solid var(--gray-45);
  border-radius: 0.31rem;

  :hover,
  :focus {
    background: white;
    color: var(--gray-45);
    border: 1px solid var(--gray-45);
    opacity: 80%;
  }

  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const Button = styled(ButtonAnt)`
  border-radius: 0.5rem;
`;
