import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import api from "../../../services/api";
import apiAuth from "../../../services/apiAuth";
import { currencyFormater } from "../../../services/currencyFormater";

import { CategoryModel } from "../../../models/CategoryModel";

import Centralizer from "../../../containers/Centralizer";

import MonetaryInput from "../../../components/MonetaryInput2";

import { Form, notification, Spin, Tooltip } from "antd";

import {
  Container,
  ButtonCancel,
  ButtonSave,
  Header,
  Body,
  Select,
  Col,
  Modal,
  Row,
  Footer,
  Input,
  ItemList,
  Table,
  Center,
} from "./styles";

const { TextArea } = Input;

interface IItem {
  id?: number;
  category_id: number;
  category_name: string;
  product_id: number;
  price_sell: number;
  name: string;
  upload_url: string;
  quantity: number;
  weight: number;
  unity: string;
}

interface ITotals {
  total: number;
  totalNF: number;
  productTotal: number;
  serviceTotal: number;
  totalQuantity: number;
}

interface IPresetValues {
  typeOfLoad: number;
  discount: number;
  discount_product: number;
  value_icms_st: number;
  value_ipi: number;
  value_interest: number;
  value_freight: number;
  value_additional_taxes: number;
  additional_value: number;
  comment: string;
}

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  categories: CategoryModel[];
  store: number | null | undefined;
  user: any;
  servicePercentage: number;
  productPercentage: number | null;
}

const ModalOrderPresets: React.FC<IProps> = ({
  setVisible,
  visible,
  setShouldSearch,
  categories,
  store,
  user,
  servicePercentage,
  productPercentage,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPresets, setLoadingPresets] = useState<boolean>(false);
  const [loadingFinish, setLoadingFinish] = useState<boolean>(false);

  const [presetsList, setPresetsList] = useState<
    { id: number; name: string; url: string }[]
  >([]);

  const [storeFreight, setStoreFreight] = useState<number>(0);
  const [presetSelect, setPresetSelect] = useState<number | undefined>();

  const [totals, setTotals] = useState<ITotals>({
    total: 0,
    totalNF: 0,
    productTotal: 0,
    serviceTotal: 0,
    totalQuantity: 0,
  });

  const [presetValues, setPresetValues] = useState<IPresetValues>({
    typeOfLoad: 0,
    discount: 0,
    discount_product: 0,
    value_icms_st: 0,
    value_ipi: 0,
    value_interest: 0,
    value_freight: 0,
    value_additional_taxes: 0,
    additional_value: 0,
    comment: "",
  });

  const [itemList, setItemList] = useState<IItem[]>([]);

  useEffect(() => {
    const fetchPresets = async () => {
      const {
        data: { content },
      } = await apiAuth.get(`/files-management/owner/orders`);

      let presets = content
        ?.filter((_file) => _file.folder === "padroes")
        ?.map((_file) => ({
          id: _file?.id,
          name: _file?.file_name,
          url: _file?.file_url,
        }));
      presets = [...new Set(presets)];

      setPresetsList(presets);
    };

    const resetFields = () => {
      setItemList([]);
      setPresetValues({
        typeOfLoad: 0,
        discount: 0,
        discount_product: 0,
        value_icms_st: 0,
        value_ipi: 0,
        value_interest: 0,
        value_freight: 0,
        value_additional_taxes: 0,
        additional_value: 0,
        comment: "",
      });
      setTotals({
        total: 0,
        totalNF: 0,
        productTotal: 0,
        serviceTotal: 0,
        totalQuantity: 0,
      });
      setPresetSelect(undefined);
    };
    if (visible) {
      fetchPresets();
    } else {
      resetFields();
    }
  }, [visible]);

  const createListItem = (content: any): IItem[] => {
    let listItems: IItem[] = [];

    const productList = categories
      ?.filter((category) =>
        content?.some((_item) => _item.category_id === category.id)
      )
      ?.flatMap((productCategory) => productCategory.products);

    listItems = content
      .map((item) => {
        const produto = productList.find(
          (_product) => _product?.id === item.id
        );
        if (produto) {
          return {
            id: item.id,
            category_id: produto?.category_id,
            category_name: categories.find(
              (_category) => _category.id === produto.category_id
            )?.name,
            product_id: produto?.id,
            price_sell: +(produto?.price_sell || 0),
            name: produto?.name,
            upload_url: produto?.upload_url,
            quantity: +(item?.quantity || 0),
            weight: produto?.weight,
            unity: produto?.unity || "",
            recommended_product: produto?.recommended_product || "",
          };
        }
        return null;
      })
      .filter(Boolean);

    return listItems;
  };

  useEffect(() => {
    const presetValues = async () => {
      setLoadingPresets(true);
      try {
        const file_name = presetsList.find(
          (_preset) => _preset.id === presetSelect
        )?.name;
        let itemList: IItem[] = [];

        if (file_name) {
          const { data } = await apiAuth.get(
            `/files-management/orders/padroes/${file_name}/beautify`
          );
          itemList = await createListItem(data);
        }

        setItemList(itemList);

        let _totalOrderValue = itemList.reduce(
          (totalPrice, reducePrice) =>
            totalPrice + +(reducePrice?.price_sell * reducePrice?.quantity),
          0
        );

        setPresetValues((oldValues) => ({
          ...oldValues,
          value_freight:
            (_totalOrderValue * (productPercentage || 0) * storeFreight) / 100,
        }));
      } catch (e) {
        notification.error({
          message: "Erro ao obter padrão",
          duration: 5,
        });
      } finally {
        setLoadingPresets(false);
      }
    };

    if (presetSelect) {
      setTotals({
        total: 0,
        totalNF: 0,
        productTotal: 0,
        serviceTotal: 0,
        totalQuantity: 0,
      });
      presetValues();
    }
  }, [presetSelect]);

  useEffect(() => {
    const fetchFreight = async () => {
      setLoading(true);
      try {
        const {
          data: {
            result: { freight },
          },
        } = await api.get(`/value_freight/${store}`);

        setStoreFreight(freight);
      } catch (error) {
        //@ts-ignore
        const _description = error.message;
        notification.error({
          message: "Oops! Erro ao listar o frete.",
          description: error
            ? _description
            : "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde!",
          duration: 5,
        });
      } finally {
        setLoading(false);
      }
    };

    if (visible && store) {
      fetchFreight();
    }
  }, [visible, store]);

  useEffect(() => {
    const calcTotals = () => {
      let _totalOrderValue = itemList.reduce(
        (totalPrice, reducePrice) =>
          totalPrice + +(reducePrice?.price_sell * reducePrice?.quantity),
        0
      );

      let _totalProducts = _totalOrderValue * (productPercentage || 0);

      let _totalService = _totalOrderValue * servicePercentage;

      let _total =
        _totalOrderValue +
        (+presetValues.value_freight || 0) +
        (+presetValues.value_icms_st || 0) +
        (+presetValues.value_ipi || 0) +
        (+presetValues.value_additional_taxes || 0) +
        (+presetValues.value_interest || 0) +
        (+presetValues.additional_value || 0) -
        +(+presetValues.discount || 0);

      let _totalNF =
        _totalOrderValue * (productPercentage || 0) +
        (+presetValues.value_freight || 0) +
        (+presetValues.value_icms_st || 0) +
        (+presetValues.value_ipi || 0) +
        (+presetValues.value_additional_taxes || 0) +
        (+presetValues.value_interest || 0) +
        (+presetValues.additional_value || 0) -
        +(+presetValues.discount || 0);

      let _totalQuantity = itemList.reduce(
        (totalQuantity, reduceQuantity) =>
          totalQuantity + reduceQuantity.quantity,
        0
      );

      setTotals(() => ({
        total: _total,
        totalNF: _totalNF,
        productTotal: _totalProducts,
        serviceTotal: _totalService,
        totalQuantity: _totalQuantity,
      }));
    };

    if (visible) {
      calcTotals();
    }
  }, [presetValues, itemList]);

  useEffect(() => {
    const calcFreight = () => {
      let _totalOrderValue = itemList.reduce(
        (totalPrice, reducePrice) =>
          totalPrice + reducePrice.price_sell * reducePrice.quantity,
        0
      );
      let freightOrderValue =
        _totalOrderValue * (productPercentage || 0) * (+storeFreight / 100);
      setPresetValues((oldValues) => ({
        ...oldValues,
        value_freight: freightOrderValue,
      }));
    };

    if (storeFreight && itemList) {
      calcFreight();
    }
  }, [itemList]);

  const changePresetFields = (field: string, value: number | string) => {
    setPresetValues((oldValues) => ({ ...oldValues, [field]: value }));
  };

  const changeQuantity = async (productName: string, value: number) => {
    const _listItem = [...itemList];

    const index = _listItem.findIndex((_item) => _item.name === productName);
    _listItem[index] = { ..._listItem[index], quantity: value };

    await setItemList(() => [..._listItem]);

    let _totalOrderValue = _listItem.reduce(
      (totalPrice, reducePrice) =>
        totalPrice + +(reducePrice?.price_sell * reducePrice?.quantity),
      0
    );

    setPresetValues((oldValues) => ({
      ...oldValues,
      value_freight:
        (_totalOrderValue * (productPercentage || 0) * storeFreight) / 100,
    }));
  };

  const payloadContruct = async () => {
    let orderItems: {
      product_id: number;
      quantity: number;
      products?: {
        name?: string;
      };
      price_unit?: string;
      id?: number;
    }[] = [];

    itemList.forEach((product, index) => {
      orderItems[index] = {
        product_id: product.product_id,
        quantity: product.quantity,
        price_unit: product.price_sell.toString(),
      };
    });

    orderItems = orderItems.filter((_item) => _item && _item.quantity > 0);

    return {
      store_id: store,
      user_id: user?.id,
      comment: presetValues.comment,
      to_delivery: false,
      time_to_get: "",
      total: +totals.total.toFixed(2),
      total_nf: +(totals.totalNF || 0).toFixed(2),
      additional_value: presetValues.additional_value,
      value_icms_st: presetValues.value_icms_st,
      value_ipi: presetValues.value_ipi,
      value_additional_taxes: presetValues.value_additional_taxes,
      value_interest: presetValues.value_interest,
      discount: +presetValues.discount || 0,
      discount_product: +presetValues.discount_product || 0,
      franchise_tax: +totals.serviceTotal.toFixed(2),
      type_of_load: presetValues.typeOfLoad,
      value_freight: presetValues.value_freight,
      orderItems,
    };
  };

  const onFinish = async () => {
    const verifyList = itemList.every((item) => item.quantity >= 0);
    const payload = await payloadContruct();

    if (!verifyList) {
      return notification.warning({
        message: "Existem items com quantidades inválidas",
        duration: 5,
      });
    }

    if (payload?.orderItems?.length === 0) {
      return notification.warning({
        message: "O pedido está vazio",
        duration: 5,
      });
    }

    if (+payload.total_nf + +payload.discount < +payload.discount) {
      return notification.warning({
        message: `Ops! Não é possível adicionar o desconto neste pedido.`,
        description: `O valor do desconto não deve ser maior que o valor total de NF`,
        duration: 5,
      });
    }

    setLoadingFinish(true);
    try {
      await api.post("/orders", {
        ...payload,
        status: 0,
      });

      notification.success({
        message: " Pedido criado com sucesso",
        duration: 5,
      });
      setShouldSearch(true);
    } catch (e) {
      //@ts-ignore
      const _description = e.message;

      notification.error({
        message: `Erro ao registrar pedido`,
        description: _description,
        duration: 5,
      });
    } finally {
      setLoadingFinish(false);
      setVisible(false);
    }
  };

  const columns = [
    {
      title: "Produto",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: <Center>Preço Produto</Center>,
      dataIndex: "price_sell",
      key: "price_sell",
      render: (text) => (
        <Tooltip
          title={`R$ ${+(+(+text || 0) * (productPercentage || 0)).toFixed(2)}`}
        >
          <Center>
            <span>
              R$ {+(+(+text || 0) * (productPercentage || 0)).toFixed(2)}
            </span>
          </Center>
        </Tooltip>
      ),
    },
    {
      title: <Center>Preço Serviço</Center>,
      dataIndex: "price_sell",
      key: "price_sell",
      render: (text) => (
        <Tooltip
          title={`R$ ${+(+(+text || 0) * servicePercentage).toFixed(2)}`}
        >
          <Center>
            <span>R$ {+(+(+text || 0) * servicePercentage).toFixed(2)}</span>
          </Center>
        </Tooltip>
      ),
    },
    {
      title: <Center>Peso</Center>,
      dataIndex: "weight",
      key: "weight",
      render: (text) => (
        <Tooltip title={text}>
          <Center>
            <span>{text}</span>
          </Center>
        </Tooltip>
      ),
    },
    {
      title: <Center>Unidade</Center>,
      dataIndex: "unity",
      key: "unity",
      render: (text) => (
        <Tooltip title={text}>
          <Center>
            <span>{text}</span>
          </Center>
        </Tooltip>
      ),
    },
    {
      title: <Center>Qtd Recomendada</Center>,
      dataIndex: "recommended_product",
      key: "recommended_product",
      render: (text) => (
        <Tooltip title={text}>
          <Center>
            <span>{text}</span>
          </Center>
        </Tooltip>
      ),
    },
    {
      title: <Center>Qtd</Center>,
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <Form.Item
          name={`qtd${record.name}`}
          rules={[
            {
              pattern: /^\d+$/,
              message: "Esse campo só aceita números inteiros positivos",
            },
          ]}
        >
          <Center>
            <input
              name={`qtd${record.name}`}
              type="number"
              step={1}
              defaultValue={text}
              onChange={(e) => changeQuantity(record?.name, +e.target.value)}
            />
          </Center>
        </Form.Item>
      ),
    },
  ];

  return (
    <Modal
      title={`Padrões de pedidos`}
      visible={visible}
      centered
      destroyOnClose={true}
      closable={false}
      width={1000}
      footer={[
        <ButtonCancel onClick={() => setVisible(false)}>Cancelar</ButtonCancel>,
        <ButtonSave
          loading={loading || loadingFinish || loadingPresets}
          onClick={() => onFinish()}
        >
          Criar Pedido
        </ButtonSave>,
      ]}
    >
      {loading ? (
        <Centralizer>
          <Spin />
        </Centralizer>
      ) : (
        <Container>
          <Header>
            <span className="span-title">Selecione o padrão</span>
            <Select
              placeholder="Selecione o padrão"
              defaultValue={""}
              value={presetSelect}
              onChange={(id) => {
                setPresetSelect(+id);
              }}
            >
              {presetsList?.map((preset) => (
                <Select.Option
                  key={preset?.id}
                  value={preset?.id}
                  style={{ textTransform: "uppercase" }}
                >
                  {preset?.name}
                </Select.Option>
              ))}
            </Select>
          </Header>
          <Body>
            {loadingPresets ? (
              <Centralizer>
                <Spin />
              </Centralizer>
            ) : (
              <>
                {presetSelect && (
                  <Form layout="vertical" initialValues={{ remember: false }}>
                    <Row gutter={[4, 4]}>
                      <Col sm={4}>
                        <Form.Item label="Desconto:" name="discount">
                          <MonetaryInput
                            getValue={(value) => {
                              changePresetFields("discount", value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={4}>
                        <Form.Item label="Valor ICMS ST:" name="value_icms_st">
                          <MonetaryInput
                            getValue={(value) => {
                              changePresetFields("value_icms_st", value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={4}>
                        <Form.Item label="Valor IPI:" name="value_ipi">
                          <MonetaryInput
                            getValue={(value) => {
                              changePresetFields("value_ipi", value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={4}>
                        <Form.Item label="Valor Juros:" name="value_interest">
                          <MonetaryInput
                            getValue={(value) => {
                              changePresetFields("value_interest", value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={4}>
                        <Form.Item
                          label="Valor Demais Impostos:"
                          name="value_additional_taxes"
                        >
                          <MonetaryInput
                            getValue={(value) => {
                              changePresetFields(
                                "value_additional_taxes",
                                value
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={4}>
                        <Form.Item
                          label="Valor adicional:"
                          name="additional_value"
                        >
                          <MonetaryInput
                            getValue={(value) => {
                              changePresetFields("additional_value", value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[4, 4]}>
                      <Col sm={4}>
                        <Form.Item
                          label={`Valor de Frete: ${storeFreight}%`}
                          name="value_freight"
                        >
                          <MonetaryInput
                            defaultValue={presetValues?.value_freight}
                            getValue={(value) => {
                              changePresetFields("value_freight", value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={12}>
                        <Form.Item label="Comentário:" name="comment">
                          <TextArea
                            style={{ height: "3.25rem" }}
                            placeholder="Deixe algum comentário relacionado ao seu pedido"
                            rows={5}
                            onChange={({ target: { value } }) => {
                              changePresetFields("comment", value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <ItemList>
                      <Table
                        loading={loadingPresets}
                        columns={columns}
                        pagination={false}
                        dataSource={itemList}
                        rowKey={(entity: any) => entity.id}
                        scroll={{ y: 120 }}
                      />
                    </ItemList>
                  </Form>
                )}
              </>
            )}
          </Body>
          {presetSelect && (
            <Footer gutter={[4, 4]}>
              <Col sm={5} className="col-total">
                <span className="span-total">Total</span>
                <span className="span-total-result">
                  R$ {currencyFormater(totals?.total || 0)}
                </span>
              </Col>
              <Col sm={5} className="col-total">
                <span className="span-total">Total NF</span>{" "}
                <span className="span-total-result">
                  R$ {currencyFormater(totals?.totalNF || 0)}
                </span>
              </Col>
              <Col sm={5} className="col-total">
                <span className="span-total">Valor Produtos</span>{" "}
                <span className="span-total-result">
                  R$ {currencyFormater(totals?.productTotal || 0)}
                </span>
              </Col>
              <Col sm={5} className="col-total">
                <span className="span-total">Valor Serviço</span>{" "}
                <span className="span-total-result">
                  R$ {currencyFormater(totals?.serviceTotal || 0)}
                </span>
              </Col>
              <Col sm={4} className="col-total">
                <span className="span-total">Quantidade</span>{" "}
                <span className="span-total-result">
                  {totals?.totalQuantity}
                </span>
              </Col>
            </Footer>
          )}
        </Container>
      )}
    </Modal>
  );
};

export default ModalOrderPresets;
