import styled, { css } from "styled-components";

import {
  Button as ButtonAnt,
  Empty as EmptyAnt,
  Modal as ModalAnt,
  Input as InputAnt,
} from "antd";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  margin-top: 1rem;
  gap: 1rem;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 5%;
  padding-top: 10px;
  padding-left: 15px;
  font-size: 2rem;
  font-weight: 400;
`;

export const ContainerMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const ActionsContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
`;

export const StoreSelectionContainer = styled.div`
  width: 20%;
  @media only screen and (max-width: 768px) {
    width: 60%;
  }
`;

export const OpenCloshStoreCashContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    width: 45%;
  }
  .ant-btn {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 3.25rem;

    color: white;

    border-radius: 5px;
    justify-content: center;
    cursor: pointer;

    background: var(--orange-gradient);
  }
`;

export const Button = styled(ButtonAnt)``;

export const ContainerSearch = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ButtonCancel = styled(ButtonAnt)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.37rem;
  height: 3.25rem;
  background: white;
  color: var(--gray-45);
  border: 1px solid var(--gray-45);
  border-radius: 0.31rem;

  :hover,
  :focus {
    background: white;
    color: var(--gray-45);
    border: 1px solid var(--gray-45);
    opacity: 80%;
  }
`;

export const ButtonSave = styled(ButtonAnt)`
  color: white;

  background: var(--orange-gradient);
  :hover,
  :focus {
    opacity: 80%;
    background: var(--orange-gradient);
    border-color: transparent;
    color: white;
  }
`;

export const AlignCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Empty = styled(EmptyAnt)``;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Spin = styled.img``;

export const ChangeStoreCashStatusTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1rem;
  margin: 10px 0;
`;

export const Modal = styled(ModalAnt)``;

export const CupomProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ProgressContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 8rem;
  width: 100%;
  padding: 1rem 2rem;
  border: 1px solid var(--gray-25);
  border-radius: 9px;
  box-shadow: 3px 3px 4px 1px rgb(0 0 0 / 9%);

  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 10px;
    background: var(--orange-350);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    z-index: 1;
  }

  @media only screen and (max-width: 768px) {
    padding: 0.5rem 2rem;
  }
`;

interface WidthProgress {
  widthProgress: string;
  bgColor?: string;
  reverse?: boolean;
}

export const LabelWithCount = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProgressLabelsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

interface Count {
  color: string;
}

export const Count = styled.span<Count>`
  font-weight: 500;

  font-size: 1.2rem;
  ${({ color }) => css`
    color: ${color};
  `}
`;

export const CompanyVoucherContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RedeemVoucherContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60%;
  gap: 1rem;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    height: 100%;
  }
`;

export const VoucherContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 50%;
  border: 1px solid #e0e1e32e;
  border-radius: 9px;
  box-shadow: 3px 3px 4px 1px rgb(0 0 0 / 9%);
  padding: 1rem;
  gap: 1rem;

  @media only screen and (max-width: 700px) {
    width: 100%;
  }

  .voucher-title,
  .voucher-content,
  .voucher-action {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
  }

  .voucher-title {
    font-size: 1.5rem;
    height: 15%;
    font-weight: 500;

    @media only screen and (max-width: 700px) {
      font-size: 1rem;
    }
  }
  .voucher-content {
    height: 65%;
  }
  .voucher-action {
    height: 25%;

    .ant-btn {
      display: flex;
      align-items: center;
      text-align: center;
      width: 30%;
      height: 2.25rem;

      border-radius: 5px;
      justify-content: center;
      cursor: pointer;

      @media only screen and (max-width: 575px) {
        width: 30%;
        margin-top: 0.5rem;
      }
    }
  }
`;

export const VoucherHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 50%;
  overflow: auto;
  border: 1px solid #e0e1e32e;
  border-radius: 9px;
  box-shadow: 3px 3px 4px 1px rgb(0 0 0 / 9%);
  @media only screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const InputCode = styled(InputAnt)`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;

  text-transform: uppercase;
`;

export const CustomerVoucherContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;

  .customer_voucher_title {
    font-weight: 600;
    font-size: 1.5rem;
  }

  .customer_voucher_image {
    width: 40%;
    @media only screen and (max-width: 768px) {
      width: 65%;
    }
  }

  .customer_voucher_description {
    padding: 5px;
  }
`;
