import React, { useState } from "react";
import { Modal, Spin, Descriptions, notification } from "antd";
import moment from "moment";
import { Sale as SaleModel } from "../../models/Sale";
import { Table } from "./styles";
import apiSales from "../../services/apiSalesHandler";
import { currencyFormaterPYGBRL } from "../../services/currencyFormaterPYGBRL";

interface Props {
  visible: boolean;
  onCancel: () => void;
  sale: SaleModel | null;
  setCashHistories: React.Dispatch<React.SetStateAction<any>>;
  cashHistories: any;
  store: any;
  countryPY?: boolean;
}

export enum PaymentTypes {
  DINHEIRO = 0,
  CREDITO = 1,
  DEBITO = 2,
  TICKET = 3,
  ONLINE = 4,
  BOLETO = 5,
  PIX = 6,
}

const ModalResendNFCeInfo: React.FC<Props> = ({
  visible,
  onCancel,
  sale,
  setCashHistories,
  cashHistories,
  store,
  countryPY,
}) => {
  const [modalLoading, setModalLoading] = useState(false);

  const totalSold = (sale: SaleModel): number => {
    const totalPayments = sale.payments.reduce((total, payment) => {
      const paymentAmount = Number(payment?.amount) || 0;
      return total + paymentAmount;
    }, 0);

    const changeAmount = Number(sale?.change_amount) || 0;

    const totalAmount = totalPayments - changeAmount;

    return totalAmount;
  };

  const formatCurrency = (amount: number): string => {
    if (countryPY) {
      const cashHistory = cashHistories.find(
        (history) => history.id === sale?.cash_history_id
      );
      const exchangeRate = Number(cashHistory?.pyg_exchange_rate) || 1;
      const amountInGuarani = amount / exchangeRate;
      return `Gs ${currencyFormaterPYGBRL(amountInGuarani, "PYG")}`;
    } else {
      return `R$ ${currencyFormaterPYGBRL(amount, "BRL")}`;
    }
  };

  const handleResendNfce = async (selectedSale: SaleModel) => {
    if (!selectedSale.items.length) {
      return notification.warning({
        message: "Oops! Sem itens na venda",
        description: `Entre em contato com o suporte para verificar o que houve!`,
        duration: 5,
      });
    }

    if (selectedSale.nfce_url) {
      return notification.warning({
        message: `${
          countryPY
            ? `A Fatura Eletrónica já foi emitida para esta venda.`
            : `A NFC-e já foi emitida para esta venda.`
        }`,
        duration: 5,
      });
    }

    let nfcePayload;
    let apiEndpoint;

    if (countryPY) {
      const cashHistory = cashHistories.find(
        (history) => history.id === selectedSale.cash_history_id
      );

      nfcePayload = {
        ruc: selectedSale.ruc,
        check_digit_ruc: Number(selectedSale.check_digit_ruc) || null,
        email: selectedSale.email,
        type_receptor: selectedSale.type_receptor
          ? selectedSale.type_receptor
          : 0,
        type_taxpayer:
          selectedSale.type_taxpayer === null ? 2 : selectedSale.type_taxpayer,
        type_document_py:
          selectedSale.type_receptor === 1 || selectedSale.type_receptor === 2
            ? null
            : selectedSale.type_document_py === null
            ? 1
            : selectedSale?.type_document_py,
        id_document_py:
          selectedSale.type_receptor === 1 || selectedSale.type_receptor === 2
            ? null
            : selectedSale.id_document_py === null && selectedSale.ruc === null
            ? "9999999"
            : selectedSale?.id_document_py,
        exchange_rate_guarani: +cashHistory?.pyg_exchange_rate || 1,
        name_receptor:
          selectedSale.type_receptor === 0
            ? "Sin Nombre"
            : String(selectedSale.name_receptor || "Sin Nombre"),

        store_id: store,
        total: totalSold(selectedSale),
        discount: +selectedSale.discount,
        change_amount: +selectedSale.change_amount,
        items: selectedSale.items.map((product) => ({
          product_store_id:
            product.store_product_id || product.product_store_id,
          price_sell: product.total,
          quantity: product.quantity,
        })),
        payments: selectedSale.payments.map((payment) => ({
          amount: payment.amount,
          type: payment.type,
          ...(payment.type === 1 || payment.type === 2
            ? { flag_card: payment.flag_card === 0 ? 99 : payment.flag_card }
            : {}),
          type_currency: payment.type_currency,
          currency_amount: payment.currency_amount,
          exchange_rate: payment.exchange_rate,
          exchange_rate_currency_to_guarani:
            payment.type_currency === 0
              ? null
              : payment.type_currency === 1
              ? (1 / (+cashHistory?.pyg_exchange_rate || 1)) *
                (payment?.exchange_rate || 0)
              : payment.type_currency === 2
              ? 1 / (+cashHistory?.pyg_exchange_rate || 1)
              : payment.type_currency === 3
              ? 1 / (+cashHistory?.ars_exchange_rate || 1)
              : null,
        })),
        ref: selectedSale.ref,
      };
      apiEndpoint = "/sales/nfe/authorize-invoice-py";
    } else {
      nfcePayload = {
        cpf: selectedSale.cpf,
        email: selectedSale.email,
        store_id: store,
        total: totalSold(selectedSale),
        discount: +selectedSale.discount,
        change_amount: +selectedSale.change_amount,
        items: selectedSale.items.map((product) => ({
          product_store_id:
            product.store_product_id || product.product_store_id,
          price_sell: product.total,
          quantity: product.quantity,
        })),
        payments: selectedSale.payments.map((payment) => ({
          amount: payment.amount,
          type: payment.type,
          ...(payment.type === 1 || payment.type === 2
            ? { flag_card: payment.flag_card === 0 ? 99 : payment.flag_card }
            : {}),
        })),
        ref: selectedSale.ref,
      };
      apiEndpoint = "/sales/nfce";
    }

    try {
      setModalLoading(true);

      const response = await apiSales.post(apiEndpoint, {
        ...nfcePayload,
        sale_id: selectedSale.id,
        cash_history_id: selectedSale.cash_history_id,
      });

      let nfce_focus_id;
      let nfce_url;

      if (countryPY) {
        const { id, caminho_xml_nota_fiscal } = response.data.responseNf;
        nfce_focus_id = id;
        nfce_url = caminho_xml_nota_fiscal;
      } else {
        const { id, caminho_xml_nota_fiscal } = response.data.nfce;
        nfce_focus_id = id;
        nfce_url = `https://api.focusnfe.com.br${caminho_xml_nota_fiscal}`;
      }

      try {
        await apiSales.put(`/sales/${selectedSale.id}`, {
          nfce_focus_id: nfce_focus_id,
          nfce_url: nfce_url,
        });
      } catch (error) {
        notification.warning({
          message: `${
            countryPY
              ? `Fatura Eletrónica emitida, mas houve falha ao atualizar a venda.`
              : `NFC-e emitida, mas houve falha ao atualizar a venda.`
          }`,
          description: `Por favor, entre em contato com o suporte informando a chave gerada: ${nfce_focus_id}`,
          duration: 5,
        });
        return;
      }

      const updatedSale = {
        ...selectedSale,
        nfce_url: nfce_url,
        nfce_focus_id: nfce_focus_id,
      };

      const updatedCashHistories = cashHistories.map((cashHistory) => {
        if (cashHistory.id === selectedSale.cash_history_id) {
          return {
            ...cashHistory,
            sales: cashHistory.sales.map((sale: SaleModel) =>
              sale.id === selectedSale.id ? updatedSale : sale
            ),
          };
        }
        return cashHistory;
      });

      setCashHistories(updatedCashHistories);

      notification.success({
        message: `${
          countryPY
            ? `Fatura Eletrónica emitida e venda atualizada com sucesso.`
            : `NFC-e emitida e venda atualizada com sucesso.`
        }`,
        duration: 5,
      });

      onCancel();
    } catch (error) {
      console.error(error);
      notification.error({
        message:
          //@ts-ignore
          error.response?.data?.message ||
          `${
            countryPY
              ? `Erro ao emitir fatura Eletrónica`
              : `Erro ao emitir NFC-e.`
          }`,
        duration: 5,
      });
    } finally {
      setModalLoading(false);
    }
  };

  const currencySymbols = {
    0: "Gs.",
    1: "U$",
    2: "R$",
    3: "AR$",
  };

  const currencyTypes = {
    0: "Guarani",
    1: "Dólar",
    2: "Real",
    3: "Peso Argentino",
  };

  const getCurrencyType = (currencyCode) => {
    return currencyTypes[currencyCode] || "Desconhecido";
  };

  const getCurrencySymbol = (currencyCode) => {
    return currencySymbols[currencyCode] || "";
  };

  const getExchangeRate = (sale) => {
    if (!sale || !cashHistories) return 1;

    const cashHistory = cashHistories.find(
      (history) => history.id === sale.cash_history_id
    );

    return cashHistory?.pyg_exchange_rate || 1;
  };

  const PaymentTypeLabels: { [key: number]: string } = {
    0: "Dinheiro",
    1: "Crédito",
    2: "Débito",
    3: "Ticket",
    4: "Online",
    5: "Boleto",
    6: "Pix",
  };

  const itemColumns = [
    {
      title: "Produto",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Quantidade",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text: number) => formatCurrency(text),
    },
  ];

  const paymentColumns = [
    {
      title: "Forma de Pagamento",
      dataIndex: "type",
      key: "type",
    },
    ...(countryPY
      ? [
          {
            title: "Moeda",
            dataIndex: "type_currency",
            key: "type_currency",
            render: (item) => getCurrencyType(item),
          },
          {
            title: "Valor",
            dataIndex: "currency_amount",
            key: "currency_amount",
            render: (text, record) => {
              const currencySymbol = getCurrencySymbol(record.type_currency);

              const currencyCodes = {
                0: "PYG",
                1: "USD",
                2: "BRL",
                3: "ARS",
              };

              const currencyCode = currencyCodes[record.type_currency] || "BRL";

              return `${currencySymbol} ${currencyFormaterPYGBRL(
                text,
                currencyCode
              )}`;
            },
          },
          {
            title: "Valor em Guarani",
            dataIndex: "amount_in_guarani",
            key: "amount_in_guarani",
            render: (text) => `Gs. ${formatCurrency(text)}`,
          },
        ]
      : [
          {
            title: "Valor",
            dataIndex: "amount",
            key: "amount",
            render: (text: number) => formatCurrency(text),
          },
        ]),
  ];

  const itemData =
    sale?.items.map((item) => ({
      key: item.id,
      productName: item.product.name,
      quantity: item.quantity,
      total: Number(item.total) || 0,
    })) || [];

  const paymentData =
    sale?.payments.map((payment, index) => {
      if (countryPY) {
        return {
          key: index,
          type: PaymentTypeLabels[payment.type] || "Desconhecido",
          currency_amount: Number(payment?.currency_amount) || 0,
          type_currency: payment.type_currency,
          amount_in_guarani: Number(payment?.amount) || 0,
        };
      } else {
        return {
          key: index,
          type: PaymentTypeLabels[payment.type] || "Desconhecido",
          amount: Number(payment?.amount) || 0,
        };
      }
    }) || [];

  return (
    <Modal
      title={`Confirmar Reenvio de ${
        countryPY ? `Fatura Eletrónica` : `NFC-e`
      }`}
      visible={visible}
      onOk={() => sale && handleResendNfce(sale)}
      onCancel={() => !modalLoading && onCancel()}
      okText="Confirmar"
      cancelText="Cancelar"
      confirmLoading={modalLoading}
      cancelButtonProps={{ disabled: modalLoading }}
      maskClosable={false}
      width={1000}
      closable={!modalLoading}
    >
      {modalLoading ? (
        <div style={{ textAlign: "center" }}>
          <Spin
            tip={`${
              countryPY ? `Enviando Fatura Eletrónica...` : `Enviando NFC-e...`
            }`}
          />
        </div>
      ) : (
        sale && (
          <>
            <Descriptions title="Detalhes da Venda" bordered column={1}>
              <Descriptions.Item label="ID da Venda">
                {sale.id}
              </Descriptions.Item>
              <Descriptions.Item label="Data da Venda">
                {moment(sale.created_at).format("DD/MM/YYYY HH:mm")}
              </Descriptions.Item>
              <Descriptions.Item label="Desconto">
                {formatCurrency(+sale?.discount)}
              </Descriptions.Item>
              <Descriptions.Item label="Valor Total">
                {formatCurrency(totalSold(sale))}
              </Descriptions.Item>
              <Descriptions.Item label="Itens Vendidos">
                <Table
                  scroll={{ y: 120 }}
                  columns={itemColumns}
                  dataSource={itemData}
                  pagination={false}
                  size="small"
                />
              </Descriptions.Item>
              <Descriptions.Item label="Formas de Pagamento">
                <Table
                  scroll={{ y: 100 }}
                  columns={paymentColumns}
                  dataSource={paymentData}
                  pagination={false}
                  size="small"
                />
              </Descriptions.Item>
            </Descriptions>
            <p style={{ marginTop: "16px", fontWeight: "bold" }}>
              {countryPY
                ? `Deseja realmente reenviar a Fatura Eletrónica para esta venda?`
                : `Deseja realmente reenviar a NFC-e para esta venda?`}
            </p>
          </>
        )
      )}
    </Modal>
  );
};

export default ModalResendNFCeInfo;
