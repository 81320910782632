import styled, { css } from "styled-components";

import { Divider as DividerAnt, Row as RowAnt, Col as ColAnt } from "antd";

import { ArrowNarrowRight } from "../../styles/Icons";

interface IProps {
  newvalue: string | number;
  oldvalue: string | number;
}

export const Container = styled(RowAnt)<IProps>`
  display: flex;
  width: 100%;
  min-height: 4.5rem;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid var(--gray-25);
  border-radius: 4px;
  text-transform: capitalize;

  :last-child{
    border-bottom: 0;
  }

  .ant-modal-body {
    border: 1px solid var(--black-opaco);
    border-radius: 5px;
  }

  ${({ newvalue, oldvalue }) => {
    if (!oldvalue && newvalue) {
      return css`
        border: 1px solid #c9f2a0;
        background: #f6ffed;
      `;
    }

    if (oldvalue && newvalue) {
      return css``;
    }

    return css`
      border: 1px solid #f22738;
      background: #fff1f0;
    `;
  }}
`;

export const Divider = styled(DividerAnt)`
  height: 16px;
  border-left: 2px solid #00000075;
  @media only screen and (max-width: 578px) {
    font-size: 14px;
  }
`;

export const ArrowRightIcon = styled(ArrowNarrowRight)`
  width: 1.1rem;
  height: 1.1rem;
  margin: 0 0.5rem;
  @media only screen and (max-width: 578px) {
    width: 0.9rem;
    height: 0.9rem;
  }
`;

export const Action = styled.div<IProps>`
  color: var(--black-opaco);
  font-size: 1rem;

  ${({ newvalue, oldvalue }) => {
    if (!oldvalue && newvalue) {
      return css`
        background: #a6e665;
      `;
    }

    if (oldvalue && newvalue) {
      return css`
        color: var(--orange-700);
      `;
    }

    return css`
      background: #f22738;
    `;
  }};
`;

export const Col = styled(ColAnt)`
  font-size: 16px;
  text-align: center;

  @media only screen and (max-width: 578px) {
    font-size: 14px;
  }
`;
