import React, { useState, useEffect } from "react";

import { getTokenInfo, verifyPermission } from "../../services/auth";
import apiAnalytics from "../../services/apiAnalytics";
import { currencyFormater } from "../../services/currencyFormater";
import states from "../../services/allBrazilStates.json";

import DatePickerAnt from "../../components/DatePicker";
import StoreProgress from "../../components/StoreProgress";

import PageContainer from "../../containers/PageContainer";
import Centralizer from "../../containers/Centralizer";

import locale from "antd/es/date-picker/locale/pt_BR";

import moment from "moment";

import notFound from "../../assets/svg/data-not-found.svg";

import { Store } from "../../models/Store";
import SpinIcon from "../../assets/svg/spin.svg";
import { Empty, Menu } from "antd";
import {
  Container,
  List,
  Panel,
  Spin,
  TotalInfo,
  TotalContainer,
  TopContent,
  Select,
  ButtonSearch,
  SearchIcon,
  Row,
  Col,
  CheckboxMenu,
  FilterMenu,
  FilterIcon,
  SummaryTypeSale,
  Dropdown,
} from "./styles";

const { Option } = Select;

const updateOptions = [
  { name: "Hoje", value: "today" },
  { name: "Ontem", value: "yesterday" },
  { name: "Última semana", value: "last_week" },
  { name: "Último mês", value: "last_month" },
  { name: "Últimos 30 dias", value: "last_thirty_days" },
  { name: "Personalizado", value: "customize" },
];

const BenchMark: React.FC = () => {
  const [stores, setStores] = useState<Store[]>([]);
  const [state, setState] = useState<string | undefined[]>([]);
  const [date, setDate] = useState({ name: "Hoje", value: "today" });
  const [selectedDate, setSelectedDate] = useState({ name: "Hoje" });
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [menuVisible, setMenuVisible] = useState(false);
  const [updateDataRange, setUpdateDataRange] = useState(true);
  const [own_store, setOwn_store] = useState(false);
  const [data, setData] = useState({
    data_inicial: moment(),
    data_final: moment(),
  });
  const [selectedGroup, setSelectedGroup] = useState(false);

  const dateOptions = {
    Hoje: [moment().startOf("day"), moment().endOf("day")],
    Ontem: [
      moment().subtract(1, "days").startOf("day"),
      moment().subtract(1, "days").endOf("day"),
    ],
    "Última semana": [
      moment().subtract(6, "days").startOf("day"),
      moment().endOf("day"),
    ],
    "Último mês": [
      moment().subtract(1, "months").startOf("month"),
      moment().subtract(1, "months").endOf("month"),
    ],
    "Últimos 30 dias": [
      moment().subtract(29, "days").startOf("day"),
      moment().endOf("day"),
    ],
  };

  const handleSelectChange = (selectedValue) => {
    const [startDate, endDate] = dateOptions[selectedValue] || [];

    const selectedOption = updateOptions.find(
      (option) => option.name === selectedValue
    );

    setDate({
      name: selectedOption ? selectedOption.name : selectedValue,
      value: selectedOption ? selectedOption.value : selectedValue,
    });

    if (selectedValue === "Personalizado") setDatePickerVisible(true);
    if (startDate && endDate) {
      setData({
        data_inicial: startDate,
        data_final: endDate,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const user = await getTokenInfo();

      const { data_inicial, data_final } = {
        data_inicial: moment(data.data_inicial).format("DD/MM/YYYY"),
        data_final: moment(data.data_final).add(1, "days").format("DD/MM/YYYY"),
      };

      const url = state
        ? `/midas/benchmark?data_inicial=${data_inicial}&data_final=${data_final}&user=${user?.id}&selected_stores=${selectedGroup}&state=${state}&own_store=${own_store}&search_type=${date.value}`
        : `/midas/benchmark?data_inicial=${data_inicial}&data_final=${data_final}&user=${user?.id}&selected_stores=${selectedGroup}&own_store=${own_store}&search_type=${date.value}`;
      setLoading(true);
      try {
        const response = await apiAnalytics.get(url);

        const {
          data: { content: stores },
        } = response;

        const records = stores.filter(
          (benchmark) =>
            benchmark.top_sold &&
            benchmark.top_sold?.best?.day ===
              benchmark.top_sold?.current?.day &&
            benchmark.top_sold?.worst?.day !== benchmark.top_sold?.current?.day
        );
        setStores(stores.filter((store) => store?.balance?.total));
        setLoading(false);
        setUpdateDataRange(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    if (updateDataRange) {
      fetchData();
    }
  }, [updateDataRange, data, state, selectedGroup, own_store, date.value]);

  const totalPeriod = stores.reduce(
    (total, index) => (total += index.balance.total),
    0
  );

  const menu = (
    <Menu>
      <Menu.Item>
        <CheckboxMenu onChange={() => setOwn_store(!own_store)}>
          Lojas Próprias
        </CheckboxMenu>
      </Menu.Item>
    </Menu>
  );

  return (
    <PageContainer route="BenchMark">
      <Container>
        <TopContent>
          <Col
            md={14}
            sm={12}
            xs={24}
            style={{
              marginLeft: "0",
            }}
          >
            <Row gutter={10}>
              <Col sm={8} xs={24}>
                <Select
                  bordered={false}
                  placeholder="Escolha a Opção"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => setState(value.toString())}
                  defaultValue={"Todos os Estados"}
                >
                  {states.map((state) => (
                    <Option value={state.sigla} key={state.sigla}>
                      {state.nome === "Todos os Estados"
                        ? `${state.nome}`
                        : `${state.nome}, ${state.sigla}`}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col sm={8} xs={24}>
                <Select
                  bordered={false}
                  placeholder="Escolha o período"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value: any) => {
                    setSelectedDate(value);
                    {
                      value === "Personalizado"
                        ? setDatePickerVisible(true)
                        : setDatePickerVisible(false);
                    }
                  }}
                  defaultValue={moment().format("DD/MM/YYYY").toString()}
                >
                  {updateOptions.map((update) => (
                    <Option value={update.name} key={update.name}>
                      {update.name}
                    </Option>
                  ))}
                </Select>

                {datePickerVisible && (
                  <DatePickerAnt
                    date={data}
                    setDate={setData}
                    locale={locale}
                    bordered={true}
                    style={{
                      marginTop: ".3rem",
                    }}
                  />
                )}
              </Col>
              <Col sm={2} xs={24} className="col-button">
                <ButtonSearch
                  onClick={() => {
                    setUpdateDataRange(true);
                    handleSelectChange(selectedDate);
                  }}
                >
                  <SearchIcon /> <span>Pesquisar</span>
                </ButtonSearch>
              </Col>
              {verifyPermission("benchmarking.own_stores") && (
                <Col sm={2} xs={24}>
                  <Dropdown
                    overlay={menu}
                    onVisibleChange={(state) => setMenuVisible(state)}
                    visible={menuVisible}
                    trigger={["click"]}
                  >
                    <FilterMenu>
                      <FilterIcon />
                    </FilterMenu>
                  </Dropdown>
                </Col>
              )}
            </Row>
          </Col>
          <Col
            sm={10}
            xs={24}
            style={{
              margin: "0",
            }}
          >
            {stores.length !== 0 && (
              <TotalContainer>
                <TotalInfo>
                  Total no período:
                  <span> R$ {currencyFormater(totalPeriod)}</span>
                </TotalInfo>
              </TotalContainer>
            )}
          </Col>
        </TopContent>

        {loading ? (
          <Centralizer>
            <Spin src={SpinIcon} />
          </Centralizer>
        ) : stores.length ? (
          <>
            <SummaryTypeSale>
              <span className="type-store">
                <div /> Loja
              </span>
              <span className="type-delivery">
                <div /> Delivery
              </span>
            </SummaryTypeSale>

            <List>
              <span className="rank">Rank de Lojas</span>
              {stores.map((store, index) => (
                <Panel key={store.company_name}>
                  <StoreProgress
                    classification={+index + 1}
                    storeCity={store.store_city}
                    companyName={store.company_name}
                    percentOfRanking={store.percent}
                    franchiseeId={store.franchisee_id}
                    balance={store.balance.total}
                    percentOfSalesStore={Math.round(
                      ((+(store.balance.total_store || 0) -
                        +(store.balance.change_amount || 0)) *
                        100) /
                        +(store.balance.total || 0)
                    )}
                    percentOfSalesDelivery={Math.round(
                      (+(store.balance.total_delivery || 0) * 100) /
                        +(store.balance.total || 0)
                    )}
                    totalStore={
                      +store.balance.total_store - +store.balance.change_amount
                    }
                    totalDelivery={+store.balance.total_delivery}
                  />
                </Panel>
              ))}
            </List>
          </>
        ) : (
          <Centralizer>
            <Empty description="Nenhum caixa aberto" image={notFound} />
          </Centralizer>
        )}
      </Container>
    </PageContainer>
  );
};

export default BenchMark;
