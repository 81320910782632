import React, { Dispatch, SetStateAction, useRef, useState } from "react";

import { message } from "antd";
import { Button, ContentModal, Modal } from "./styles";

interface IPatchNotes {
  visiblePatchNotes: boolean;
  setVisiblePatchNotes: Dispatch<SetStateAction<boolean>>;
  url?: string;
  id?: string;
  created_at?: string;
  viewed?: boolean;
}

const ModalPatchNotes: React.FC<IPatchNotes> = ({
  visiblePatchNotes,
  setVisiblePatchNotes,
  url,
  id,
  viewed,
  created_at,
}) => {
  const patchNotesRef = useRef<HTMLIFrameElement>(null);
  const [isViewed, setIsViewed] = useState(viewed);

  const saveIframeUrlToLocalStorage = () => {
    if (patchNotesRef.current) {
      const iframeCreatedAt = created_at;
      const iframeUrl = patchNotesRef.current.src;
      const iframeId = id;
      const iframeViewed = isViewed;

      const patchNotesData = {
        created_at: iframeCreatedAt,
        url: iframeUrl,
        id: iframeId,
        viewed: iframeViewed,
      };
      localStorage.setItem("patch-notes", JSON.stringify(patchNotesData));
    } else {
      message.error("A referencia do iframe é nula");
    }
  };

  const handleOnClick = () => {
    setIsViewed(true);
    saveIframeUrlToLocalStorage();
    setVisiblePatchNotes(false);
  };

  return (
    <Modal
      title={"Notas de Atualizações"}
      width={window.outerWidth > 700 ? "60%" : "100%"}
      centered
      visible={visiblePatchNotes}
      destroyOnClose={true}
      onCancel={() => setVisiblePatchNotes(false)}
      footer={[<Button onClick={handleOnClick}>Ok</Button>]}
    >
      <ContentModal>
        <iframe src={url} ref={patchNotesRef} title="Notas do Patch"/>
      </ContentModal>
    </Modal>
  );
};

export default ModalPatchNotes;
