import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import api from "../../services/api";

import PageContainer from "../../containers/PageContainer";
import StandardFreightList from "../../containers/StandardFreightList";
import Centralizer from "../../containers/Centralizer";

import { StandardFreight as StandardFreightModel } from "../../models/StandardFreight";

import SpinIcon from "../../assets/svg/spin.svg";

import { message, Modal } from "antd";

import {
  Container,
  Content,
  TopContent,
  AddButton,
  AddIcon,
  DrawerContent,
  Col,
  Input,
  ButtonSave,
  Spin,
  Form,
  Row,
  ModalRegistration,
  ButtonCancel,
} from "./styles";

const { confirm } = Modal;

interface IProps extends RouteComponentProps {}

const StandardFreight: React.FC<IProps> = () => {
  const [shouldSearch, setStateSearch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [freights, setFreights] = useState<StandardFreightModel[]>([]);
  const [freightsId, setFreightsId] = useState<number | null>(null);
  const [filterdFreights, setFilterdFreights] = useState<number | null>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchApi = async () => {
      setLoading(true);
      const {
        data: { data },
      } = await api.get(`/freights`);
      setFreights(data);
      setStateSearch(false);
      setLoading(false);
    };

    if (shouldSearch) {
      fetchApi();
    }
  }, [shouldSearch]);

  const findFreights = (value: number | null) => {
    if (value && +value > 0) {
      return freights.filter(
        (freight) =>
          value >= freight.distance_min && value <= freight.distance_max
      );
    } else {
      return freights;
    }
  };

  const handleCreate = async () => {
    setLoading(true);
    try {
      await form.validateFields();
      const freight = form.getFieldsValue() as StandardFreightModel;
      if (+freight.distance_min > +freight.distance_max) {
        message.warning("Distância mínima deve ser menor que distância máxima");
      } else {
        const method = freight.id ? "put" : "post";
        const url = freight.id ? `/freight/${freight.id}` : "/freight";
        await api[method](url, freight);

        if (method === "post") {
          message.success("Critério cadastrado com sucesso!");
        } else {
          message.success("Critério atualizado com sucesso!");
        }
        setStateSearch(true);
        clearFields();
      }
    } catch (error) {
      const _message =
        //@ts-ignore
        error?.response?.data?.message;
      message.warning(_message || "Preencha os campos corretamente.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (id: number) => {
    setFreightsId(id);
    const freightEdit = freights.find((freight) => freight.id === id);
    form.setFieldsValue({
      ...freightEdit,
      distance_min: freightEdit?.distance_min,
      distance_max: freightEdit?.distance_max,
      value: freightEdit?.value,
    });
  };

  const clearFields = () => {
    setVisible(false);
    form.resetFields();
    setFreightsId(null);
  };

  const handleDelete = async (id: number) => {
    setLoading(true);

    confirm({
      title: "Excluir Critério",
      className: "modalDelete",
      content: "Você deseja excluir este critério de frete?",
      onOk() {
        try {
          api.delete(`/freight/${id}`);
          message.success("Critério excluído com sucesso!");

          setStateSearch(true);
        } catch (error) {
          const _message =
            //@ts-ignore
            error?.response?.data?.message;
          message.error(_message || "Erro ao excluir critério");
        }
      },
    });

    setLoading(false);
  };

  return (
    <PageContainer route="Critérios de Frete">
      <Container>
        <TopContent gutter={4}>
          {freights.length !== 0 && (
            <>
              <Col sm={8} md={6} xl={4} xs={20}>
                <Input
                  placeholder="Digite aqui a quilometragem"
                  type="number"
                  id="filter"
                  onChange={({ target: { value } }) =>
                    setFilterdFreights(+value || null)
                  }
                />
              </Col>

              <Col sm={3} md={3} xs={3} style={{ minWidth: "3.25rem" }}>
                <AddButton onClick={() => setVisible(true)}>
                  <span>Adicionar novo</span>
                  <AddIcon />
                </AddButton>
              </Col>
            </>
          )}
        </TopContent>

        <Content>
          {loading ? (
            <Centralizer>
              <Spin src={SpinIcon} />
            </Centralizer>
          ) : (
            <StandardFreightList
              handleDelete={handleDelete}
              setVisible={setVisible}
              loading={loading}
              freights={findFreights(filterdFreights)}
              handleUpdate={handleUpdate}
            ></StandardFreightList>
          )}
        </Content>

        <ModalRegistration
          title={freightsId ? "Atualizar critério" : "Cadastrar novo critério"}
          closable={true}
          onOk={() => handleCreate()}
          onCancel={() => clearFields()}
          visible={visible}
          footer={[
            <ButtonCancel key="back" onClick={() => clearFields()} type="link">
              Cancelar
            </ButtonCancel>,
            <ButtonSave
              key="submit"
              loading={loading}
              onClick={() => handleCreate()}
              type="primary"
            >
              Salvar
            </ButtonSave>,
          ]}
        >
          <DrawerContent>
            <Form layout="vertical" form={form}>
              <Row>
                <Col sm={0} xs={0}>
                  <Form.Item name="id">
                    <Input name="id" />
                  </Form.Item>
                </Col>
                <Col sm={24} xs={24}>
                  <Form.Item
                    label="Descrição"
                    name="description"
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <Input
                      type="text"
                      placeholder="Descrição"
                      name="description"
                    />
                  </Form.Item>
                </Col>

                <Col sm={12} xs={12}>
                  <Form.Item
                    rules={[
                      { required: true, message: "Campo obrigatório" },
                      {
                        pattern: /^\d+$/,
                        message:
                          "Esse campo só aceita números inteiros positivos",
                      },
                    ]}
                    label="Mín."
                    name="distance_min"
                  >
                    <Input
                      type="number"
                      placeholder="Distância Mínima"
                      name="distance_min"
                    />
                  </Form.Item>
                </Col>

                <Col sm={12} xs={12}>
                  <Form.Item
                    rules={[
                      { required: true, message: "Campo obrigatório" },
                      {
                        pattern: /^\d+$/,
                        message:
                          "Esse campo só aceita números inteiros positivos",
                      },
                    ]}
                    label="Máx."
                    name="distance_max"
                  >
                    <Input
                      type="number"
                      placeholder="Distância Máxima"
                      name="distance_max"
                      min={0}
                    />
                  </Form.Item>
                </Col>

                <Col sm={24} xs={24}>
                  <Form.Item
                    rules={[
                      { required: true, message: "Campo obrigatório" },
                      {
                        pattern: /^\d*\.?\d+$/,
                        message: "Esse campo só aceita números positivos (inteiros ou decimais)",
                      },
                    ]}
                    label="Valor (%)"
                    name="value"
                  >
                    <Input type="number" placeholder="Valor (%)" name="value" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </DrawerContent>
        </ModalRegistration>
      </Container>
    </PageContainer>
  );
};

export default withRouter(StandardFreight);
