import styled from "styled-components";

import { Button as ButtonAnt } from "antd";
import { PersonSupport } from "../../styles/Icons";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const MenuContainer = styled.div`
  display: flex;
  width: 14%;
  height: 100%;

  @media only screen and (max-width: 1440px) {
    width: 15%;
  }

  @media only screen and (max-width: 700px) {
    width: 0%;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  width: 86%;
  height: 100%;

  @media only screen and (max-width: 1440px) {
    width: 85%;
  }

  @media only screen and (max-width: 700px) {
    width: 100%;
    overflow: hidden;
  }
`;

export const Page = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: var(--gray-20);
`;

export const ButtonChat = styled(ButtonAnt)`
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 6rem;

  border-radius: 1.5rem;
  background: var(--chanterelle);
  color: var(--white);
  border: none;
  box-shadow: none;

  :hover,
  :focus,
  :active {
    background: var(--chanterelle);
    color: var(--white);
    border: none;
    box-shadow: none;
    opacity: 80%;
  }

  @media only screen and (max-width: 500px) {
    bottom: 2rem;
    right: 2rem;
  }
`;

export const IFrameDiv = styled.div`
  position: fixed;
  bottom: 0;
  right: 4rem;
  border: none;
  z-index: 3;
  background: var(--white);
  width: 34.35rem;
  height: 31.25rem;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 4.37rem;
    bottom: 30.25rem;
    z-index: 4;
    font-size: 0.7rem;
    font-weight: 500;
    color: var(--white);
    background: var(--blue-900);
    width: 1rem;
    height: 1rem;
    border-radius: 2rem;
  }
  @media only screen and (max-width: 500px) {
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    button {
      right: 0rem;
      top: 0rem;
      width: 1.25rem;
      height: 1.25rem;
      z-index: 5;
      color: var(--white);
    }
  }
`;

export const ChatIframe = styled.iframe`
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 500px) {
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

export const SupportIcon = styled(PersonSupport)`
  width: 2rem;
  height: 2rem;
  color: var(--white);
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 5rem;
  right: 3rem;
  z-index: 1;
  background: var(--maroon);
  color: var(--white);
  font-size: 0.7rem;
  width: 1rem;
  height: 1rem;
  border-radius: 2rem;

  @media only screen and (max-width: 500px) {
    bottom: 4rem;
    right: 1.5rem;
  }
`;
