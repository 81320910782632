import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Select } from "./styles";

import apiAuth from "../../services/apiAuth";
import { getTokenInfo } from "../../services/auth";

const { Option } = Select;

interface IProps {
  showAllStore?: boolean;
  allStores?: Dispatch<SetStateAction<boolean>>;
  handleChangeStore?: (store: any) => void;
  handleChange?: (id: number) => void;
  defaultValue?: number | null;
  disabled?: boolean;
  setMinOrder?: Dispatch<SetStateAction<number | undefined>>;
  filterCountryPY?: boolean;
  filterCountryBR?: boolean;
  setBrasilCompanyIds?: Dispatch<SetStateAction<number[]>>;
  setParaguayCompanyIds?: Dispatch<SetStateAction<number[]>>;
}

const GetStore: React.FC<IProps> = ({
  handleChangeStore,
  handleChange,
  defaultValue,
  disabled,
  setMinOrder,
  filterCountryPY,
  filterCountryBR,
  setParaguayCompanyIds,
  setBrasilCompanyIds,
  allStores,
  showAllStore
}) => {
  const [loading, setLoading] = useState(true);
  const [stores, setStores] = useState<
    { key: number; value: string; min_order: number }[]
  >([]);

  useEffect(() => {
    const fetchStores = async () => {
      setLoading(true);
      const user = await getTokenInfo();
      const {
        data: { content },
      } = await apiAuth.get(`/companyUser/${user?.id}/user`);
      let response = content.map((company) => ({
        key: company.company_id,
        value: company.company.company_name,
        min_order: company.company.min_order,
        company: company.company,
      }));

      setStores(response);
      setLoading(false);

      if (filterCountryBR) {
        response = response.filter(
          (store) => store?.company?.country === "brasil"
        );
      }
      if (setBrasilCompanyIds && filterCountryBR) {
        const brasilIds = response.map((store) => store.key);
        setBrasilCompanyIds(brasilIds);
      }
      if (filterCountryPY) {
        response = response.filter(
          (store) => store?.company?.country === "paraguai"
        );
      }

      if (setParaguayCompanyIds && filterCountryPY) {
        const paraguayIds = response.map((store) => store.key);
        setParaguayCompanyIds(paraguayIds);
      }

      setStores(response);
      setLoading(false);
    };

    fetchStores();
    setStores([]);
  }, []);

  const onChange = (storeId: number) => {
    const storeSelect = stores?.find((_store) => _store.key === storeId);

    if (storeId === 0) {
      allStores && allStores(true);
    } else {
      allStores && allStores(false);
    }

    setMinOrder && setMinOrder(storeSelect?.min_order);
    handleChange && handleChange(+storeId);
    handleChangeStore && handleChangeStore(storeSelect);
  };

  return (
    <Select
      disabled={disabled}
      placeholder="Selecione uma loja"
      defaultValue={defaultValue || undefined}
      onChange={(id) => onChange(+id)}
      loading={loading}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      <>
        {showAllStore && (
          <Option value={0} key={0}>
            Todas as Lojas
          </Option>
        )}
        {stores.map((store) => (
          <Option
            key={store.key}
            value={store.key}
            style={{ textTransform: "uppercase" }}
          >
            {store.value}
          </Option>
        ))}
      </>
    </Select>
  );
};

export default GetStore;
