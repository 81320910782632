import styled, { css } from "styled-components";

import {
  Table as TableAnt,
  List as ListAnt,
  Button as ButtonAnt,
} from "antd";
import { MoreHoriz } from "../../styles/Icons";
import {
  ArrowIosUpward,
  ArrowIosDownward,
  ArrowDropDown,
  ArrowDropUp,
} from "../../styles/Icons";

export const Container = styled.div`
  height: 100%;
  width: 100%;

  overflow-y: auto;
`;

export const ErrorCash = styled.span`
  font-size: 1rem;
  margin: 0;
  padding: 0;
  color: #47d173;
`;

export const ButtonOpenModal = styled(ButtonAnt)`
  background-color: transparent;
  color: var(--orange-350);
  border: 0;
  box-shadow: none;
  cursor: pointer;
  :hover {
    color: var(--orange-350);
    opacity: 50%;
  }
`;

export const List = styled(ListAnt)`
  .ant-list-item-meta-title {
    display: none;
  }
  .ant-list-items {
    display: flex;
    justify-content: space-between;
    width: 95%;
  }

  .ant-list-header {
    display: flex;
    width: 106%;

    div {
      width: 15.8%;
    }
  }
`;

export const LabelName = styled.label`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  width: 100%;
  height: 100%;

  .hourTime {
    color: var(--orange-350);
    margin: 0 !important;
  }
  .dateTime {
    display: flex;
    flex-direction: row;

    span {
      margin: 0 0.2rem 0 0;
    }
    @media only screen and (max-width: 800px) {
      flex-direction: column;
    }
  }
  .amountSM {
    @media only screen and (max-width: 578px) {
      display: none;
    }
  }
  .amountXS {
    display: none;
    @media only screen and (max-width: 578px) {
      display: flex;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  color: var(--orange-350);
  margin-left: 0.5rem;

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: red !important;
  }
`;

const IconCSS = css`
  width: 1.7rem;
  height: 1.7rem;
  margin-right: 0.5rem;
`;

export const ArrowUpIcon = styled(ArrowIosUpward)`
  ${IconCSS}
  color: var(--green-400);
`;

export const ArrowDownIcon = styled(ArrowIosDownward)`
  ${IconCSS}
  color: var(--orange-600);
`;

export const MoreInfo = styled(MoreHoriz)`
  width: 23px;
  height: 23px;
  cursor: pointer;
  :hover,
  :active,
  :focus {
    fill: orange;
  }
  @media only screen and (max-width: 578px) {
    width: 18px;
    height: 18px;
  }
`;
export const Table = styled(TableAnt)`
  user-select: none;

  *::-webkit-scrollbar {
    display: none;
  }

  .ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table {
    margin: 0;
  }

  .children_table .ant-table-cell {
    background-color: #fff6f4;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 16px 7px;
  }

  .children_table .tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row > td {
    padding: 0;
  }

  .children_table .ant-table-tbody {
    background-color: #fff6f4;
  }
  .ant-table-container {
    border-radius: 5px;
    border: 1px solid var(--gray-75);
    height: 100%;
    overflow: hidden;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    @media only screen and (max-width: 1250px) {
      max-height: 35rem;
    }

    @media only screen and (max-width: 600px) {
      height: calc(100% - 5rem);
      .ant-table-thead > tr > th {
        font-size: 0.8rem;
      }

      .ant-table-tbody > tr > td {
        font-size: 0.9rem;
      }

      .ant-table-cell .ant-table-cell-scrollbar {
        display: none;
      }
    }

    @media only screen and (max-width: 500px) {
      max-height: 100vh;
    }
  }

  .ant-table-tbody > tr > td {
    text-transform: capitalize;
    color: var(--blue-999);
    font-weight: 400;
    font-size: 1rem;
    overflow: hidden;
    hyphens: auto;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }

  .ant-table-thead > tr > th {
    background: #fdfdfd;
    color: var(--blue-900);
  }
  .ant-select-selection-item {
    width: 6rem;
  }
  .nfce {
    color: var(--midori-green);
  }
  .not-nfce {
    color: var(--maroon);
  }
`;

export const ContentTitleSales = styled.div`
  display: flex;
  width: 100%;
`;

const iconCSS = css`
  width: 40px;
  height: 40px;

  cursor: pointer;
`;

export const DropdownIcon = styled(ArrowDropDown)`
  ${iconCSS};
`;
export const DropUpIcon = styled(ArrowDropUp)`
  ${iconCSS};
`;

export const CellContainer = styled.div`
  display: flex;
`;

export const IconTotem = styled.img`
  width: 1rem;
  height: 1rem;
`;

export const IconCaixa = styled.img`
  width: 1rem;
  height: 1rem;
`;
