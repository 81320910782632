import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";

import apiS3Handler from "../../../../services/apiS3Handler";
import apiMercury from "../../../../services/apiMercury";
import apiAuth from "../../../../services/apiAuth";

import moment, { Moment } from "moment";
import locale from "antd/es/date-picker/locale/pt_BR";
import { Divider, Modal, notification, Tooltip } from "antd";

import NewService from "../NewServicePage";

import OrderPaymentType from "../../../../models/json/OrderPaymentType.json";
import { AccountPayable } from "../../../../models/AccountsPayable";
import { PaymentTypeShop } from "../../../../models/enums/PaymentConditionShop";
import { ShopType } from "../../../../models/enums/ShopType";

import PageContainer from "../../../../containers/PageContainer";

import SearchProvider from "../../../../components/SearchProvider";
import MonetaryInput2 from "../../../../components/MonetaryInput2";
import Spinner from "../../../../components/Spinner";

import { currencyFormater } from "../../../../services/currencyFormater";

import { removeAccentsAndLowerCase } from "../../../../utils/removeAccentsAndCaseSensitive";

import {
  Col,
  Container,
  Row,
  Form,
  TitlePage,
  Input,
  TrashIcon,
  ColIcon,
  DatePicker,
  Select,
  Footer,
  Button,
  NewRowButton,
  InfoCircleIcon,
  ContentFileView,
  ChevronIcon,
  InputFile,
  StyledInput,
} from "./styles";

interface ComponentProps extends RouteComponentProps<{}, {}, any> { }

type Item = {
  key: number;
  additional_value: number;
  quantity: number;
  total?: number;
  product_id: number;
  unitary_value: number;
  value_icms: number;
  value_ipi: number;
  observation?: string;
  category_id: number;
  id?: number | null;
};

type UploadedFile = {
  url_file: string;
  s3_key: string;
};

const paymentCondition = [
  { id: 1, name: "À vista" },
  { id: 2, name: "À prazo" },
];

const NewShop: React.FC<ComponentProps> = ({ location }) => {
  const { state } = location;
  const { record } = state;

  const { store } = state;
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const [form] = Form.useForm();
  const [items, setItems] = useState<Item[]>([
    {
      key: Date.now(),
      additional_value: 0,
      quantity: 0,
      product_id: 0,
      unitary_value: 0,
      value_icms: 0,
      value_ipi: 0,
      observation: "",
      category_id: 0,
    },
  ]);
  const [itemsPayable, setItemsPayable] = useState<AccountPayable[]>([
    {
      account_type: 0,
      category_id: null,
      store_id: null,
      provider_id: null,
      payment_amount: null,
      occurrence: null,
      due_date: null,
      payment_method: null,
      issue_date: null,
      month_competence: null,
      observationItem: "",
    },
  ]);

  const [formValues, setFormValues] = useState<{
    category_id: number | null;
    product_id: number | null;
    month_competence: Moment | null;
    due_date: Moment | null;
    payment_method: number | null;
    issue_date: Moment | null;
    payment_date: Moment | null;
    cod_bankslip: string;
    additionalValue: number;
    discountValue: number;
    valueInterest: number;
    valueFreight: number;
    totalAccount: number;
    paymentValue: number;
    observation: string;
    accountsPayable_id?: number;
  }>({
    category_id: 11,
    product_id: null,
    month_competence: null,
    due_date: null,
    payment_method: null,
    issue_date: null,
    payment_date: null,
    cod_bankslip: "",
    additionalValue: 0,
    discountValue: 0,
    valueInterest: 0,
    valueFreight: 0,
    totalAccount: 0,
    paymentValue: 0,
    observation: "",
  });

  const [selectedCondition, setSelectedCondition] = useState<number | null>(
    null
  );
  const [installments, setInstallments] = useState<number>(1);
  const [installmentValue, setInstallmentValue] = useState(0);

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<any[]>([]);

  const [type, setType] = useState<number | null>(1);

  const [discountValue, setDiscountValue] = useState<number | null>();
  const [valueFreight, setValueFreight] = useState<number | null>();
  const [valueAdditional, setValueAdditional] = useState<number | null>();
  const [valueInterestAmount, setValueInterestAmount] = useState<
    number | null
  >();
  const [icmsStValue, setIcmsStValue] = useState<number | null>();
  const [totalShop, setTotalShop] = useState<number | null>();

  const [totalItemsValue, setTotalItemsValue] = useState({
    total_icms: 0,
    total_ipi: 0,
    total_shop: 0,
    additional_value: 0,
    total_products: 0,
  });

  const [purchaseFilesUpdate, setPurchaseFilesUpdate] = useState<
    { key: number; s3_key: string; url_file: string; id: number }[]
  >([]);

  const [selectedOcurrencyType, setSelectedOcurrencyType] = useState<
    number | null
  >(null);

  const [provider, setProvider] = useState<number | null>(null);

  useEffect(() => {
    if (record && record.type && record.type !== 2 || record && !record.type) {
      setLoading(true);
      const {
        purchaseFiles,
        discount_value,
        value_freight,
        created_at,
        occurrence,
        due_date,
        observation,
        provider_id,
        purchasesItems,
        purchase_installment,
        additional_value,
        accountsPayable,
        purchase_date,
        icms_st_value,
        interest_amount,
        type,
        month_competence,
        discounts,
      } = record;

      if (record?.accountsPayable.length === 0) {
        let accountsPayable: any = [{
          id: record?.id,
          account_type: record?.account_type,
          category_id: record?.category_id,
          store_id: record?.store_id,
          provider_id: record?.provider_id,
          payment_amount: record.total,
          due_date: record?.due_date,
          payment_method: record.payment_method,
          issue_date: record?.issue_date,
          observationItem: record.observation,
          created_at: record?.created_at,
        }];

        setItemsPayable(accountsPayable)
      }

      form.setFieldsValue({
        occurrence: occurrence,
        due_date: due_date ? moment(due_date) : null,
        observation: observation,
        discount_value: discount_value,
        value_freight: value_freight,
        interest_amount: interest_amount,
        created_at: created_at ? moment(created_at) : null,
        total_quantidades: calculateTotalQuantities(purchasesItems),
        purchase_installment: purchase_installment,
        purchase_date: purchase_date ? moment(purchase_date) : "",
        type: type,
        month_competence: month_competence ? moment(month_competence) : null,
        additional_value: additional_value,
        discounts: discounts,
      });

      if (purchaseFiles && purchaseFiles.length > 0) {
        const mappedItems =
          purchaseFiles?.map((item, index) => {
            return {
              key: index,
              s3_key: item.s3_key,
              url_file: item.url_file,
              id: item.id,
            };
          }) || [];
        setPurchaseFilesUpdate([...mappedItems]);
      }

      setTotalItemsValue((oldValues) => ({
        ...oldValues,
        total_ipi: calculateTotalProductsValue(purchasesItems).ipiTotal,
        total_shop:
          calculateTotalProductsValue(purchasesItems).totalCalculateShop,
        total_icms: calculateTotalProductsValue(purchasesItems).icmsTotal,
        total_products:
          calculateTotalProductsValue(purchasesItems).totalProducts,
        additional_value: +additional_value,
      }));

      setDiscountValue(discount_value);
      setValueFreight(value_freight);
      setValueAdditional(additional_value);
      setValueInterestAmount(interest_amount);
      setIcmsStValue(icms_st_value);
      setProvider(provider_id);
      setSelectedCondition(occurrence);
      setInstallmentValue(installmentValue);
      setInstallments(accountsPayable?.length);

      if (purchasesItems && purchasesItems.length > 0) {
        const mappedItems = purchasesItems.map((item, index) => {
          const _totalItemValue =
            parseFloat(item.quantity) * parseFloat(item.unitary_value);
          return {
            key: index,
            product_id: item?.product_id,
            quantity: item?.quantity,
            unitary_value: item?.unitary_value,
            value_icms: item?.value_icms,
            value_ipi: item?.value_ipi,
            total: +(+_totalItemValue + +item?.value_ipi + +item?.value_icms),
            observation: item?.observation || "",
            category_id: item?.category_id,
            id: item?.new ? null : item?.id,
          };
        });
        setItems([...mappedItems]);
      }

      const sortAccountsPayable = (ant, prox) => {
        if (moment(ant?.due_date).isBefore(moment(prox?.due_date))) {
          return -1;
        } else if (moment(ant?.due_date).isAfter(moment(prox?.due_date))) {
          return 1;
        }
        return 0;
      };

      if (accountsPayable && accountsPayable.length > 0) {
        const mappedItems = accountsPayable.map((item) => {
          return {
            id: item?.id,
            account_type: item?.account_type,
            category_id: item?.category_id,
            store_id: item?.store_id,
            provider_id: item?.provider_id,
            payment_amount: item.payment_amount,
            due_date: item?.due_date,
            payment_method: item.payment_method,
            issue_date: item?.issue_date,
            observationItem: item.observation,
            created_at: item?.created_at,
          };
        });
        setItemsPayable([...mappedItems].sort(sortAccountsPayable));
      }
      setType(1);
      setTimeout(() => {
        setLoading(false);
      }, 50);
    } else {
      setLoading(false);
    }
  }, [record, form]);

  useEffect(() => {
    if (record && record.type === 2) {
      const {
        purchaseFiles,
        purchasesItems,
        accountsPayable,
        total,
        value_freight,
        month_competence,
        due_date,
        type,
        observation,
      } = record;

      form.setFieldsValue({ type: type })

      if (purchaseFiles && purchaseFiles.length > 0) {
        const mappedItems =
          purchaseFiles?.map((item, index) => {
            return {
              key: index,
              s3_key: item.s3_key,
              url_file: item.url_file,
              id: item.id,
            };
          }) || [];
        setPurchaseFilesUpdate([...mappedItems]);
      }

      setFormValues({
        category_id: purchasesItems[0].category_id,
        product_id: purchasesItems[0].product_id,
        month_competence: month_competence ? moment(month_competence) : null,
        due_date: due_date ? moment(due_date) : null,
        payment_method: accountsPayable[0].payment_method,
        issue_date: accountsPayable[0].issue_date ? moment(accountsPayable[0].issue_date) : null,
        payment_date: accountsPayable[0].payment_date ? moment(accountsPayable[0].payment_date) : null,
        cod_bankslip: accountsPayable[0].cod_bankslip,
        paymentValue: purchasesItems[0].unitary_value || 0,
        discountValue: accountsPayable[0].discounts || 0,
        valueInterest: accountsPayable[0].interest_amount || 0,
        valueFreight: value_freight || 0,
        totalAccount: total || 0,
        additionalValue: valueAdditional || 0,
        observation: observation,
        accountsPayable_id: accountsPayable[0].id,
      });
      setType(2);
    }
  }, [record, form, type]);

  useEffect(() => {
    if (!record) {
      const installmentValue = (totalShop || 0) / (installments || 1);

      const newItemsPayable = Array.from(
        { length: installments },
        (_, index) => {
          if (index < itemsPayable.length) {
            return {
              ...itemsPayable[index],
              payment_amount: installmentValue,
            };
          }
          return {
            account_type: null,
            store_id: null,
            provider_id: null,
            payment_amount: installmentValue,
            occurrence: null,
            due_date: null,
            payment_method: null,
            issue_date: null,
            month_competence: null,
            observationItem: null,
            category_id: null,
          };
        }
      );

      for (let index = installments; index < itemsPayable.length; index++) {
        form.setFieldsValue({
          [`account_type${index}`]: null,
          [`store_id${index}`]: null,
          [`provider_id${index}`]: null,
          [`occurrence${index}`]: null,
          [`due_date${index}`]: null,
          [`payment_method${index}`]: null,
          [`issue_date${index}`]: null,
          [`month_competence${index}`]: null,
          [`observationItem${index}`]: null,
          [`category_id${index}`]: null,
        });
      }

      setItemsPayable(newItemsPayable);
    }
  }, [installments, selectedCondition]);

  useEffect(() => {
    if (type === 2) {
      const filtered = state.productsCategory?.filter((category) =>
        ["custo fixo", "custo com pessoas"].includes(
          category.name.toLowerCase()
        )
      );
      setFilteredCategories(filtered || []);
    } else {
      setFilteredCategories(state.productsCategory || []);
    }
  }, [type, state.productsCategory]);

  const calculateTotalQuantities = (_items) => {
    return _items.reduce((acc, item) => acc + (+item.quantity || 0), 0);
  };

  const calculateTotalProductsValue = (_items) => {
    return _items.reduce(
      (acc, item) => {
        const quantity = +item.quantity || 0;
        const unitaryValue = +item.unitary_value || 0;
        const total = item.total || 0;
        const ipiValue = +item.value_ipi || 0;
        const icmsValue = +item.value_icms || 0;

        acc.totalCalculateShop += +total;
        acc.totalProducts += +quantity * +unitaryValue;
        acc.ipiTotal += +ipiValue;
        acc.icmsTotal += +icmsValue;

        return acc;
      },
      { totalCalculateShop: 0, totalProducts: 0, ipiTotal: 0, icmsTotal: 0 }
    );
  };

  useEffect(() => {
    const calculateTotalProductsValueItems = () => {
      const totals = calculateTotalProductsValue(items);

      setTotalItemsValue((oldValues) => ({
        ...oldValues,
        total_ipi: totals.ipiTotal,
        total_shop: totals.totalCalculateShop - totals.icmsTotal,
        total_icms: totals.icmsTotal,
        total_products: totals.totalProducts,
      }));
    };

    form.setFieldsValue({
      total_quantidades: calculateTotalQuantities(items),
    });
    if (items) {
      calculateTotalProductsValueItems();
    }
  }, [items]);

  const calculateTotalShop = (values) => {
    const { additional_value, total_shop } = values;

    const totalBase = +additional_value + +total_shop;
    const totalCalculated =
      +totalBase -
      +(discountValue || 0) +
      +(valueFreight || 0) +
      +(valueInterestAmount || 0) +
      +(valueAdditional || 0) +
      +(icmsStValue || 0);
    return +totalCalculated.toFixed(2);
  };

  useEffect(() => {
    const _totalShop = Math.max(0, calculateTotalShop(totalItemsValue));
    setTotalShop(_totalShop);
    if (!loading) {
      const _installmentValue = (_totalShop || 0) / (installments || 1);

      const _itemsPayable = itemsPayable.map((oldValues) => ({
        ...oldValues,
        payment_amount: _installmentValue,
      }));

      setItemsPayable(() => [..._itemsPayable]);
    }
  }, [
    totalItemsValue,
    discountValue,
    valueFreight,
    icmsStValue,
    valueInterestAmount,
    valueAdditional,
  ]);

  const handleInputChange = (index, name, value) => {
    setItemsPayable((prevItems) => {
      const newItems = [...prevItems];

      if (newItems[index]) {
        newItems[index] = { ...newItems[index], [name]: +value };
      }

      return newItems;
    });
  };

  useEffect(() => {
    if (installments > 0) {
      const value = ((totalShop || 0) / installments).toFixed(2);
      setInstallmentValue(+value);
    } else {
      setInstallmentValue(+totalShop!.toFixed(2));
    }
  }, [totalShop, installments]);

  const uploadFiles = async (): Promise<UploadedFile[]> => {
    const inputFile = document.getElementById("input_file") as HTMLInputElement;

    if (!inputFile || !inputFile.files || inputFile.files.length === 0) {
      return [];
    }

    const inputFiles = Array.from(inputFile.files);
    const fileList = inputFiles.filter((file) =>
      selectedFiles.some((item) => item.name === file.name)
    );

    const results: UploadedFile[] = [];
    try {
      setLoadingUpload(true);
      for (const file of fileList) {
        const timeStamp = new Date().getTime();
        const fileNameWithTimeStamp = `${file.name}_${timeStamp}`;

        const formData = new FormData();
        const renamedFile = new File([file], fileNameWithTimeStamp, {
          type: file.type,
        });
        formData.append("file", renamedFile);
        formData.append("owner", store);
        if (type === 1) {
          formData.append("folder", "compras");
        } else {
          formData.append("folder", "contas");
        }
        formData.append("action", "create");

        try {
          const {
            data: { location, key },
          } = await apiS3Handler.post(
            `/s3-upload/upload/amatech-purchase-files`,
            formData
          );

          await apiAuth.post("/files-management", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          results.push({ url_file: location, s3_key: key });
        } catch (error) {
          const errorMessage =
            (error as any)?.response?.data?.message || "Erro ao fazer upload";
        }
      }
      return results;
    } catch (error) {
      notification.error({
        message: "Erro ao fazer upload",
        description: "Um erro inesperado ocorreu.",
        duration: 5,
      });
      return [];
    } finally {
      setLoadingUpload(false);
    }
  };

  const handleSubmit = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    const totalPayments = itemsPayable.reduce(
      (acc, item) => acc + +item.payment_amount!,
      0
    );
    if (type !== ShopType.Servico) {
      if (Math.abs(Number(totalPayments) - Number(totalShop)) > 0.01) {
        notification.error({
          message: "Erro ao cadastrar compra",
          description: `O valor total das parcelas (${totalPayments.toFixed(
            2
          )}) não coincide com o valor total da compra (${totalShop!.toFixed(
            2
          )}).`,
          duration: 5,
        });
        return;
      }
    }
    const allItemsSameCategory = items.every(
      (item) => item.category_id === items[0]?.category_id
    );

    const totalInstallments = values?.purchase_installment || 1;
    let installments = Array.from(
      { length: totalInstallments },
      (_, index) => index + 1
    );

    Modal.confirm({
      title: record
        ? type === 2
          ? "Editar Compra de Serviço"
          : "Editar Compra de Mercadoria"
        : type === 2
          ? "Cadastrar Compra de Serviço"
          : "Cadastrar Compra de Mercadoria",
      content: record
        ? type === 2
          ? "Deseja continuar e editar essa Compra de Serviço?"
          : "Deseja continuar e editar essa Compra de Mercadoria?"
        : type === 2
          ? "Deseja continuar e cadastrar essa Compra de Serviço?"
          : "Deseja continuar e cadastrar essa Compra de Mercadoria?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      centered: true,
      async onOk() {
        let fileList = await uploadFiles();
        let _filesUpload =
          record && record.purchaseFiles ? record?.purchaseFiles?.concat(fileList) : fileList;

        let payload;
        if (type === 1) {
          const firstDueDate = itemsPayable.length ? moment(itemsPayable[0]?.due_date).toISOString() : null;

          payload = {
            type: type,
            store_id: state?.store,
            total: +(totalShop || 0),
            provider_id: provider,
            provider: provider,
            cod_bankslip: values?.cod_bankslip || "",
            name: state.productsCategory.find(
              (product) => product.id === values?.category_id0
            )?.name,
            icms_st_value: +(icmsStValue || 0),
            due_date: firstDueDate,
            additional_value: +(valueAdditional || 0),
            occurrence: selectedCondition,
            value_freight: +(valueFreight || 0),
            discount_value: +(discountValue || 0),
            is_account_payable: true,
            purchase_installment: values?.purchase_installment,
            observation: values?.observation,
            interest_amount: +(valueInterestAmount || 0),
            purchase_date: values?.purchase_date,
            purchaseFiles: _filesUpload,
            accountsPayableFiles: _filesUpload,
            month_competence: values?.month_competence,
            purchasesItems: items.map((item) => ({
              product_id: item.product_id,
              category_id: item.category_id,
              unitary_value: item.unitary_value,
              quantity: item.quantity,
              value_ipi: +item.value_ipi,
              total:
                +((item.total || 0) - (+item.value_icms || 0)).toFixed(2) || 0,
              new: true,
              id: item?.id,
              value_icms: item?.value_icms,
              observation: item?.observation,
            })),
            accountsPayable: itemsPayable.map((item, index) => ({
              account_type: 1,
              category_id: allItemsSameCategory
                ? items[0]?.category_id
                : items[index]?.category_id || items[0]?.category_id,
              store_id: state?.store,
              provider_id: provider,
              payment_amount: item.payment_amount,
              due_date: moment(item?.due_date).toISOString(),
              payment_method: item.payment_method,
              installment_number: installments[index],
              purchase_installment: values?.purchase_installment,
              observation: item.observationItem,
              id: item?.id
            })),
          };
        } else {
          payload = {
            type: type,
            store_id: state?.store,
            due_date: moment(values?.due_date).toISOString(),
            total: formValues?.totalAccount || 0,
            provider_id: provider,
            provider: provider,
            name: state.productsCategory.find(
              (product) => product.id === values?.category_id0
            )?.name,
            occurrence: 1,
            value_freight: formValues?.valueFreight || 0,
            discount_value: formValues?.discountValue || 0,
            is_account_payable: true,
            purchase_installment: values?.purchase_installment,
            observation: values?.observation,
            interest_amount: values?.interest_amount,
            purchase_date: values?.purchase_date,
            purchaseFiles: _filesUpload,
            accountsPayableFiles: _filesUpload,
            month_competence: values?.month_competence,
            purchasesItems: [
              {
                product_id: values.product_id,
                category_id: values.category_id,
                unitary_value: formValues?.paymentValue,
                quantity: 1,
                value_ipi: null,
                total: formValues?.totalAccount || 0,
                new: true,
                id: values?.id,
                value_icms: null,
              },
            ],
            accountsPayable: itemsPayable.map((_, index) => ({
              id: formValues?.accountsPayable_id,
              provider_id: values?.provider_id,
              installment_number: installments[index],
              purchase_installment: values?.purchase_installment,
              purchase_id: undefined,
              occurrence: 1,
              account_type: 2,
              store_id: store,
              category_id: values.category_id,
              product_id: values?.product_id,
              payment_amount: formValues?.totalAccount || 0,
              due_date: moment(values?.due_date).toISOString(),
              payment_date: values?.payment_date
                ? moment(values?.payment_date).toISOString()
                : null,
              payment_method: values.payment_method,
              payment_condition: values.payment_condition || 1,
              issue_date: values?.issue_date,
              month_competence: values?.month_competence,
              observation: values.observation,
              accountsPayableFiles: _filesUpload,
              payment_status: !!values?.payment_date,
              additional_value: formValues?.additionalValue || 0,
              discounts: formValues?.discountValue || 0,
              freight: formValues.valueFreight || 0,
              interest_amount: formValues?.valueInterest || 0,
              total: formValues?.totalAccount || 0,
              cod_bankslip: values?.cod_bankslip || "",
            })),
          };
        }

        try {
          setLoading(true);
          const method = record ? "put" : "post";
          const url = record ? `/purchases/${record.id}` : "/purchases";

          await apiMercury[method](url, payload);

          await form.resetFields();
          const message =
            type === 1
              ? record
                ? "Compra de Mercadoria alterada com sucesso"
                : "Compra de Mercadoria efetuada com sucesso"
              : record
                ? "Compra de Serviço alterada com sucesso"
                : "Compra de Serviço efetuada com sucesso";

          notification.success({
            message,
            duration: 5,
          });

          history.push({
            pathname: "/shop",
            state: { store_param: store },
          });
        } catch (error) {
          //@ts-ignore
          const _description = error?.response?.data?.error?.message;

          const action =
            type === 1
              ? record
                ? "Editar"
                : "Cadastrar"
              : record
                ? "Editar"
                : "Cadastrar";
          const message =
            type === 1
              ? `Oops, ocorreu um erro ao ${action} a Compra de Mercadoria, ${_description}`
              : `Oops, ocorreu um erro ao ${action} a Compra de Serviço, ${_description}`;

          notification.error({
            message,
            description: _description,
            duration: 5,
          });
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const addItem = () => {
    setItems([
      ...items,
      {
        key: Date.now(),
        additional_value: 0,
        quantity: 0,
        product_id: 0,
        unitary_value: 0,
        value_icms: 0,
        value_ipi: 0,
        observation: "",
        category_id: 0,
      },
    ]);
  };

  const removeItem = (key) => {
    let _itemList = items.filter((item) => item.key !== key);

    setItems(_itemList);
    _itemList.forEach((item, index) => {
      form.setFieldsValue({
        [`product_id${index}`]: item.product_id ? item.product_id : "",
        [`category_id${index}`]: item.category_id ? item.category_id : "",
        [`quantity${index}`]: item.quantity ? item.quantity : "",
        [`observation${index}`]: item.observation ? item.observation : "",
      });
    });
    form.setFieldsValue({
      [`product_id${_itemList.length}`]: "",
      [`category_id${_itemList.length}`]: "",
      [`quantity${_itemList.length}`]: "",
      [`observation${_itemList.length}`]: "",
    });
  };

  const handleQuantityChange = (index, value) => {
    const newItems = [...items];
    newItems[index].quantity = +value;
    const _total = +value * +newItems[index].unitary_value;
    const _total_ipi = +newItems[index].value_ipi || 0;
    const _total_icms = +newItems[index].value_icms || 0;
    newItems[index].total = +_total + +_total_ipi + +_total_icms;
    setItems(newItems);
  };

  const handleValueChange = (index, value) => {
    const newItems = [...items];
    newItems[index].unitary_value = +value;
    const _total = +value * +newItems[index].quantity;
    const _total_ipi = +newItems[index].value_ipi || 0;
    const _total_icms = +newItems[index].value_icms || 0;
    newItems[index].total = +_total + +_total_ipi + +_total_icms;
    setItems(newItems);
  };

  const handleIpiChange = (index, value) => {
    const newItems = [...items];
    const _total = +newItems[index].unitary_value * +newItems[index].quantity;
    const _ipi_value = +value || 0;
    const _total_icms = +newItems[index].value_icms || 0;
    newItems[index].value_ipi = _ipi_value;
    newItems[index].total = _total + _ipi_value + _total_icms;
    setItems(newItems);
  };

  const handleIcmsChange = (index, value) => {
    const newItems = [...items];
    const _total = +newItems[index].unitary_value * +newItems[index].quantity;
    const _total_ipi = newItems[index].value_ipi || 0;
    const _total_icms_value = +value || 0;
    newItems[index].value_icms = _total_icms_value;
    newItems[index].total = _total + _total_ipi + _total_icms_value;
    setItems(newItems);
  };

  const handlePurchasesOrder = (value, name, index, value2?, name2?) => {
    let _items = [...items];
    _items[index] = { ..._items[index], [name]: value };
    if (value2) {
      _items[index] = { ..._items[index], [name2]: value2 };
    }
    setItems(_items);
  };

  const handlePaymentMethod = (value, name, index) => {
    let _items = [...itemsPayable];
    _items[index] = { ..._items[index], [name]: value };
    setItemsPayable(_items);
  };

  const resetFormErrors = (value) => {
    form.setFields([
      {
        name: value,
        errors: [],
      },
    ]);
  };

  const resetOccurrence = (value) => {
    form.setFieldsValue({ occurrence: value });
    setSelectedCondition(+value);
    form.setFieldsValue({ purchase_installment: "" });
    if (value === 1) {
      setInstallments(1);
    }
  };

  const allProducts = state.productsCategory
    ?.flatMap((productCategory) => productCategory.products)
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleTypeChange = (value) => {
    const hasUnitaryValue = items.some(item => item.unitary_value > 0);

    if (hasUnitaryValue) {
      Modal.confirm({
        title: 'Tem certeza que deseja mudar o tipo?',
        content: 'Você possui alguns campos preenchidos.',
        centered: true,
        onOk: () => {
          setType(+value);
        },
      });
    } else {
      setType(+value);
    }
  };


  return (
    <PageContainer
      content={
        <>
          <Link
            to={{
              pathname: "/shop",
              state: { store_param: store },
            }}
          >
            <ChevronIcon />
          </Link>
        </>
      }
      route={record ? "Editar Compra" : "Adicionar Nova Compra"}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Form
            layout="vertical"
            initialValues={{ remember: false }}
            form={form}
          >
            <Container>
              <Row gutter={12}>
                <Col xs={12}>
                  <Form.Item
                    label={
                      <span>
                        Fornecedor
                        <Tooltip title="Empresa responsável pela venda dos produtos">
                          <InfoCircleIcon />
                        </Tooltip>
                      </span>
                    }
                    name="provider_id"
                  >
                    <SearchProvider
                      defaultValue={state.record?.provider_id}
                      handleChange={(value) => {
                        setProvider(value);
                        resetFormErrors("provider_id");
                      }}
                      height="2.5rem"
                    />
                  </Form.Item>
                </Col>

                <Col xs={12}>
                  <Form.Item
                    label={
                      <span>
                        Tipo
                        <Tooltip title="Tipo da Ação (Mercadoria ou Serviço)">
                          <InfoCircleIcon />
                        </Tooltip>
                      </span>
                    }
                    name="type"
                  >
                    <Select
                      disabled={record}
                      showSearch
                      placeholder="Selecione o tipo da ação"
                      optionFilterProp="children"
                      defaultValue={type!}
                      onChange={handleTypeChange}
                    >
                      <Select.Option value={1}>Mercadoria</Select.Option>
                      <Select.Option value={2}>Serviço</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              {type === 1 ? (
                <>
                  <TitlePage>Itens da Compra</TitlePage>
                  {items.map((item, index) => (
                    <>
                      {index > 0 && (
                        <Divider
                          orientation="left"
                          style={{ marginTop: 0, marginBottom: 0 }}
                        >
                          Item {index + 1}
                        </Divider>
                      )}
                      <Row gutter={12} key={item.key}>
                        <Col md={4} xs={24}>
                          <Form.Item
                            label={
                              <span>
                                Produto
                                <Tooltip title="Produto da compra">
                                  <InfoCircleIcon />
                                </Tooltip>
                              </span>
                            }
                            name={`product_id${index}`}
                            rules={
                              record
                                ? []
                                : [
                                  {
                                    required: true,
                                    message: "Campo obrigatório",
                                  },
                                ]
                            }
                          >
                            <Select
                              disabled={record}
                              showSearch
                              placeholder="Selecione algum produto"
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                const cleanedInput =
                                  removeAccentsAndLowerCase(input);
                                const cleanedOptionLabel =
                                  removeAccentsAndLowerCase(
                                    option?.label ?? ""
                                  );
                                return cleanedOptionLabel.includes(
                                  cleanedInput
                                );
                              }}
                              value={item?.product_id || undefined}
                              defaultValue={record && item?.product_id}
                              onChange={(value) => {
                                const category_select =
                                  state.productsCategory.find((_categories) =>
                                    _categories.products.some(
                                      (_product) => _product.id === +value
                                    )
                                  )?.id;

                                handlePurchasesOrder(
                                  value,
                                  "product_id",
                                  index,
                                  category_select,
                                  "category_id"
                                );

                                form.setFieldsValue({
                                  [`category_id${index}`]: category_select,
                                });
                              }}
                              onClick={() =>
                                resetFormErrors(`product_id${index}`)
                              }
                            >
                              {allProducts?.map((product) => (
                                <Select.Option
                                  value={product?.id || ""}
                                  key={product?.id}
                                  label={product.name}
                                >
                                  {product.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={4} xs={24}>
                          <Form.Item
                            label={
                              <span>
                                Categoria
                                <Tooltip title="Categoria do produto">
                                  <InfoCircleIcon />
                                </Tooltip>
                              </span>
                            }
                            name={`category_id${index}`}
                            rules={
                              record
                                ? []
                                : [
                                  {
                                    required: true,
                                    message: "Campo obrigatório",
                                  },
                                ]
                            }
                          >
                            <Select
                              showSearch
                              placeholder="Selecione uma categoria"
                              disabled={!item?.product_id || record}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toString()
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={item?.category_id}
                              defaultValue={record && item?.category_id}
                              onChange={(value) => {
                                handlePurchasesOrder(
                                  value,
                                  "category_id",
                                  index
                                );
                              }}
                              onClick={() =>
                                resetFormErrors(`category_id${index}`)
                              }
                            >
                              {record ? (
                                <>
                                  {state.productsCategory?.map(
                                    (productCategory) => (
                                      <Select.Option
                                        value={productCategory.id}
                                        key={productCategory.id}
                                        label={productCategory.name}
                                      >
                                        {productCategory.name}
                                      </Select.Option>
                                    )
                                  )}
                                </>
                              ) : (
                                <>
                                  {state.productsCategory
                                    ?.filter((category) =>
                                      category.products.some(
                                        (_product) =>
                                          _product.id === item.product_id
                                      )
                                    )
                                    ?.map((productCategory) => (
                                      <Select.Option
                                        value={productCategory.id}
                                        key={productCategory.id}
                                        label={productCategory.name}
                                      >
                                        {productCategory.name}
                                      </Select.Option>
                                    ))}
                                </>
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={3} xs={24}>
                          <Form.Item
                            label={
                              <span>
                                {window.outerWidth > 1350
                                  ? "Valor Unitário"
                                  : "Valor Unit."}
                                <Tooltip title="Preço de uma unidade do produto">
                                  <InfoCircleIcon />
                                </Tooltip>
                                <span
                                  style={{ color: "var(--rainbows-outer-rim)" }}
                                >
                                  {" "}
                                  *
                                </span>
                              </span>
                            }
                            name={`unitary_value${index}`}
                            rules={
                              record
                                ? []
                                : [
                                  {
                                    validator: () => {
                                      return item?.unitary_value > 0
                                        ? Promise.resolve()
                                        : Promise.reject("Campo obrigatório");
                                    },
                                  },
                                ]
                            }
                          >
                            <MonetaryInput2
                              style={{ height: "2.5rem" }}
                              getValue={(value) => {
                                handleValueChange(index, +value);
                                resetFormErrors(`unitary_value${index}`);
                              }}
                              defaultValue={item?.unitary_value}
                              disabled={record || !item?.product_id}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={3} xs={24}>
                          <Form.Item
                            label={
                              <span>
                                {window.outerWidth > 1350
                                  ? "Quantidade"
                                  : "Qtd."}

                                <Tooltip title="Número de itens">
                                  <InfoCircleIcon />
                                </Tooltip>
                              </span>
                            }
                            name={`quantity${index}`}
                            rules={
                              record
                                ? []
                                : [
                                  {
                                    required: true,
                                    message: "Campo obrigatório",
                                  },
                                ]
                            }
                          >
                            <Input
                              defaultValue={item.quantity || ""}
                              placeholder="Informe a qtd"
                              type="number"
                              onChange={(e) =>
                                handleQuantityChange(index, +e.target.value)
                              }
                              onClick={() =>
                                resetFormErrors(`quantity${index}`)
                              }
                              min={0}
                              max={99}
                              disabled={record || !item?.product_id}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={2} xs={24}>
                          <Form.Item
                            label={
                              <span>
                                IPI
                                <Tooltip title="Valor do IPI do Produto">
                                  <InfoCircleIcon />
                                </Tooltip>
                              </span>
                            }
                            name={`value_ipi${index}`}
                          >
                            <MonetaryInput2
                              style={{ height: "2.5rem" }}
                              getValue={(value) => {
                                handleIpiChange(index, +value);
                              }}
                              defaultValue={item?.value_ipi}
                              disabled={record || !item?.product_id}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={2} xs={24}>
                          <Form.Item
                            label={
                              <span>
                                ICMS
                                <Tooltip title="Valor do ICMS do produto">
                                  <InfoCircleIcon />
                                </Tooltip>
                              </span>
                            }
                            name={`value_icms${index}`}
                          >
                            <MonetaryInput2
                              style={{ height: "2.5rem" }}
                              getValue={(value) => {
                                handleIcmsChange(index, value);
                              }}
                              defaultValue={item?.value_icms}
                              disabled={record || !item?.product_id}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={3} xs={24}>
                          <Form.Item
                            name={`observation${index}`}
                            label={
                              <span>
                                Observação do Item
                                <Tooltip title="Comentário sobre o produto">
                                  <InfoCircleIcon />
                                </Tooltip>
                              </span>
                            }
                          >
                            <Input
                              placeholder="Insira a observação do item"
                              name="observation"
                              defaultValue={item.observation || ""}
                              onChange={(e) =>
                                handlePurchasesOrder(
                                  e.target.value,
                                  "observation",
                                  index
                                )
                              }
                              disabled={!item?.product_id}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={items.length > 1 ? 2 : 3} xs={24}>
                          <Form.Item
                            label={
                              <span>
                                Valor Total
                                <Tooltip title="Preço Unitário x Quantidade">
                                  <InfoCircleIcon />
                                </Tooltip>
                              </span>
                            }
                            name={`total${index}`}
                          >
                            <Input
                              disabled
                              name={`total${index}`}
                              value={currencyFormater(
                                +(item?.total || 0) - +(item?.value_icms || 0)
                              )}
                              placeholder={currencyFormater(
                                +(item?.total || 0) - +(item?.value_icms || 0)
                              )}
                            />
                          </Form.Item>
                        </Col>
                        {items.length > 1 && index !== 0 && !record && (
                          <ColIcon md={1} xs={24}>
                            <Form.Item label=" ">
                              <TrashIcon onClick={() => removeItem(item.key)} />
                            </Form.Item>
                          </ColIcon>
                        )}
                      </Row>
                    </>
                  ))}
                  {!record && (
                    <NewRowButton onClick={addItem}>
                      + Adicionar novo item
                    </NewRowButton>
                  )}

                  <TitlePage>Totais da Compra</TitlePage>
                  <Row gutter={12}>
                    <Col md={3} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            Total dos Produtos
                            <Tooltip title="Soma do valor total de todos itens da compra">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name="total"
                      >
                        <Input
                          placeholder={
                            totalItemsValue.total_products
                              ? String(
                                currencyFormater(
                                  +totalItemsValue.total_products
                                )
                              )
                              : "R$ 0,00"
                          }
                          disabled
                          value={
                            record &&
                            currencyFormater(
                              +totalItemsValue.total_products || 0
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col md={2} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            Nº de Itens
                            <Tooltip title="Número de itens da compra (por unidade)">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name="numero_itens"
                      >
                        <Input
                          placeholder={String(items.length) || "0"}
                          disabled
                          value={items.length || "0"}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={3} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            Total de Qtde
                            <Tooltip title="Soma das quantidades de todos itens da compra">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name="total_quantidades"
                      >
                        <Input disabled name="total_quantidades" />
                      </Form.Item>
                    </Col>
                    <Col md={2} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            Total de IPI
                            <Tooltip title="Valor do IPI de todos os produtos em reais">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name="total_ipi"
                      >
                        <MonetaryInput2
                          style={{ height: "2.5rem" }}
                          getValue={(value) =>
                            setTotalItemsValue((oldValues) => ({
                              ...oldValues,
                              total_ipi: +value,
                            }))
                          }
                          defaultValue={totalItemsValue.total_ipi || 0}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col md={2} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            Total ICMS
                            <Tooltip title="Valor em reais do ICMS">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name="total_icms"
                      >
                        <MonetaryInput2
                          style={{ height: "2.5rem" }}
                          getValue={(value) =>
                            setTotalItemsValue((oldValues) => ({
                              ...oldValues,
                              total_icms: +value,
                            }))
                          }
                          defaultValue={totalItemsValue.total_icms || 0}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col md={2} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            V. Adicional
                            <Tooltip title="Valor Adicional da Compra">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name="additional_value"
                      >
                        <MonetaryInput2
                          style={{ height: "2.5rem" }}
                          getValue={(value) => setValueAdditional(+value)}
                          defaultValue={record && valueAdditional}
                          disabled={record}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={2} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            ICMS ST
                            <Tooltip title="Valor Total do ICMS (o valor inserido é meramente informativo, e não faz parte da soma total dos produtos)">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name="icms_st_value"
                      >
                        <MonetaryInput2
                          style={{ height: "2.5rem" }}
                          getValue={(value) => setIcmsStValue(+value)}
                          defaultValue={record && icmsStValue}
                          disabled={record}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={2} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            Desconto
                            <Tooltip title="Qualquer desconto no valor da compra">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name="discount_value"
                      >
                        <MonetaryInput2
                          style={{ height: "2.5rem" }}
                          getValue={(value) => setDiscountValue(+value)}
                          defaultValue={discountValue}
                          disabled={record}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={3} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            Frete
                            <Tooltip title="Custo com entrega">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name="value_freight"
                      >
                        <MonetaryInput2
                          style={{ height: "2.5rem" }}
                          getValue={(value) => setValueFreight(+value)}
                          defaultValue={record && valueFreight}
                          disabled={record}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={3} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            Juros
                            <Tooltip title="Total de Juros">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name="interest_amount"
                      >
                        <MonetaryInput2
                          style={{ height: "2.5rem" }}
                          getValue={(value) => setValueInterestAmount(+value)}
                          defaultValue={record && valueInterestAmount}
                          disabled={record}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={3} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            Total da Compra
                            <Tooltip title="Valor Total dos Produtos + ICMS ST + Valor Adicional - Desconto + Frete">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name="total_compra"
                      >
                        <MonetaryInput2
                          style={{ height: "2.5rem" }}
                          getValue={() => { }}
                          defaultValue={totalShop}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <TitlePage>Pagamento</TitlePage>
                  <Row gutter={12}>
                    <Col md={12} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            Mês de Competência
                            <Tooltip title="Dia que a compra foi feita">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name="month_competence"
                        rules={
                          record
                            ? []
                            : [{ required: true, message: "Campo obrigatório" }]
                        }
                      >
                        <DatePicker
                          placeholder="mm/aaaa"
                          format="MM/YYYY"
                          picker="month"
                          name="month_competence"
                          locale={locale}
                          onClick={() => resetFormErrors(`month_competence`)}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      md={
                        selectedCondition !== PaymentTypeShop.A_PRAZO ? 12 : 6
                      }
                      xs={24}
                    >
                      <Form.Item
                        label={
                          <span>
                            Condição de Pagamento
                            <Tooltip title="Se a compra é a vista ou a prazo">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name="occurrence"
                        rules={
                          record
                            ? []
                            : [{ required: true, message: "Campo obrigatório" }]
                        }
                      >
                        <Select
                          placeholder="Selecione uma opção"
                          onChange={(value) => {
                            resetOccurrence(value);
                          }}
                          onClick={() => resetFormErrors(`occurrence`)}
                          value={form.getFieldValue("occurrence")}
                          disabled={record}
                        >
                          {paymentCondition.map((item) => (
                            <Select.Option value={item.id} key={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    {selectedCondition === PaymentTypeShop.A_PRAZO && (
                      <Col md={6} xs={24}>
                        <Form.Item
                          label={
                            <span>
                              Parcelas
                              <Tooltip title="Número de parcelas">
                                <InfoCircleIcon />
                              </Tooltip>
                              <span
                                style={{ color: "var(--rainbows-outer-rim)" }}
                              >
                                *
                              </span>
                            </span>
                          }
                          name="purchase_installment"
                          rules={[
                            {
                              validator: () => {
                                return form.getFieldValue(
                                  "purchase_installment"
                                ) > 1 &&
                                  form.getFieldValue("purchase_installment") <=
                                  99 &&
                                  selectedCondition === PaymentTypeShop.A_PRAZO
                                  ? Promise.resolve()
                                  : form.getFieldValue("purchase_installment") >
                                    99
                                    ? Promise.reject(
                                      "O número máximo de parcelas é 99"
                                    )
                                    : Promise.reject(
                                      "O número mínimo de parcelar é 2"
                                    );
                              },
                            },
                          ]}
                        >
                          <Input
                            placeholder="Número de parcelas"
                            type="number"
                            step={1}
                            name="purchase_installment"
                            onChange={(e) => {
                              let value = e.target.value.replace(/\D/g, "");
                              resetFormErrors("purchase_installment");
                              selectedCondition !== PaymentTypeShop.A_PRAZO ||
                                !value
                                ? setInstallments(1)
                                : +value > 99
                                  ? setInstallments(99)
                                  : setInstallments(+value);
                            }}
                            disabled={record}
                            maxLength={2}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>

                  {itemsPayable.map((item, index) => (
                    <>
                      {index > 0 && (
                        <Divider
                          orientation="left"
                          style={{ marginTop: 0, marginBottom: 0 }}
                        >
                          Parcela {index + 1}
                        </Divider>
                      )}
                      <Row gutter={12} key={index}>
                        <Col md={4} xs={24}>
                          <Form.Item
                            label={
                              <span>
                                Data de Vencimento
                                <Tooltip title="Data final do vencimento da compra">
                                  <InfoCircleIcon />
                                </Tooltip>
                              </span>
                            }
                            name={`due_date${index}`}
                            rules={
                              record
                                ? []
                                : [
                                  {
                                    required: true,
                                    message: "Campo obrigatório",
                                  },
                                ]
                            }
                          >
                            <DatePicker
                              placeholder="dd/mm/aaaa"
                              format="DD/MM/YYYY"
                              value={moment(item?.due_date)}
                              onChange={(value) =>
                                handlePaymentMethod(
                                  moment(value),
                                  "due_date",
                                  index
                                )
                              }
                              onClick={() =>
                                resetFormErrors(`due_date${index}`)
                              }
                              defaultValue={record && moment(item?.due_date)}
                            />
                          </Form.Item>
                        </Col>

                        <Col md={3} xs={24}>
                          <Form.Item
                            label={
                              <span>
                                Valor
                                <Tooltip title="Valor do Pagamento">
                                  <InfoCircleIcon />
                                </Tooltip>
                              </span>
                            }
                            name={`payment_amount${index}`}
                            rules={
                              record
                                ? []
                                : [
                                  {
                                    required: true,
                                    message: "Campo obrigatório",
                                  },
                                ]
                            }
                            initialValue={installmentValue}
                          >
                            <MonetaryInput2
                              style={{ height: "2.5rem" }}
                              getValue={(value) => {
                                handleInputChange(
                                  index,
                                  "payment_amount",
                                  value
                                );
                                resetFormErrors(`payment_amount${index}`);
                              }}
                              defaultValue={
                                record
                                  ? item?.payment_amount || 0
                                  : installmentValue
                              }
                              disabled={record}
                            />
                          </Form.Item>
                        </Col>

                        <Col md={4} xs={24}>
                          <Form.Item
                            name={`payment_method${index}`}
                            label={
                              <span>
                                Forma de Pagamento
                                <Tooltip title="Método de pagamento">
                                  <InfoCircleIcon />
                                </Tooltip>
                              </span>
                            }
                            rules={
                              record
                                ? []
                                : [
                                  {
                                    required: true,
                                    message: "Campo obrigatório",
                                  },
                                ]
                            }
                          >
                            <Select
                              placeholder="Selecione uma opção"
                              onChange={(value) => {
                                handlePaymentMethod(
                                  +value,
                                  "payment_method",
                                  index
                                );
                                setSelectedOcurrencyType(+value);
                              }}
                              value={item?.payment_method!}
                              defaultValue={
                                typeof item?.payment_method === "number"
                                  ? item?.payment_method
                                  : undefined
                              }
                              onClick={() =>
                                resetFormErrors(`payment_method${index}`)
                              }
                            >
                              {OrderPaymentType?.map((type) => (
                                <Select.Option
                                  value={type.value}
                                  key={type.value}
                                >
                                  {type.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col md={13} xs={24}>
                          <Form.Item
                            label={
                              <span>
                                Observação
                                <Tooltip title="Comentário sobre o pagamento">
                                  <InfoCircleIcon />
                                </Tooltip>
                              </span>
                            }
                          >
                            <Input.TextArea
                              placeholder="Insira uma observação"
                              value={item?.observationItem || ""}
                              onChange={(e) =>
                                handlePaymentMethod(
                                  e.target.value,
                                  "observationItem",
                                  index
                                )
                              }
                              defaultValue={item?.observationItem || ""}
                              maxLength={50}
                              style={{ height: "2.5rem" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      {selectedOcurrencyType === 5 && installments === 1 && (
                        <Row>
                          <Col xs={24}>
                            <Form.Item
                              label={
                                <span>
                                  Código do Boleto
                                  <Tooltip title="Mês e ano da competência">
                                    <InfoCircleIcon />
                                  </Tooltip>
                                </span>
                              }
                              name="cod_bankslip"
                            >
                              <Input
                                value={form.getFieldValue("cod_bankslip")}
                                placeholder="Digite o código do boleto"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <NewService
                  form={form}
                  filteredCategories={filteredCategories}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  record={record}
                />
              )}

              <TitlePage>Observações e Anexos</TitlePage>
              <Row gutter={12}>
                <Col xs={24}>
                  <Form.Item
                    name="observation"
                    label={
                      <span>
                        Observação
                        <Tooltip title="Comentário sobre a compra">
                          <InfoCircleIcon />
                        </Tooltip>
                      </span>
                    }
                  >
                    <Input.TextArea
                      placeholder="Adicione uma observação"
                      name="observation"
                      maxLength={255}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={8}>
                <Col xs={24} style={{ marginTop: ".8rem" }}>
                  <Form.Item label="Anexos">
                    <InputFile
                      type="file"
                      maxLength={3}
                      id="input_file"
                      accept=".pdf, .jpg, .jpeg, .png"
                      style={{ display: "none" }}
                      multiple
                      onChange={(e) => {
                        const files = e.target.files
                          ? Array.from(e.target.files)
                          : [];
                        setSelectedFiles(files);
                      }}
                    />

                    <StyledInput htmlFor="input_file">
                      Escolher arquivos
                    </StyledInput>
                  </Form.Item>
                </Col>
                <Col xs={24} style={{ overflowY: "scroll" }}>
                  {purchaseFilesUpdate.map((file, index) => (
                    <ContentFileView key={index}>
                      {file.url_file ? (
                        <a href={file.url_file} target="_blank" rel="noopener noreferrer">
                          {file.url_file}
                        </a>
                      ) : (
                        <span>Arquivo não disponível</span>
                      )}
                      {!record && (
                        <button
                          type="button"
                          onClick={() => {
                            setPurchaseFilesUpdate((prevFiles) =>
                              prevFiles.filter((_, item) => item !== index)
                            );
                          }}
                        >
                          Deletar
                        </button>
                      )}
                    </ContentFileView>
                  ))}

                  {selectedFiles.map((file, index) => (
                    <ContentFileView key={index}>
                      <span>{file.name}</span>
                      <button
                        type="button"
                        onClick={() => {
                          setSelectedFiles((prevFiles) =>
                            prevFiles.filter((_, item) => item !== index)
                          );
                        }}
                      >
                        Deletar
                      </button>
                    </ContentFileView>
                  ))}
                </Col>
              </Row>
            </Container>
            <Footer>
              <Button
                backgroundColor="var(--orange-350)"
                color="var(--white)"
                border="none"
                loading={loadingUpload && loading}
                onClick={() => handleSubmit()}
              >
                Salvar
              </Button>
              <Link
                to={{
                  pathname: "/shop",
                  state: { store_param: store },
                }}
              >
                <Button
                  backgroundColor="transparent"
                  color="var(--gray-650)"
                  border="1px solid var(--gray-650)"
                >
                  Cancelar
                </Button>
              </Link>
            </Footer>
          </Form>
        </>
      )}
    </PageContainer>
  );
};

export default NewShop;
