import React, { Dispatch, SetStateAction, useState } from "react";

import locale from "antd/es/date-picker/locale/pt_BR";

import moment from "moment";

import { verifyPermission } from "../../../services/auth";
import api from "../../../services/api";

import { Order as OrderModel } from "../../../models/Order/Order";
import { OrderStatus } from "../../../models/enums/OrderStatus";

import Spinner from "../../../components/Spinner";

import Centralizer from "../../../containers/Centralizer";

import { changeTitleTextStatusOrder } from "../../../services/changeStatusOrder";

import { DatePicker, Form, notification, Tooltip } from "antd";

import {
  ButtonCancel,
  ButtonSave,
  ButtonEdit,
  FooterModal,
  Modal,
  TextModal,
} from "./styles";

interface IProps {
  orderSelect?: OrderModel;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  status: number;
  setStatus: Dispatch<SetStateAction<number>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  setVisibleEdit: Dispatch<SetStateAction<boolean>>;
  setVisibleModalHomologOrder?: Dispatch<SetStateAction<boolean>>;
  setOrderToEdit: Dispatch<SetStateAction<OrderModel | undefined>>;
}

const ModalStatusChange: React.FC<IProps> = ({
  orderSelect,
  visible,
  setVisible,
  status,
  setStatus,
  setShouldSearch,
  setVisibleEdit,
  setVisibleModalHomologOrder,
  setOrderToEdit,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { title, text } = changeTitleTextStatusOrder(status);

  const [form] = Form.useForm();

  const changeStatus = async () => {
    await form.validateFields();

    setLoading(true);
    if (orderSelect && status !== 0) {
      try {
        let payload;

        payload = {
          status: orderSelect.to_delivery && status <= 3 ? 3 : status,
          comment: orderSelect.comment,
          observation: orderSelect.observation,
          total: orderSelect.total,
          to_delivery: orderSelect.to_delivery,
          time_to_get: orderSelect.time_to_get,
          franchise_tax: orderSelect.franchise_tax,
        };
        if (status === 4 && orderSelect.status_integration_bling !== 0) {
          try {
            setLoading(true);
            await api.delete(`/orders/remove-to-bling/${orderSelect?.id}`);
            notification.success({
              message: "Pedido estornado do bling com sucesso",
              duration: 5,
            });

            setShouldSearch(true);
          } catch (error) {
            //@ts-ignore
            if (error?.response?.data?.error?.status === 404) {
              //@ts-ignore
              notification.error({
                message:
                  "Esse pedido foi excluido no Bling, verifique o número do pedido no Bling.",
                duration: 5,
              });

              //@ts-ignore
            } else if (error?.response?.data?.error?.code === 3) {
              notification.error({
                //@ts-ignore
                message: error?.response?.data?.error?.msg,
                duration: 5,
              });
              //@ts-ignore
            } else if (error) {
              //@ts-ignore
              const errors = Array.isArray(error?.response?.data?.error)
                ? //@ts-ignore
                  error?.response?.data?.error?.join("---")
                : //@ts-ignore
                error?.response?.data?.message
                ? //@ts-ignore
                  error?.response?.data.message
                : //@ts-ignore
                  [error?.response?.data?.error?.error?.fields[0]?.msg];

              Modal.error({
                title: "Erro ao estornar o pedido do bling",
                //@ts-ignore
                content: errors,
                okText: "Sim",
                okType: "primary",
              });
            }
          } finally {
            setLoading(false);
            setShouldSearch(false);
          }
        }

        if (
          status === OrderStatus.EM_TRANSPORTE &&
          orderSelect.type_of_load !== 2
        ) {
          payload = {
            ...payload,
            delivery_time: moment(form.getFieldValue("delivery_time")).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
          };
        }
        await api.put(`/orders/${orderSelect.id}`, payload);

        if (
          status === OrderStatus.EM_ESTOQUE ||
          status === OrderStatus.ESTORNADO
        ) {
          await Promise.all(
            orderSelect.orderItems.map(async (item) => {
              await api.post(`/products_store/stock`, {
                store_id: orderSelect?.store_id,
                product_id: item.product_id,
                quantity:
                  status === OrderStatus.EM_ESTOQUE
                    ? +item.quantity
                    : -+item.quantity,
              });
            })
          );
        }
        setShouldSearch(true);
      } catch (error) {
        const _message = "Oops, um erro interno aconteceu";

        //@ts-ignore
        const _description = error.message;

        notification.error({
          message: _message,
          description: _description,
          duration: 5,
        });
      } finally {
        setLoading(false);
        setVisible(false);
        setStatus(0);
      }
    }
  };

  return (
    <Modal
      title={title}
      visible={visible}
      centered
      destroyOnClose={true}
      closable={false}
      footer={
        <FooterModal>
          <ButtonCancel onClick={() => setVisible(false)}>
            Cancelar
          </ButtonCancel>
          {verifyPermission("orders.edit") &&
            status < OrderStatus.FINALIZADO && (
              <Tooltip
                title={
                  moment(
                    orderSelect && orderSelect.created_at,
                    "DD-MM-YYYY HH:mm:ss"
                  ).isBefore(moment("2025-01-06", "YYYY-MM-DD"))
                    ? "Pedidos feitos antes do dia 06/01/2025 não podem ser editados. Entre em contato com o Sucesso do Franqueado para mais informações"
                    : ""
                }
              >
                <ButtonEdit
                  disabled={
                    !orderSelect?.created_at ||
                    moment(
                      orderSelect.created_at,
                      "DD-MM-YYYY HH:mm:ss"
                    ).isBefore(moment("2025-01-06", "YYYY-MM-DD"))
                  }
                  onClick={() => {
                    setOrderToEdit(orderSelect);
                    setVisible(false);
                    orderSelect?.type_of_load === 2
                      ? setVisibleModalHomologOrder &&
                        setVisibleModalHomologOrder(true)
                      : setVisibleEdit(true);
                  }}
                >
                  Editar Pedido
                </ButtonEdit>
              </Tooltip>
            )}
          <ButtonSave
            onClick={() => {
              changeStatus();
            }}
            loading={loading}
          >
            {status === OrderStatus.CANCELADO
              ? "Cancelar pedido"
              : "Confirmar Alteração"}
          </ButtonSave>
        </FooterModal>
      }
    >
      {loading ? (
        <Centralizer>
          <Spinner />
        </Centralizer>
      ) : (
        <Form layout="vertical" initialValues={{ remember: false }} form={form}>
          {status === OrderStatus.EM_TRANSPORTE &&
            orderSelect?.type_of_load !== 2 && (
              <Form.Item
                label="Previsão de entrega"
                name="delivery_time"
                rules={[{ required: true, message: "Campo obrigatório" }]}
              >
                <DatePicker
                  showTime
                  size="small"
                  locale={locale}
                  placeholder="Selecione a data"
                  disabledDate={(current) =>
                    current && current < moment().startOf("day")
                  }
                />
              </Form.Item>
            )}
          <TextModal>{text}</TextModal>
        </Form>
      )}
    </Modal>
  );
};

export default ModalStatusChange;
