import React from "react";

import { Tooltip } from "antd";
import { Link } from "react-router-dom";

import moment from "moment";

import { currencyFormater } from "../../services/currencyFormater";

import { Purchase } from "../../models/Purchase/Purchase";
import { PaymentType } from "../../models/enums/PaymentType";
import { ProductCategory as ProductCategoryResponse } from "../../models/ProductCategory";
import { ShopType } from "../../models/enums/ShopType";

import Centralizer from "../Centralizer";
import { Spin } from "../Centralizer/styles";
import {
  Button,
  ButtonRow,
  Content,
  ContentAnexos,
  ContentColumn,
  ContentItemInfo,
  ContentRow,
  Description,
  Divider,
  DownloadIcon,
  GoToPayment,
  InfoCircleIcon,
  ItemUploadInfo,
  Label,
  OpenIcon,
  TableElement,
  Title,
  TitlePaymentInfoSection,
  TitleSection,
  ContentItemInfoPayment,
  LabelItem,
  DescriptionLabel,
  Drawer,
  DescriptionValue,
} from "./styles";

interface IProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  purchasesOrder: Purchase | null;
  productsCategory: ProductCategoryResponse[];
  purchases: Purchase[];
  loadingInfo: boolean;
}

const InfoPurchaseItemsDrawer: React.FC<IProps> = ({
  visible,
  setVisible,
  purchasesOrder,
  loadingInfo,
}) => {
  return (
    <Drawer
      title={<Title>Detalhes da Compra</Title>}
      visible={visible}
      onClose={() => setVisible(false)}
      maskClosable
      mask={false}
      className="drawer"
      closable
      width={window.outerWidth < 768 ? "100%" : "40%"}
      footer={
        <ButtonRow>
          <Button
            onClick={() => setVisible(false)}
            backgroundColor="transparent"
            color="var(--gray-550)"
            border="1px solid var(--gray-550)"
          >
            Fechar
          </Button>
        </ButtonRow>
      }
    >
      {loadingInfo ? (
        <Centralizer>
          <Spin />
        </Centralizer>
      ) : (
        <Content>
          <Divider>
            <ContentRow>
              <ContentItemInfo>
                <Label>Fornecedor</Label>
                <Description alignLeft>
                  {purchasesOrder?.provider?.company_name ||
                    "Sem Fornecedor Informado"}
                </Description>
              </ContentItemInfo>
              <ContentItemInfo>
                <Label>Data de Criação</Label>
                <Description>
                  {purchasesOrder?.created_at
                    ? moment(purchasesOrder.created_at).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )
                    : "Data não informada"}
                </Description>
              </ContentItemInfo>
            </ContentRow>

            <ContentRow>
              <ContentItemInfo>
                <Label>Responsável</Label>
                <Description alignLeft>
                  {purchasesOrder?.user?.name || "-"}
                </Description>
              </ContentItemInfo>
              <ContentItemInfo>
                <Label>Contas a pagar?</Label>
                <GoToPayment>
                  Ver{" "}
                  <Link
                    to={{
                      pathname: "/bills-to-pay",
                      state: { store_param: purchasesOrder?.store_id, purchase_id: purchasesOrder?.id },
                    }}
                  >
                    <OpenIcon />
                  </Link>
                </GoToPayment>
              </ContentItemInfo>
            </ContentRow>

            <ContentRow>
              <ContentItemInfo>
                <Label>Mês de Competência</Label>
                <DescriptionLabel alignLeft>
                  {moment(purchasesOrder?.month_competence).format("MMMM YYYY") || "Sem Mês de Competência"}
                </DescriptionLabel>
              </ContentItemInfo>

              <ContentItemInfo>
                <Label>Observação</Label>
                <Tooltip title={purchasesOrder?.observation ? purchasesOrder?.observation : null}>
                  <DescriptionLabel alignLeft>
                    {purchasesOrder?.observation || "Sem Observação Cadastrada"}
                  </DescriptionLabel>
                </Tooltip>
              </ContentItemInfo>
            </ContentRow>
          </Divider>

          <Divider>
            <TitleSection>Itens</TitleSection>
            <TableElement>
              <thead>
                <tr>
                  <th>Qtd</th>
                  <th>Categoria</th>
                  <th>Produto</th>
                  <th>
                    Valor
                    <Tooltip title="Preço de uma unidade de produto por linha">
                      <InfoCircleIcon />
                    </Tooltip>
                  </th>
                  <th>{window.outerWidth > 768 ? "Observação" : "Obs"}</th>
                </tr>
              </thead>
              <tbody>
                {purchasesOrder?.purchasesItems?.length ? (
                  purchasesOrder.purchasesItems.map((item) => (
                    <tr key={item.id}>
                      <td>{item.quantity}x</td>
                      <td>
                        {item.product?.category?.name ||
                          "Categoria desconhecida"}
                      </td>
                      <td>{item.product?.name || "Produto desconhecido"}</td>
                      <td>
                        R$ {currencyFormater(+item.unitary_value || 0) || 0}
                      </td>
                      <td>{item.observation || "-"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5}>Nenhum item disponível.</td>
                  </tr>
                )}
              </tbody>
            </TableElement>
          </Divider>

          <Divider>
            <ContentRow>
              <ContentItemInfo>
                <Label>Valor adicional</Label>
                <Description alignLeft>
                  {purchasesOrder?.additional_value
                    ? `R$ ${currencyFormater(
                      +purchasesOrder?.additional_value || 0
                    )}`
                    : "-"}
                </Description>
              </ContentItemInfo>
              <ContentItemInfo>
                <Label>Desconto</Label>
                <Description>
                  {purchasesOrder?.discount_value
                    ? `R$ ${currencyFormater(
                      +purchasesOrder?.discount_value || 0
                    )}`
                    : "-"}
                </Description>
              </ContentItemInfo>
            </ContentRow>
            <ContentRow>
              <ContentItemInfo>
                <Label>Frete</Label>
                <Description alignLeft>
                  {purchasesOrder?.value_freight
                    ? `R$ ${currencyFormater(
                      +purchasesOrder?.value_freight || 0
                    )}`
                    : "-"}
                </Description>
              </ContentItemInfo>

              <ContentItemInfo>
                <Label>Juros</Label>
                <Description alignLeft>
                  R$ {purchasesOrder?.type === ShopType.Servico
                    ? currencyFormater(Number(purchasesOrder?.accountsPayable[0]?.interest_amount) || 0)
                    : currencyFormater(Number(purchasesOrder?.interest_amount || 0)) || "-"}
                </Description>
              </ContentItemInfo>
            </ContentRow>

            {purchasesOrder?.type !== 2 && (
              <ContentRow>
                <ContentItemInfo>
                  <Label>IPI</Label>
                  <Description alignLeft>
                    R$ {currencyFormater(
                      purchasesOrder?.purchasesItems?.reduce(
                        (acc, item) => acc + Number(item.value_ipi || 0),
                        0
                      ) || 0
                    )}
                  </Description>
                </ContentItemInfo>

                <ContentItemInfo>
                  <Label>ICMS</Label>
                  <Description alignLeft>
                    R$ {currencyFormater(
                      purchasesOrder?.purchasesItems?.reduce(
                        (acc, item) => acc + Number(item.value_icms || 0),
                        0
                      ) || 0
                    )}
                  </Description>
                </ContentItemInfo>
              </ContentRow>
            )}

            <ContentRow>
              <ContentItemInfo>
                <Label>
                  Código do Boleto
                  <Tooltip title="Código do Boleto da Compra">
                    <InfoCircleIcon />
                  </Tooltip>
                </Label>
                <Description alignLeft>
                  {purchasesOrder?.type === ShopType.Servico
                    ? purchasesOrder?.accountsPayable[0]?.cod_bankslip
                    : purchasesOrder?.cod_bankslip
                      ? purchasesOrder?.cod_bankslip
                      : "Não informado"}
                </Description>
              </ContentItemInfo>

              <ContentItemInfo>
                <Label>
                  Total da compra
                  <Tooltip title="Soma do valor total de todos itens da compra">
                    <InfoCircleIcon />
                  </Tooltip>
                </Label>
                <Description alignLeft>
                  {purchasesOrder?.total
                    ? `R$ ${currencyFormater(+purchasesOrder?.total || 0)}`
                    : "-"}
                </Description>
              </ContentItemInfo>
            </ContentRow>

          </Divider>

          <Divider>
            <ContentItemInfoPayment>
              <TitleSection>Pagamento</TitleSection>

              <div>
                <ContentItemInfoPayment>
                  <LabelItem>Data da compra: {"   "} </LabelItem>
                  <Description alignLeft>
                    {purchasesOrder?.created_at
                      ? moment(purchasesOrder?.created_at).format("DD/MM/YYYY")
                      : "-"}
                  </Description>
                </ContentItemInfoPayment>
              </div>
            </ContentItemInfoPayment>

            {purchasesOrder?.accountsPayable?.map((item, index) => (
              <ContentColumn key={index}>
                <TitlePaymentInfoSection>
                  {`${index + 1}ª Parcela`}
                </TitlePaymentInfoSection>

                <ContentRow>
                  <ContentItemInfo>
                    <Label>Condição de pagamento</Label>
                    <Description alignLeft>
                      {purchasesOrder?.type === ShopType.Servico
                        ? "A VISTA"
                        : purchasesOrder?.occurrence
                          ? purchasesOrder?.occurrence === ShopType.Servico
                            ? "A PRAZO"
                            : "A VISTA"
                          : "-"}
                    </Description>
                  </ContentItemInfo>
                </ContentRow>

                <ContentRow>
                  <ContentItemInfo>
                    <Label>Vencimento</Label>
                    <Description alignLeft>
                      {item.due_date
                        ? moment(item.due_date).format("DD/MM/YYYY")
                        : "-"}
                    </Description>
                  </ContentItemInfo>

                  <ContentItemInfo>
                    <Label>Valor</Label>
                    <Description>
                      <td>
                        R$ {purchasesOrder?.type === ShopType.Servico
                          ? currencyFormater(+purchasesOrder?.total || 0)
                          : item?.payment_amount
                            ? currencyFormater(+item.payment_amount || 0)
                            : "-"}
                      </td>
                    </Description>
                  </ContentItemInfo>

                  <ContentItemInfo>
                    <Label>Forma de pagamento</Label>
                    <Description>
                      {item.payment_method === 0 || item.payment_method
                        ? PaymentType[item.payment_method]
                        : "-"}
                    </Description>
                  </ContentItemInfo>

                  <ContentItemInfo>
                    <Label>Observação</Label>
                    <DescriptionValue>{item.observation || "-"}</DescriptionValue>
                  </ContentItemInfo>
                </ContentRow>
              </ContentColumn>
            ))}
          </Divider>

          <ContentAnexos>
            <TitleSection>Anexos</TitleSection>
            <ContentColumn>
              {purchasesOrder?.purchaseFiles &&
                purchasesOrder.purchaseFiles.length > 0 ? (
                purchasesOrder.purchaseFiles.map((file, index) => (
                  <ItemUploadInfo key={index}>
                    <DownloadIcon
                      onClick={() => window.open(file.url_file, "_blank")}
                    />
                    <Description>
                      Download do anexo: {file.url_file.split("/").pop()}
                    </Description>
                  </ItemUploadInfo>
                ))
              ) : (
                <Description>Não há anexos disponíveis.</Description>
              )}
            </ContentColumn>
          </ContentAnexos>
        </Content>
      )}
    </Drawer>
  );
};

export default InfoPurchaseItemsDrawer;
