import React, { useEffect, useState } from "react";
import { getTokenInfo } from "../../services/auth";

import {
  Container,
  Body,
  Header,
  ActionContainer,
  PatchNotesIcon,
  ContainerTitle,
  HomologContent,
  ContentChildrenDiv,
} from "./styles";
import ModalPatchNotes from "../../components/ModalPatchNotes";
interface IProps {
  route: string;
  color?: string;
  content?: React.ReactNode;
}

interface PatchNotes {
  url?: string;
  id?: string;
  created_at?: string;
  viewed: boolean;
}
const PageContainer: React.FC<IProps> = ({
  children,
  route,
  content,
  color,
}) => {
  const [visiblePatchNotes, setVisiblePatchNotes] = useState(Boolean);
  const [patchNotes, setPatchNotes] = useState<PatchNotes>({ viewed: true });

  useEffect(() => {
    let patchNotes = window.localStorage.getItem("patch-notes");
    if (patchNotes) {
      setPatchNotes(JSON.parse(patchNotes) as PatchNotes);
    }
  }, [visiblePatchNotes]);
  return (
    <Container>
      <Header>
        <ContainerTitle>
          <ContentChildrenDiv>
            <div className="content">{content}</div>
            <h3 style={{ color }}>{route}</h3>
          </ContentChildrenDiv>
          <PatchNotesIcon onClick={() => setVisiblePatchNotes(true)} actived={visiblePatchNotes ? true : undefined} />
          {process.env.REACT_APP_ENV === "homolog" && (
            <HomologContent>AMBIENTE DE HOMOLOGAÇÃO</HomologContent>
          )}
        </ContainerTitle>
        <div className="generalContent">

          <ActionContainer>
            <div className="actions-icons">
              <img
                src={
                  getTokenInfo()?.image
                    ? getTokenInfo()?.image
                    : "https://www.ecp.org.br/wp-content/uploads/2017/12/default-avatar-1.png"
                }
                alt="Imagem de avatar do usuário"
              />
            </div>
          </ActionContainer>

        </div>
      </Header>
      <Body>{children}</Body>

      <ModalPatchNotes
        created_at={patchNotes?.created_at}
        id={patchNotes?.id}
        viewed={patchNotes?.viewed}
        url={patchNotes?.url}
        visiblePatchNotes={visiblePatchNotes}
        setVisiblePatchNotes={setVisiblePatchNotes}
      />
    </Container>
  );
};

export default PageContainer;
