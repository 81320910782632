import styled  from "styled-components";
import {
  Select as SelectAnt,
  Input as InputAnt,
  DatePicker as DatePickerAnt,
} from "antd";

export const Select = styled(SelectAnt)`
  width: 100%;
  height: 2.5rem !important;

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select-selector {
    height: 2.5rem !important;
  }
`;

export const Validate = styled.span`
  color: red;
`;

export const Input = styled(InputAnt)`
  width: 100%;
  height: 2.5rem !important;
  border-radius: 5px !important;

  .ant-input {
    height: 1.9rem !important;
  }
`;

export const DatePicker = styled(DatePickerAnt)`
  width: 100%;
  height: 2.5rem !important;

  .ant-picker-input > input {
    font-weight: 400 !important;
    font-size: 0.9rem !important;
  }
`;

export const Title = styled.span`
  color: var(--gray-45);
  font-size: 1.2rem;
  text-transform: capitalize;
  margin-top: 3rem;
`;

export const TitleTop = styled.span`
  color: black;
  font-size: 1.2rem;
  text-transform: capitalize;
  margin-top: 4rem;
`;